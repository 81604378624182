import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import ListIcon from '@mui/icons-material/List'
import React, { useCallback } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import type { ToggleButtonProps } from '@sevenrooms/react-components'
import { MenuItem } from '@sevenrooms/react-components/components/MenuItem'
import { Select, type SelectChangeEvent } from '@sevenrooms/react-components/components/Select'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { SvgIcon } from '@sevenrooms/react-components/components/SvgIcon'
import { ToggleButton } from '@sevenrooms/react-components/components/ToggleButton'
import { ToggleButtonGroup } from '@sevenrooms/react-components/components/ToggleButtonGroup'
import { Tooltip } from '@sevenrooms/react-components/components/Tooltip'
import { AccessRuleRange, AccessRuleView, Density } from '../../enums/enums'
import { useAccessRulesUrlParams } from '../../hooks/useAccessRulesUrlParams'
import { accessRulesSettingsMessages } from '../../locales'
import { AccessDatePicker } from './AccessDatePicker'

interface FilledToggleButtonProps extends ToggleButtonProps {
  'data-test'?: string
  title: string
}

function FilledToggleButton({ value, children, title, ...props }: FilledToggleButtonProps) {
  return (
    <Tooltip title={title}>
      <ToggleButton value={value} size="small" data-test={props['data-test']}>
        {children}
      </ToggleButton>
    </Tooltip>
  )
}

export interface ActionsBarProps {
  density: Density
  setDensity: (density: Density) => void
}

function ActionsBar({ density, setDensity }: ActionsBarProps) {
  const [{ view, range }, setParams] = useAccessRulesUrlParams()
  const { formatMessage } = useLocales()

  const handleDensityToggle = useCallback(
    (_: React.MouseEvent<HTMLElement>, newDensity: Density) => {
      if (!newDensity) {
        return
      }
      setDensity(newDensity)
    },
    [setDensity]
  )

  const handleViewToggle = useCallback(
    (event: React.MouseEvent<HTMLElement>, newView: AccessRuleView) => {
      if (!newView) {
        return
      }

      if (newView === AccessRuleView.CALENDAR && range === AccessRuleRange.OVERVIEW) {
        setParams({ view: newView, range: AccessRuleRange.WEEK })
      } else if (newView === AccessRuleView.LIST && range === AccessRuleRange.WEEK) {
        setParams({ view: newView, range: AccessRuleRange.OVERVIEW })
      } else {
        setParams({ view: newView })
      }
    },
    [range, setParams]
  )

  const handleRangeToggle = useCallback(
    (event: SelectChangeEvent<unknown>) => {
      const newRange = event.target.value as AccessRuleRange
      if (newRange === AccessRuleRange.WEEK && view === AccessRuleView.LIST) {
        setParams({ view: AccessRuleView.CALENDAR, range: newRange })
      } else if (newRange === AccessRuleRange.OVERVIEW && view === AccessRuleView.CALENDAR) {
        setParams({ view: AccessRuleView.LIST, range: newRange })
      } else {
        setParams({ range: newRange })
      }
    },
    [view, setParams]
  )

  return (
    <Stack id="access-rules-actions-bar" direction="row" spacing={2} justifyContent="space-between" alignItems="center">
      <AccessDatePicker />
      <Stack direction="row" spacing={3} alignItems="center">
        {view === AccessRuleView.CALENDAR && (
          <ToggleButtonGroup
            value={density ?? Density.COMPACT}
            id="density-switch"
            data-test="density-switch"
            aria-label="Density Switch"
            exclusive
            color="primary"
            size="small"
            onChange={handleDensityToggle}
            disabled={view !== AccessRuleView.CALENDAR}
          >
            <FilledToggleButton
              size="small"
              value={Density.COMPACT}
              data-test="btn-density-compact"
              title={formatMessage(accessRulesSettingsMessages.compact)}
            >
              <SvgIcon sx={{ fontSize: '20px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor">
                  <path d="M13.6 13.6H12.4V2.40001H13.6V13.6ZM11.1 13.6H9.90002V2.40001H11.1V13.6ZM8.60002 13.6H7.40002L7.40002 2.40001H8.60002V13.6ZM6.10002 13.6H4.90002L4.90002 2.40001H6.10002L6.10002 13.6ZM3.60002 13.6H2.40002L2.40002 2.40001H3.60002L3.60002 13.6Z" />
                </svg>
              </SvgIcon>
            </FilledToggleButton>
            <FilledToggleButton
              size="small"
              value={Density.EXPANDED}
              data-test="btn-density-expanded"
              title={formatMessage(accessRulesSettingsMessages.expanded)}
            >
              <SvgIcon sx={{ fontSize: '20px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="currentColor">
                  <path d="M13.6 13.6H12.4V2.40001H13.6V13.6ZM8.60002 13.6H7.40002L7.40002 2.40001H8.60002V13.6ZM3.60002 13.6H2.40002L2.40002 2.40001H3.60002L3.60002 13.6Z" />
                </svg>
              </SvgIcon>
            </FilledToggleButton>
          </ToggleButtonGroup>
        )}
        <ToggleButtonGroup
          value={view}
          id="cal-list-switch"
          data-test="cal-list-switch"
          aria-label="Calendar/List Switch"
          exclusive
          color="primary"
          size="small"
          onChange={handleViewToggle}
        >
          <FilledToggleButton
            size="small"
            value={AccessRuleView.CALENDAR}
            data-test="btn-cal"
            title={formatMessage(accessRulesSettingsMessages.calendar)}
          >
            <CalendarMonthIcon fontSize="small" />
          </FilledToggleButton>
          <FilledToggleButton
            size="small"
            value={AccessRuleView.LIST}
            data-test="btn-list"
            title={formatMessage(accessRulesSettingsMessages.list)}
          >
            <ListIcon fontSize="small" />
          </FilledToggleButton>
        </ToggleButtonGroup>
        <Select
          id="range-select"
          value={range}
          onChange={handleRangeToggle}
          displayEmpty
          color="primary"
          sx={{
            height: '36px',
            width: '123px',
            minWidth: '123px',
            maxWidth: '123px',
            padding: 0,
          }}
        >
          <MenuItem value={AccessRuleRange.DAY}>{formatMessage(accessRulesSettingsMessages.day)}</MenuItem>
          <MenuItem value={AccessRuleRange.WEEK}>{formatMessage(accessRulesSettingsMessages.week)}</MenuItem>
          <MenuItem value={AccessRuleRange.OVERVIEW}>{formatMessage(accessRulesSettingsMessages.overview)}</MenuItem>
        </Select>
      </Stack>
    </Stack>
  )
}

export { ActionsBar }
