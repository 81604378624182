import InfoOutlined from '@mui/icons-material/InfoOutlined'
import { useContext, useEffect, useMemo } from 'react'
import { useGetAccessRulesQuery, useGetShiftsScheduleQuery, useGetAccessRuleQuery } from '@sevenrooms/core/api'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly, getDateFNSLocale, startOfWeek, endOfWeek } from '@sevenrooms/core/timepiece'
import { useAccessRulesSlideoutData } from '@sevenrooms/mgr-access-rules-slideout/useAccessRulesSlideoutData'
import { SettingsPageContent, SettingsPageContext, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Divider } from '@sevenrooms/react-components/components/Divider'
import { Link } from '@sevenrooms/react-components/components/Link'
import { LocalizationProvider } from '@sevenrooms/react-components/components/LocalizationProvider'
import { SevenRoomsProgress } from '@sevenrooms/react-components/components/SevenRoomsProgress'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { ThemeProvider, vmsTheme } from '@sevenrooms/react-components/components/ThemeProvider'
import { Tooltip } from '@sevenrooms/react-components/components/Tooltip'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { AccessRulesActionButtons } from '../../components/ActionButtons/AccessRulesActionButtons'
import { ActionsBar } from '../../components/ActionsBar/ActionsBar'
import { AccessRuleSlideoutProvider, useAccessRuleSlideout } from '../../contexts/AccessRuleSlideoutProvider'
import { AccessRuleRange, AccessRuleView } from '../../enums/enums'
import { useAccessRulesUrlParams } from '../../hooks/useAccessRulesUrlParams'
import { useDensityPreference } from '../../hooks/useDensityPreference'
import { accessRulesSettingsMessages } from '../../locales'
import { AccessRulesCalendar } from '../AccessRulesCalendar/AccessRulesCalendar'
import { AccessRulesList } from '../AccessRulesList/AccessRulesList'

function AccessRulesSettingsContainer({ children }: React.PropsWithChildren<{}>) {
  const headerHeight = useContext(SettingsPageContext)

  return (
    <Box height="100%" sx={{ backgroundColor: theme => theme.palette.common.white }}>
      <Stack height="100%" maxHeight={`calc(100vh - ${headerHeight}px)`} padding={4} boxSizing="border-box">
        {children}
      </Stack>
    </Box>
  )
}

export function AccessRulesSettingsContent() {
  const { venue } = useVenueContext()
  const { formatMessage } = useLocales()

  const [{ density }, setDensity] = useDensityPreference()
  const [{ view, range, date, selectedAccessRuleId }] = useAccessRulesUrlParams()

  const { isFetching: isAllArDataFetching, data: arData } = useAccessRulesSlideoutData()

  const showAccessRuleSlideout = useAccessRuleSlideout()
  const selectedAccessRule = useGetAccessRuleQuery(
    { id: selectedAccessRuleId ?? '', venueId: venue.id, date: date.toJsDate().toISOString() },
    { skip: !selectedAccessRuleId || !date }
  )
  useEffect(() => {
    if (selectedAccessRuleId && selectedAccessRule.data) {
      showAccessRuleSlideout({ accessRule: selectedAccessRule.data, startDate: undefined, mode: undefined })
    }
  }, [selectedAccessRuleId, showAccessRuleSlideout, selectedAccessRule.data])

  const [start, end, overviewDates] = useMemo(() => {
    const start = startOfWeek(date.toJsDate(), { weekStartsOn: 0 })
    const end = endOfWeek(date.toJsDate(), { weekStartsOn: 0 })
    return [
      start,
      end,
      Array.from({ length: 7 }, (_, i: number) => new Date(new Date(start).setDate(start.getDate() + i))).map(x => DateOnly.fromDate(x)),
    ]
  }, [date])

  const useListOverviewQuery = range === AccessRuleRange.OVERVIEW && view === AccessRuleView.LIST

  const shifts = useGetShiftsScheduleQuery(
    {
      venueId: venue.id,
      startDate: range === AccessRuleRange.DAY ? date : DateOnly.fromDate(start),
      endDate: range === AccessRuleRange.DAY ? date : DateOnly.fromDate(end),
    },
    { skip: useListOverviewQuery }
  )

  // Note: Each of these queries returns a different structured object, so we need to handle them differently in child components.
  // It could either be an AccessRules object (for date ranges) or an array of AccessRule objects (when grabbing all).
  const accessRulesByRange = useGetAccessRulesQuery(
    {
      venueId: venue.id,
      startDate: range === AccessRuleRange.DAY ? date.toIso() : start.toISOString(),
      endDate: range === AccessRuleRange.DAY ? date.toIso() : end.toISOString(),
    },
    { skip: useListOverviewQuery }
  )

  const ViewComponent = useMemo(() => {
    if (view === AccessRuleView.CALENDAR) {
      return function CalendarView() {
        return (
          <AccessRulesCalendar overviewDates={overviewDates} shifts={shifts.data} accessRules={accessRulesByRange.data} density={density} />
        )
      }
    }
    return function ListView() {
      return (
        <Box width="100%" height="100%" sx={{ overflowY: 'auto', position: 'relative' }}>
          {arData && (range === AccessRuleRange.DAY ? accessRulesByRange.data : true) && (
            <AccessRulesList
              accessRulesDay={accessRulesByRange.data}
              accessRulesOverview={arData.allAccessRules}
              shifts={shifts.data}
              shiftsOverview={arData.allShifts}
              seatingAreas={arData.seatingAreaData}
              audienceHierarchy={arData.audienceHierarchy}
              experiences={arData.experiences}
              upsells={arData.upsells}
            />
          )}
        </Box>
      )
    }
  }, [view, overviewDates, shifts.data, accessRulesByRange.data, density, arData, range])

  const isFetching = useMemo(
    () => isAllArDataFetching || shifts.isFetching || accessRulesByRange.isFetching,
    [isAllArDataFetching, shifts.isFetching, accessRulesByRange.isFetching]
  )

  if (!venue) {
    return null
  }

  return (
    <>
      <SettingsPageMeta title={formatMessage(accessRulesSettingsMessages.title)} />
      <SettingsPageContent
        secondHeaderMaxWidth="100%"
        title={
          <Box display="flex" alignItems="center" width="200px" height="42px" sx={{ lineHeight: '42px' }}>
            {formatMessage(accessRulesSettingsMessages.title)}
            <Tooltip
              title={
                <Typography>
                  {formatMessage(accessRulesSettingsMessages.subtitle, {
                    a: (chunks: string[]) => (
                      <Link
                        data-test="access-rules-settings-help"
                        target="_blank"
                        href="/help?return_to=/hc/en-us/categories/360004583192-Availability"
                      >
                        {chunks}
                      </Link>
                    ),
                  })}
                </Typography>
              }
            >
              <Box data-test="header-info-tooltip-trigger" aria-label="Info" display="flex" pl={2}>
                <InfoOutlined />
              </Box>
            </Tooltip>
          </Box>
        }
        actions={<AccessRulesActionButtons />}
      >
        <AccessRulesSettingsContainer>
          <ActionsBar density={density} setDensity={setDensity} />
          <Divider sx={{ my: 4, mx: -4 }} />
          {isFetching ? (
            <Box display="flex" alignItems="center" justifyContent="center" height="100%">
              <SevenRoomsProgress aria-label="Loading" />
            </Box>
          ) : (
            <ViewComponent />
          )}
        </AccessRulesSettingsContainer>
      </SettingsPageContent>
    </>
  )
}

export function AccessRulesSettings() {
  const { venueLocale } = useAppContext()
  const locale = getDateFNSLocale(venueLocale)

  return (
    <ThemeProvider theme={vmsTheme}>
      <LocalizationProvider adapterLocale={locale}>
        <AccessRuleSlideoutProvider>
          <AccessRulesSettingsContent />
        </AccessRuleSlideoutProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}
