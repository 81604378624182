import React, { useMemo } from 'react'
import { type OmnivoreConfig, type OmnivoreLocation, POSITypeEnum } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { Form } from '@sevenrooms/core/ui-kit/form'
import { OmnivoreLocationSelectionCard } from '../../../LocationSelectionCard'
import { BaseEditPageForm } from '../BaseEditPageForm'
import { useSavePOSIConfig } from '../hooks'
import {
  convertOmnivoreEditPageFormDataToOmnivoreConfig,
  getOmnivoreEditPageFormDefaultValues,
  type OmnivoreEditPageFormData,
  useOmnivoreEditPageForm,
} from './OmnivoreEditPageForm.zod'
import type { EditPageFormProps } from '../EditPageForm'

export interface OmnivoreEditPageFormProps extends EditPageFormProps {
  config: OmnivoreConfig
  locations: OmnivoreLocation[]
  refetchConfig: () => void
  isFetching: boolean
}
export function OmnivoreEditPageForm({ config, locations, refetchConfig, isFetching }: OmnivoreEditPageFormProps) {
  const { saveConfig, isSaving } = useSavePOSIConfig()
  const defaultValues = useMemo(() => getOmnivoreEditPageFormDefaultValues(config), [config])
  const form = useForm(useOmnivoreEditPageForm(), {
    defaultValues,
  })
  const { field, formState } = form
  const { isDirty } = formState

  const disabled = useMemo(() => !isDirty || isSaving || isFetching, [isDirty, isSaving, isFetching])

  const handleSave = async (data: OmnivoreEditPageFormData) => {
    await saveConfig({
      posiType: POSITypeEnum.OMNIVORE,
      config: convertOmnivoreEditPageFormDataToOmnivoreConfig({ config, locations, data }),
      refetchConfig,
    })
  }

  return (
    <Form {...form} onSubmit={data => handleSave(data)} onInvalid={() => {}}>
      <BaseEditPageForm posiType={POSITypeEnum.OMNIVORE} disabled={disabled}>
        <OmnivoreLocationSelectionCard field={field.prop('locationCard')} locations={locations} disabled={!!config.location} />
      </BaseEditPageForm>
    </Form>
  )
}
