import { useMemo, useState } from 'react'
import type { AccessRules, ShiftsByDate } from '@sevenrooms/core/domain'
import { DateOnly } from '@sevenrooms/core/timepiece'
import {
  accessRuleSort,
  getAccessRulesDisplayOrder,
  type AccessRuleProps,
} from '@sevenrooms/mgr-access-rules-slideout/utils/accessRulesCalendarUtils'
import { Density } from '../../enums/enums'
import { MultiDayScheduleGrid } from '../MultiDayScheduleGrid/MultiDayScheduleGrid'
import { AccessRuleBlock } from './AccessRuleBlock'
import { ShiftBlock, type ShiftBlockProps } from './ShiftBlock'

type ShiftData = Omit<ShiftBlockProps, 'column' | 'sortOrderOffset'>

function getShiftsBlocks(shiftsByDate: ShiftsByDate) {
  const shiftData = Object.entries(shiftsByDate).map(([date, shifts]) => ({ date: DateOnly.from(date), shifts }))

  const result: ShiftData[] = []
  for (const [i, { date, shifts }] of shiftData.entries()) {
    const previousDay = i > 0 ? shiftData.at(i - 1) : undefined
    const nextDay = i < shiftData.length ? shiftData.at(i + 1) : undefined

    for (const shift of shifts.values()) {
      if (shift.startTimeSortOrder === undefined || shift.endTimeSortOrder === undefined) {
        continue
      }

      const isLeft = previousDay?.shifts.find(x => x.id === shift.id) === undefined
      const isRight = nextDay?.shifts.find(x => x.id === shift.id) === undefined

      result.push({
        shift,
        date,
        isLeft,
        isRight,
        startSortOrder: shift.startTimeSortOrder,
        endSortOrder: shift.endTimeSortOrder,
      })
    }
  }

  return result
}

function getGroupedAccessRules(accessRules: AccessRules, shiftsByDate: ShiftsByDate): AccessRuleProps[][] {
  return Object.entries(accessRules).flatMap(([date, rules]) => {
    const jsDate = new Date(Date.parse(date))
    const dateOnly = DateOnly.fromDate(jsDate)

    const shifts = shiftsByDate[dateOnly.toIso()] ?? []

    const rulesWithSortOrders = getAccessRulesDisplayOrder(rules, shifts)
      .map(x => ({ ...x, date: dateOnly, ruleName: x.rule.name }))
      .sort(accessRuleSort)

    const overlapGroups: AccessRuleProps[][] = []
    for (const accessRuleData of rulesWithSortOrders) {
      const previousGroup = overlapGroups.at(-1)

      if (
        previousGroup &&
        (previousGroup.at(-1)?.endSortOrder ?? -1) >= accessRuleData.startSortOrder &&
        (previousGroup.at(0)?.endSortOrder ?? -1) >= accessRuleData.startSortOrder
      ) {
        previousGroup.push(accessRuleData)
      } else {
        overlapGroups.push([accessRuleData])
      }
    }

    return overlapGroups
  })
}

interface AccessRulesScheduleGridProps {
  dates: DateOnly[]
  focusDate?: DateOnly
  venueStartOfDayHour: number
  shifts?: ShiftsByDate
  accessRules?: AccessRules
  onClickDayHeader: (date: DateOnly) => void
  density: Density
}

export function AccessRulesScheduleGrid({
  dates,
  focusDate,
  venueStartOfDayHour,
  shifts,
  accessRules,
  onClickDayHeader,
  density,
}: AccessRulesScheduleGridProps) {
  const getColumnIndex = (date: DateOnly) => {
    if (focusDate) {
      return 1
    }

    const index = dates.findIndex(x => date.isEqualTo(x))
    if (index >= 0) {
      return index + 1
    }

    return null
  }

  const [accessRuleHoverState, setAccessRuleHoverState] = useState<{ [key: string]: boolean }>({})

  const { shiftsData, accessRuleData } = useMemo(() => {
    if (!shifts || !accessRules) {
      return {}
    }

    const shiftsData = shifts ? getShiftsBlocks(shifts) : []
    const overlapGroups = getGroupedAccessRules(accessRules, shifts)
    const accessRuleData = overlapGroups.flatMap(group =>
      group.map((accessRuleData, index, { length }) => ({ ...accessRuleData, overlapIndex: index, overlapCount: length }))
    )

    return { shiftsData, accessRuleData }
  }, [shifts, accessRules])

  const shiftMinSortOrder = shiftsData?.map(x => x.shift.startTimeSortOrder ?? Number.MAX_VALUE) ?? []
  const accessRuleMinSortOrder = accessRuleData?.map(x => x.startSortOrder) ?? []
  const minSortOrder = Math.min(...shiftMinSortOrder, ...accessRuleMinSortOrder)
  const maxSortOrder = Math.max(
    ...(shiftsData?.map(x => x.shift.endTimeSortOrder ?? Number.MAX_VALUE) ?? []),
    ...(accessRuleData?.map(x => x.endSortOrder) ?? [])
  )
  const startHour = Math.floor(venueStartOfDayHour + minSortOrder / 4)
  const startHourOffset = startHour - venueStartOfDayHour
  const sortOrderOffset = startHourOffset * 4
  const hourSpan = Math.ceil(maxSortOrder / 4) - startHourOffset

  const displayShifts = focusDate ? shiftsData?.filter(x => x.date.isEqualTo(focusDate)) : shiftsData

  return (
    <MultiDayScheduleGrid
      startHour={startHour}
      hourSpan={hourSpan ?? 24}
      dates={dates}
      onClickDayHeader={onClickDayHeader}
      rowMinHeight={44}
      width={density === Density.EXPANDED ? '200%' : '100%'}
      minWidth={600}
    >
      {displayShifts?.map(props => {
        const column = getColumnIndex(props.date)

        return column ? (
          <ShiftBlock
            key={`shift-${props.date.toIso()}-${props.shift.name}`}
            column={column}
            sortOrderOffset={sortOrderOffset}
            {...props}
          />
        ) : null
      })}
      {accessRuleData?.map(props => {
        const column = getColumnIndex(props.date)

        return column ? (
          <AccessRuleBlock
            key={`rule-${props.date.toIso()}-${props.rule.id}-${props.startSortOrder}-${props.endSortOrder}`}
            column={column}
            overlap={!focusDate}
            venueStartOfDayHour={venueStartOfDayHour}
            sortOrderOffset={sortOrderOffset}
            hover={accessRuleHoverState[`${props.rule.id}-${props.date.toIso()}`] ?? false}
            setHover={(hover: boolean) => setAccessRuleHoverState(x => ({ ...x, [`${props.rule.id}-${props.date.toIso()}`]: hover }))}
            {...props}
          />
        ) : null
      })}
    </MultiDayScheduleGrid>
  )
}
