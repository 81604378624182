import React from 'react'
import type { Shift } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { TimeOnly, TimeInterval } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesListCategoriesMessages, accessRulesListMessages } from '../../../locales'
import { TimeRangeChip, TimeSlotChip } from './Common/TimeChips'

interface TimeFieldProps {
  timeSlots: TimeOnly[]
  timeRanges: TimeInterval[]
  shiftCategories?: string[]
  rowId: string
  restrictToShift?: boolean
  overviewShifts?: Shift[]
}

function TimeField({ timeSlots, timeRanges, rowId, restrictToShift = false, shiftCategories, overviewShifts }: TimeFieldProps) {
  const { formatMessage } = useLocales()
  const MAX_SLOTS = 7
  const MAX_ROWS = 4

  // This means we are in the overview list and the access time type is ALL
  if (shiftCategories && overviewShifts) {
    return (
      <Typography variant="body2" color="text.primary">
        {shiftCategories
          .map(category => {
            const count = overviewShifts.filter(shift => shift.shiftCategory === category).length
            const formattedCategory = formatMessage(
              accessRulesListCategoriesMessages[`${category.toLowerCase()}Category` as keyof typeof accessRulesListCategoriesMessages]
            )
            return `${formattedCategory} (${count})`
          })
          .join(', ')}
      </Typography>
    )
  }

  if (timeRanges.length > 0) {
    const visibleRanges: TimeInterval[] = timeRanges.slice(0, MAX_ROWS)
    const remainingRanges = timeRanges.length - MAX_ROWS

    return (
      <Box display="flex" flexWrap="wrap" gap="4px" maxWidth="100%">
        {restrictToShift && (
          <Typography variant="body2" color="text.secondary">
            {formatMessage(accessRulesListMessages.restrictedByShift)}
          </Typography>
        )}
        {visibleRanges.map(range => (
          <TimeRangeChip key={`${rowId}-${range.formatSTime()}`} timeInterval={range} />
        ))}
        {remainingRanges > 0 && (
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ height: 22, display: 'inline-flex', alignItems: 'center', px: 1, py: 0.5 }}
          >
            {formatMessage(accessRulesListMessages.xMore, { count: remainingRanges })}
          </Typography>
        )}
      </Box>
    )
  }

  if (timeSlots.length > 0) {
    const visibleSlots: TimeOnly[] = timeSlots.slice(0, MAX_SLOTS)
    const remainingSlots = timeSlots.length - MAX_SLOTS

    return (
      <Box display="flex" flexWrap="wrap" gap="4px" maxWidth="100%">
        {restrictToShift && (
          <Typography variant="body2" color="text.secondary">
            {formatMessage(accessRulesListMessages.restrictedByShift)}
          </Typography>
        )}
        {visibleSlots.map(slot => (
          <TimeSlotChip key={`${rowId}-${slot.formatSTime()}`} timeSlot={slot} />
        ))}
        {remainingSlots > 0 && (
          <Typography
            variant="body2"
            color="text.primary"
            sx={{ height: 22, display: 'inline-flex', alignItems: 'center', px: 1, py: 0.5 }}
          >
            {formatMessage(accessRulesListMessages.xMore, { count: remainingSlots })}
          </Typography>
        )}
      </Box>
    )
  }

  return null
}

export { TimeField }
