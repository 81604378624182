import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { PaymentPolicyMessages } from '../../../locales'
import type { AccessListRowPayment } from '../../../views/AccessRulesList/rowInterfaces'

interface PaymentFieldProps {
  paymentPolicy: AccessListRowPayment
  isFieldShift: boolean
}

function PaymentField({ paymentPolicy, isFieldShift }: PaymentFieldProps) {
  const { formatMessage } = useLocales()
  const { paymentRule, refundType, cancelType, cancelNum, cancelHour } = paymentPolicy

  const renderLine = (icon: React.ReactNode, text: string) => (
    <Box display="flex" alignItems="flex-start" gap={1}>
      <Box display="flex" alignItems="center">
        {icon}
      </Box>
      <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
        {text}
      </Typography>
    </Box>
  )

  const getPaymentRequiredMessage = () => {
    switch (paymentRule) {
      case 'save_for_later':
        return formatMessage(PaymentPolicyMessages.paymentChoiceLater)
      case 'advanced_payment':
        return formatMessage(PaymentPolicyMessages.paymentChoiceImmediately)
      default:
        return formatMessage(PaymentPolicyMessages.paymentChoiceNone)
    }
  }

  const getRefundMessage = () => {
    switch (refundType) {
      case 'FULL_REFUND':
        return formatMessage(PaymentPolicyMessages.refundChoiceFull)
      case 'PARTIAL_REFUND':
        return formatMessage(PaymentPolicyMessages.refundChoicePartial)
      case 'CANCELLATION_NO_SHOW_CHARGE':
        return formatMessage(PaymentPolicyMessages.penaltyChoiceCharge)
      case 'NO_CHARGE':
      case undefined:
        return formatMessage(PaymentPolicyMessages.penaltyChoiceNone)
      // `NO_REFUND'
      default:
        return formatMessage(PaymentPolicyMessages.refundChoiceNone)
    }
  }

  const getCancellationMessage = () => {
    switch (cancelType) {
      case 'NEVER':
        return formatMessage(PaymentPolicyMessages.cancelChoiceNever)
      case undefined:
        return formatMessage(PaymentPolicyMessages.cancelChoiceAnyTime)
      // AccessRuleTimeUnitType -> "MINUTES" | "HOURS" | "DAYS" | "WEEKS" | "MONTHS"
      default:
        // Special case based on how we store this data. 0/undefined means the same as any time
        if (!cancelNum || cancelNum === 0) {
          return formatMessage(PaymentPolicyMessages.cancelChoiceAnyTime)
        }
        return formatMessage(PaymentPolicyMessages.upUntilGivenCutoff, {
          num: cancelNum ?? 0,
          unit: cancelType,
          time: TimeOnly.fromSafe(cancelHour ?? '')?.formatSTime() ?? '0',
        })
    }
  }

  const getIcon = (condition: boolean) =>
    condition ? (
      <TaskAltIcon sx={{ color: 'success.main', fontSize: 16 }} />
    ) : (
      <DoDisturbAltIcon sx={{ color: 'error.main', fontSize: 16 }} />
    )

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      {renderLine(getIcon(paymentRule !== undefined), getPaymentRequiredMessage())}
      {renderLine(getIcon(refundType !== 'NO_CHARGE' && refundType !== 'NO_REFUND' && refundType !== undefined), getRefundMessage())}
      {renderLine(getIcon(cancelType !== 'NEVER'), getCancellationMessage())}
    </Box>
  )
}

export { PaymentField }
