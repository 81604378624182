import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesListMessages } from '../../../locales'

interface SeatingAreasFieldProps {
  seatingAreas: string[]
  tables?: string[]
  isFieldShift: boolean
}

function SeatingAreasField({ seatingAreas, tables, isFieldShift }: SeatingAreasFieldProps) {
  const { formatMessage } = useLocales()
  let combinedItems: string[] = []

  // Combine seating areas and tables into a single array
  if (seatingAreas && seatingAreas.length > 0) {
    combinedItems = [...seatingAreas]
    if (tables && tables.length > 0) {
      combinedItems = [...combinedItems, ...tables]
    }
  } else if (tables && tables.length > 0) {
    combinedItems = [...tables]
  }

  const maxVisibleItems = 20
  const visibleItems = combinedItems.slice(0, maxVisibleItems)
  const remainingCount = combinedItems.length - visibleItems.length

  const displayText = combinedItems.length ? visibleItems.join(', ') : formatMessage(accessRulesListMessages.allSeatingAreas)
  const remainingText = remainingCount > 0 ? formatMessage(accessRulesListMessages.andXMore, { count: remainingCount }) : ''

  return (
    <Box>
      <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
        {displayText}
        {remainingText && `, ${remainingText}`}
      </Typography>
    </Box>
  )
}

export { SeatingAreasField }
