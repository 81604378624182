import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  saveSuccess: {
    id: 'posiSettings.TableMappingWizard.saveSuccess',
    defaultMessage: 'Saved table mappings',
  },
  saveError: {
    id: 'posiSettings.TableMappingWizard.saveError',
    defaultMessage: 'Error saving table mappings',
  },
} as const)
