import { useLocales, commonMessages } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import type { AccessListRowSelectableUpgrades } from '../../../views/AccessRulesList/rowInterfaces'

interface SelectableUpgradesFieldProps {
  selectableUpgrades: AccessListRowSelectableUpgrades
  rowId: string
  isFieldShift: boolean
}

function SelectableUpgradesField({ selectableUpgrades, rowId, isFieldShift }: SelectableUpgradesFieldProps) {
  const upgradeKeys = Object.keys(selectableUpgrades) as (keyof AccessListRowSelectableUpgrades)[]
  const { formatMessage } = useLocales()

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {upgradeKeys.map(upgradeKey => {
        const upgrades = selectableUpgrades[upgradeKey]
        if (!upgrades || upgrades.length === 0) {
          return null
        }

        return (
          <Box key={`${rowId}-${upgradeKey}`} display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2" fontWeight="bold" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
              {upgradeKey === 'required' ? formatMessage(commonMessages.required) : formatMessage(commonMessages.optional)}
            </Typography>
            <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
              {upgrades.join(', ')}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

export { SelectableUpgradesField }
