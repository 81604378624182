import { POSIUnmappedTablesReasonCodeEnum } from '@sevenrooms/core/domain'
import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  title: {
    id: 'posiSettings.TableMappingWizard.UnmappedTablesStep.title',
    defaultMessage: 'You completed Table Mapping!',
  },
  body: {
    id: 'posiSettings.TableMappingWizard.UnmappedTablesStep.body',
    defaultMessage:
      "Some Tables aren't matched. To get the most out of the integration, complete any necessary steps to ensure every Table in use is mapped.",
  },
  sevenrooms: {
    id: 'posiSettings.TableMappingWizard.UnmappedTablesStep.sevenrooms',
    defaultMessage: 'SEVENROOMS',
  },
  pos: {
    id: 'posiSettings.TableMappingWizard.UnmappedTablesStep.pos',
    defaultMessage: 'POS',
  },
  back: {
    id: 'posiSettings.TableMappingWizard.UnmappedTablesStep.back',
    defaultMessage: 'Back',
  },
  reasonTitle: {
    id: 'posiSettings.TableMappingWizard.UnmappedTablesStep.reasonTitle',
    defaultMessage: 'Why are these unmatched?',
  },
  pleaseSelectAtLeastOneOption: {
    id: 'posiSettings.TableMappingWizard.UnmappedTablesStep.pleaseSelectAtLeastOneOption',
    defaultMessage: 'Please select at least one option',
  },

  // Reasons for unmatched tables
  [POSIUnmappedTablesReasonCodeEnum.MISSING_POS_TABLES]: {
    id: 'posiSettings.TableMappingWizard.UnmappedTablesStep.unmatchedReason.MISSING_POS_TABLES',
    defaultMessage: 'Missing table(s) in POS system',
  },
  [POSIUnmappedTablesReasonCodeEnum.MISSING_SEVENROOMS_TABLES]: {
    id: 'posiSettings.TableMappingWizard.UnmappedTablesStep.unmatchedReason.MISSING_SEVENROOMS_TABLES',
    defaultMessage: 'Missing table(s) in SevenRooms',
  },
  [POSIUnmappedTablesReasonCodeEnum.POS_TABLES_FOR_PRIVATE_EVENTS]: {
    id: 'posiSettings.TableMappingWizard.UnmappedTablesStep.unmatchedReason.POS_TABLES_FOR_PRIVATE_EVENTS',
    defaultMessage: 'POS table(s) are for Private Events',
  },
  [POSIUnmappedTablesReasonCodeEnum.INACCURATE_UNUSED_TABLES]: {
    id: 'posiSettings.TableMappingWizard.UnmappedTablesStep.unmatchedReason.INACCURATE_UNUSED_TABLES',
    defaultMessage: 'Table(s) are innacurate or unused',
  },
  [POSIUnmappedTablesReasonCodeEnum.OTHER]: {
    id: 'posiSettings.TableMappingWizard.UnmappedTablesStep.unmatchedReason.OTHER',
    defaultMessage: 'A different reason',
  },
} as const)
