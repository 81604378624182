import { useGetPOSITableMappingDataQuery } from '@sevenrooms/core/api'
import { Modal, type ModalHeaderProps } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { LoadingSettingsNoHeader } from '../../../Loading'
import { TableMappingWizard } from './TableMappingWizard'

export interface TableMappingWizardContainerProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
}

export function TableMappingWizardContainer({ closeHref }: TableMappingWizardContainerProps) {
  const { venueId } = useVenueContext()

  const { data: tableMappingData, isLoading, refetch: refetchTableMappingData } = useGetPOSITableMappingDataQuery({ venueId })

  return isLoading || !tableMappingData ? (
    <Modal ariaLabel="table-mapping-wizard-container">
      <LoadingSettingsNoHeader />
    </Modal>
  ) : (
    <TableMappingWizard closeHref={closeHref} tableMappingData={tableMappingData} refetchTableMappingData={refetchTableMappingData} />
  )
}
