import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  type AccessRule,
  AccessRuleAccessTimeEnum,
  AccessRuleInventoryTypeEnum,
  type AccessRules,
  type AudienceHierarchy,
  type Shift,
  type ShiftsByDate,
  type Experience,
} from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { TimeOnly, TimeInterval, DateOnly } from '@sevenrooms/core/timepiece'
import { notNullish } from '@sevenrooms/core/ui-kit/form'
import type {
  SeatingArea as AccessRuleSeatingArea,
  Upsells as AccessRulesUpsells,
} from '@sevenrooms/mgr-access-rules-slideout/AccessRule.types'
import { assignRandomInternalDisplayColor } from '@sevenrooms/mgr-access-rules-slideout/utils/displayColorUtils'
import { AccessRulesDataGrid } from '../../components/AccessRulesDataGrid/AccessRulesDataGrid'
import { DataGridSearchBar } from '../../components/AccessRulesDataGrid/DataGridSearchBar'
import { AccessRuleRange } from '../../enums/enums'
import { useAccessRulesUrlParams } from '../../hooks/useAccessRulesUrlParams'
import {
  getAudienceMap,
  getSeatingAreasMap,
  transformAudienceTiersForRow,
  transformAutomaticUpsellsForRow,
  transformBookStartTimesForRow,
  transformDurationsForRow,
  transformSelectableUpsellsForRow,
  getAccessRulesMap,
  getTablesMap,
} from '../../utils/accessRulesListUtils'
import { getColumnsMapping, getGroupByColumnMapping } from './columnDefinitions'
import type {
  AccessListRowAudience,
  AccessListRowBookStartTime,
  AccessListRowCoverLimit,
  AccessListRowDuration,
  AccessListRowGuestDurationPicker,
  AccessListRowOffer,
  AccessListRowPacing,
  AccessListRowPax,
  AccessListRowPayment,
  AccessListRowSchedule,
  AccessListRowSelectableUpgrades,
  AccessListRowTime,
} from './rowInterfaces'
import type { GridSortModel } from '@mui/x-data-grid'

interface AccessListProps {
  shifts?: ShiftsByDate
  accessRulesDay?: AccessRules
  accessRulesOverview?: AccessRule[]
  seatingAreas: AccessRuleSeatingArea
  audienceHierarchy: AudienceHierarchy[]
  experiences: Record<string, Experience>
  upsells: AccessRulesUpsells
  shiftsOverview?: Shift[]
}

interface IsFieldShift {
  duration?: boolean
  pax?: boolean
  seatingAreas?: boolean
  coverLimit?: boolean
  pacing?: boolean
  selectableUpgrades?: boolean
  paymentPolicy?: boolean
}

export interface SummarizedShift {
  name: string
  category: string
  startTime?: TimeOnly
  endTime?: TimeOnly
  startDate?: DateOnly
  endDate?: DateOnly
  date?: DateOnly
  archived: boolean
  daysOfWeek: boolean[]
  status?: string
}

export interface AccessRuleRow {
  id: string
  accessId: string
  persistentId: string
  shiftCategory?: string
  rule: string
  color: string
  schedule: AccessListRowSchedule
  time: AccessListRowTime
  pax: AccessListRowPax
  seatingAreas?: string[]
  tables?: string[]
  slotDescription: string
  paymentPolicy?: AccessListRowPayment
  audience: AccessListRowAudience
  duration?: AccessListRowDuration
  guestDurationPicker?: AccessListRowGuestDurationPicker
  coverLimit: AccessListRowCoverLimit
  bookStartTime: AccessListRowBookStartTime
  bookEndTime: string
  offer: AccessListRowOffer
  bundledUpgrades: string[]
  selectableUpgrades?: AccessListRowSelectableUpgrades
  pacing: AccessListRowPacing
  isFieldShift: IsFieldShift
  isOverviewRow?: boolean
  overviewCategory?: string
  isOverride: boolean
  accessTimeType?: string
  spansMultipleShifts?: boolean
  overviewShifts?: Shift[]
}

function createAccessRulesRows(
  accessRules: AccessRule[],
  shifts: Shift[],
  seatingAreasMap: Record<string, string>,
  tablesMap: Record<string, string>,
  audiencesMap: Record<string, string>,
  experiencesMap: Record<string, Experience>,
  upsellsMap: AccessRulesUpsells,
  isOverview = false,
  shiftsOverview?: Shift[]
): AccessRuleRow[] {
  const rows: AccessRuleRow[] = []
  const today = DateOnly.fromDate(new Date())

  for (const ar of accessRules) {
    let filteredShifts: Shift[] = []
    let overviewShifts: Shift[] = []
    const times: AccessListRowTime = { timeSlots: [], timeRanges: [], shiftCategories: [] }
    const isFieldShift: { [key: string]: boolean } = {}
    switch (ar.accessTimeType) {
      case AccessRuleAccessTimeEnum.ALL:
        // Overview case
        if (isOverview && shiftsOverview) {
          times.shiftCategories = ar.shiftCategories
          overviewShifts = [...shiftsOverview.filter(shift => shift.shiftCategory && ar.shiftCategories.includes(shift.shiftCategory))]
          break
        }

        // Non-overview case
        filteredShifts = shifts.filter(s => s.shiftCategory && ar.shiftCategories.includes(s.shiftCategory))

        times.timeRanges = filteredShifts.map(s => TimeInterval.fromSafe(s.startTime, s.endTime)).filter(notNullish)
        break
      case AccessRuleAccessTimeEnum.TIME_RANGE: {
        // Overview case
        if (isOverview && shiftsOverview) {
          if (ar.restrictToShifts) {
            times.shiftCategories = ar.shiftCategories
          }
          const timeRange = TimeInterval.fromSafe(ar.startTime, ar.endTime)
          times.timeRanges = [timeRange].filter(notNullish)
          overviewShifts = [
            ...shiftsOverview.filter(shift => timeRange?.isOverlapping(TimeInterval.fromSafe(shift.startTime, shift.endTime))),
          ]
          break
        }

        // Non-overview case
        if (ar.restrictToShifts) {
          filteredShifts = shifts.filter(s => s.shiftCategory && ar.shiftCategories.includes(s.shiftCategory))
        } else {
          const timeRange = TimeInterval.fromSafe(ar.startTime, ar.endTime)
          filteredShifts = shifts.filter(shift => timeRange?.isOverlapping(TimeInterval.fromSafe(shift.startTime, shift.endTime)))
        }
        times.timeRanges = [TimeInterval.fromSafe(ar.startTime, ar.endTime)].filter(notNullish)
        break
      }
      case AccessRuleAccessTimeEnum.SPECIFIC: {
        // Overview case
        if (isOverview && shiftsOverview) {
          if (ar.restrictToShifts) {
            times.shiftCategories = ar.shiftCategories
          }
          const specificTimes: TimeOnly[] = ar.specificTimes?.map(t => TimeOnly.fromSafe(t)).filter(notNullish) ?? []
          times.timeSlots = specificTimes
          overviewShifts = [
            ...shiftsOverview.filter(shift =>
              specificTimes.some(specificTime => {
                const specificTimeAsRange = TimeInterval.fromSafe(specificTime, specificTime)
                return specificTimeAsRange?.isOverlapping(TimeInterval.fromSafe(shift.startTime, shift.endTime))
              })
            ),
          ]
          break
        }

        // Non-overview case
        const specificTimes: TimeOnly[] = ar.specificTimes?.map(t => TimeOnly.fromSafe(t)).filter(notNullish) ?? []
        if (ar.restrictToShifts) {
          filteredShifts = shifts.filter(s => s.shiftCategory && ar.shiftCategories.includes(s.shiftCategory))
        } else {
          filteredShifts = shifts.filter(shift =>
            specificTimes.some(specificTime => {
              const specificTimeAsRange = TimeInterval.fromSafe(specificTime, specificTime)
              return specificTimeAsRange?.isOverlapping(TimeInterval.fromSafe(shift.startTime, shift.endTime))
            })
          )
        }
        times.timeSlots = specificTimes
        break
      }
      default:
        break
    }

    // Build possible AR values
    const audienceTiersAR = transformAudienceTiersForRow(ar.audienceTiers, audiencesMap)
    const guestDurationPicker = ar.durationMax && ar.durationMin ? { min: ar.durationMin, max: ar.durationMax } : undefined
    const durationsAR =
      ar.durationMinutesByPartySize && Object.keys(ar.durationMinutesByPartySize).length > 0 ? transformDurationsForRow(ar) : undefined
    const minPartySizeAR = ar.partySizeMin
    const maxPartySizeAR = ar.partySizeMax
    const seatingAreasAR = ar.seatingAreaIDS.length > 0 ? ar.seatingAreaIDS : undefined
    const tablesAR = ar.tableIDS.length > 0 ? ar.tableIDS : undefined
    const limitAR = { type: ar.inventoryType, limit: ar.inventoryCount }
    const defaultPacingAR = ar.defaultPacing ? String(ar.defaultPacing) : undefined
    const customPacingAR = Object.keys(ar.customPacing ?? {}).length > 0 ? ar.customPacing : undefined
    const bundledUpgradesAR = transformAutomaticUpsellsForRow(ar.selectedAutomaticUpsells, upsellsMap)
    const selectableUpgradesAR =
      ar.upsellCategories.length > 0 ? transformSelectableUpsellsForRow(ar.upsellCategories, upsellsMap) : undefined
    const paymentAR = !ar.useShiftPaymentAndPolicy
      ? {
          paymentRule: ar.ccPaymentRule,
          refundType: ar.autoChargeType,
          cancelType: ar.cancelCutoffType,
          cancelNum: ar.cancelCutoffNum,
          cancelHour: ar.cancelCutoffHour,
        }
      : undefined

    const bookStartTimesAR = transformBookStartTimesForRow(ar.audienceTiers)

    // Overview or None Category Day View (special case)
    if (isOverview || filteredShifts.length === 0) {
      const durations = guestDurationPicker ? transformDurationsForRow(ar) : durationsAR
      if (!durations && !guestDurationPicker) {
        isFieldShift.duration = true
      }
      const minPartySize = minPartySizeAR
      const maxPartySize = maxPartySizeAR
      if (!minPartySize && !maxPartySize) {
        isFieldShift.pax = true
      }
      const seatingAreas = seatingAreasAR
      if (!seatingAreas && !tablesAR) {
        isFieldShift.seatingAreas = true
      }
      const limit = limitAR
      if (!limit) {
        isFieldShift.coverLimit = true
      }
      const defaultPacing = defaultPacingAR
      const customPacing = customPacingAR
      if (!customPacing && !defaultPacingAR) {
        isFieldShift.pacing = true
      }
      const selectableUpgrades = selectableUpgradesAR
      if (!selectableUpgrades) {
        isFieldShift.selectableUpgrades = true
      }
      const payment = paymentAR
      if (!payment && ar.useShiftPaymentAndPolicy) {
        isFieldShift.paymentPolicy = true
      }

      let overviewCategory = 'ACTIVE'
      if (overviewShifts.length === 0) {
        overviewCategory = 'UNASSIGNED'
      } else if (
        (ar.startDate && ar.startDate.toJsDate().getTime() > today.toJsDate().getTime()) ||
        (ar.date && ar.date.toJsDate().getTime() > today.toJsDate().getTime())
      ) {
        overviewCategory = 'UPCOMING'
      }

      rows.push({
        id: isOverview ? `${ar.id}#OVERVIEW` : `${ar.id}#NONE`, // unique id for each row, needed for MUI
        accessId: ar.id,
        persistentId: ar.persistentId,
        shiftCategory: isOverview ? undefined : 'NONE',
        rule: ar.name,
        color: ar.internalDisplayColor ?? assignRandomInternalDisplayColor(),
        schedule: { dayOfWeek: ar.dayOfWeek ?? Array(7).fill(false), startDate: ar.startDate ?? ar.date, endDate: ar.endDate ?? ar.date },
        time: times,
        pax: { min: minPartySize, max: maxPartySize },
        seatingAreas:
          seatingAreas?.length === 0 || seatingAreas?.length === Object.keys(seatingAreasMap).length
            ? []
            : seatingAreas?.map(id => seatingAreasMap[id] ?? ''),
        tables:
          tablesAR?.length === 0 || tablesAR?.length === Object.keys(tablesMap).length ? [] : tablesAR?.map(id => tablesMap[id] ?? ''),
        slotDescription: ar.publicTimeSlotDescription ?? '', // always AR
        paymentPolicy: payment,
        audience: audienceTiersAR, // always AR
        duration: durations,
        guestDurationPicker,
        coverLimit: limit,
        bookStartTime: bookStartTimesAR, // always AR
        bookEndTime: ar.cutoffNum && ar.cutoffType ? `${String(ar.cutoffNum)} ${ar.cutoffType?.toLowerCase()}` : '', // always AR
        offer: experiencesMap[ar.experienceID ?? ''] ?? { name: '', isActive: false },
        bundledUpgrades: bundledUpgradesAR,
        selectableUpgrades,
        pacing: { custom: customPacing, default: defaultPacing },
        isFieldShift,
        isOverviewRow: true,
        overviewCategory,
        isOverride: ar.isOverride,
        accessTimeType: ar.accessTimeType,
        overviewShifts,
      })
      continue
    }

    // Day View
    for (const shift of filteredShifts) {
      // Build possible Shift values and use shift values if AR values are not present
      const durations = !durationsAR ? transformDurationsForRow(shift) : durationsAR
      if (!durationsAR) {
        isFieldShift.duration = true
      }
      const minPartySize = minPartySizeAR ?? shift.minPartySize
      const maxPartySize = maxPartySizeAR ?? shift.maxPartySize
      if (!minPartySizeAR && !maxPartySizeAR) {
        isFieldShift.pax = true
      }
      const seatingAreas = seatingAreasAR ?? shift.seatingAreaIds
      if (!seatingAreasAR && !tablesAR) {
        isFieldShift.seatingAreas = true
      }
      const limit =
        limitAR.type === AccessRuleInventoryTypeEnum.UNLIMITED && shift.maxCovers
          ? { type: AccessRuleInventoryTypeEnum.COVERS, limit: shift.maxCovers }
          : limitAR
      if (!limitAR) {
        isFieldShift.coverLimit = true
      }
      const defaultPacing = defaultPacingAR ?? String(shift.defaultPacing)
      if (!defaultPacingAR) {
        isFieldShift.pacing = true
      }
      const customPacing = customPacingAR ?? shift.customPacing
      if (!customPacingAR) {
        isFieldShift.pacing = true
      }
      const selectableUpgrades =
        !selectableUpgradesAR || (selectableUpgradesAR.optional.length === 0 && selectableUpgradesAR.required.length === 0)
          ? transformSelectableUpsellsForRow(shift.upsellCategories, upsellsMap)
          : selectableUpgradesAR
      if (!selectableUpgradesAR) {
        isFieldShift.selectableUpgrades = true
      }
      const payment = paymentAR ?? {
        paymentRule: shift.ccPaymentRule,
        refundType: shift.autoChargeType,
        cancelType: shift.cancelCutoffType,
        cancelNum: shift.cancelCutoffNum,
        cancelHour: shift.cancelCutoffHour,
      }
      if (!paymentAR) {
        isFieldShift.paymentPolicy = true
      }

      rows.push({
        id: `${ar.id}#${shift.shiftCategory}`, // unique id for each row, needed for MUI
        accessId: ar.id,
        persistentId: ar.persistentId,
        shiftCategory: shift.shiftCategory,
        rule: ar.name,
        color: ar.internalDisplayColor ?? assignRandomInternalDisplayColor(),
        schedule: { dayOfWeek: ar.dayOfWeek ?? Array(7).fill(false), startDate: ar.startDate ?? ar.date, endDate: ar.endDate ?? ar.date },
        time: times, // always shift start and end based on accessTimeType
        pax: { min: minPartySize, max: maxPartySize },
        seatingAreas:
          seatingAreas.length === 0 || seatingAreas.length === Object.keys(seatingAreasMap).length
            ? []
            : seatingAreas.map(id => seatingAreasMap[id] ?? ''),
        tables:
          tablesAR?.length === 0 || tablesAR?.length === Object.keys(tablesMap).length ? [] : tablesAR?.map(id => tablesMap[id] ?? ''),
        slotDescription: ar.publicTimeSlotDescription ?? '', // always AR
        paymentPolicy: payment,
        audience: audienceTiersAR, // always AR
        duration: durations,
        guestDurationPicker,
        coverLimit: limit,
        bookStartTime: bookStartTimesAR, // always AR
        bookEndTime: ar.cutoffNum && ar.cutoffType ? `${String(ar.cutoffNum)} ${ar.cutoffType?.toLowerCase()}` : '', // always AR
        offer: experiencesMap[ar.experienceID ?? ''] ?? { name: '', isActive: false },
        bundledUpgrades: bundledUpgradesAR,
        selectableUpgrades,
        pacing: { custom: customPacing, default: defaultPacing },
        isFieldShift,
        isOverviewRow: false,
        overviewCategory: undefined,
        isOverride: ar.isOverride,
        spansMultipleShifts: filteredShifts.length > 1,
      })
    }
  }

  if (!isOverview) {
    // Sort rows by shift category order for rendering
    const shiftCategoryOrder = ['NONE', 'BREAKFAST', 'BRUNCH', 'LUNCH', 'DAY', 'DINNER', 'LEGACY']
    rows.sort((a, b) => {
      if (a.shiftCategory && b.shiftCategory) {
        return shiftCategoryOrder.indexOf(a.shiftCategory) - shiftCategoryOrder.indexOf(b.shiftCategory)
      }
      return 0
    })
  } else {
    // Sort rows by overview category order for rendering
    const overviewCategories = ['UNASSIGNED', 'ACTIVE', 'UPCOMING']
    rows.sort((a, b) => {
      if (a.overviewCategory && b.overviewCategory) {
        return overviewCategories.indexOf(a.overviewCategory) - overviewCategories.indexOf(b.overviewCategory)
      }
      return 0
    })
  }

  return rows
}

const AccessRulesList = React.memo(
  ({
    shifts,
    accessRulesDay,
    accessRulesOverview,
    seatingAreas,
    audienceHierarchy,
    experiences,
    upsells,
    shiftsOverview,
  }: AccessListProps) => {
    const [{ date, range }] = useAccessRulesUrlParams()
    const { formatMessage } = useLocales()

    const [searchText, setSearchText] = useState('')
    const [sortModel, setSortModel] = useState<GridSortModel>([{ field: 'rule', sort: 'asc' }])

    const groupByColumnMapping = useMemo(() => getGroupByColumnMapping(formatMessage), [formatMessage])
    const columns = useMemo(() => getColumnsMapping(formatMessage), [formatMessage])

    const seatingAreasMap = useMemo(() => getSeatingAreasMap(seatingAreas), [seatingAreas])
    const tablesMap = useMemo(() => getTablesMap(seatingAreas), [seatingAreas])
    const audiencesMap = useMemo(() => getAudienceMap(audienceHierarchy), [audienceHierarchy])
    const experiencesMap = experiences
    const upsellsMap = upsells

    // force a reset of the sort model when changing the date
    useEffect(() => {
      setSortModel([{ field: 'rule', sort: 'asc' }])
    }, [date])

    const { filteredAccessRules, shiftsForDay } = useMemo(() => {
      const shiftsForDay = shifts ? shifts[date.toIso()] ?? [] : []
      if (range === AccessRuleRange.DAY && accessRulesDay) {
        const arDateKey = date.formatNYearNMonthNDay('en-us', true) // Key for accessRules object always in en-us format
        const accessRulesForDay = (accessRulesDay[arDateKey] ?? []).filter(
          (rule, index, self) => self.findIndex(r => r.id === rule.id) === index
        )
        return { filteredAccessRules: accessRulesForDay, shiftsForDay }
      } else if (range === AccessRuleRange.OVERVIEW && accessRulesOverview) {
        return { filteredAccessRules: accessRulesOverview, shiftsForDay }
      }
      return { filteredAccessRules: [], shiftsForDay }
    }, [shifts, date, range, accessRulesDay, accessRulesOverview])

    const accessRulesMap = useMemo(() => getAccessRulesMap(filteredAccessRules), [filteredAccessRules])

    const arRows = useMemo(
      () =>
        createAccessRulesRows(
          filteredAccessRules,
          shiftsForDay,
          seatingAreasMap,
          tablesMap,
          audiencesMap,
          experiencesMap,
          upsellsMap,
          range === AccessRuleRange.OVERVIEW,
          shiftsOverview
        ),
      [filteredAccessRules, shiftsForDay, seatingAreasMap, tablesMap, audiencesMap, experiencesMap, upsellsMap, range, shiftsOverview]
    )

    // Memoized filtering of arRows (search)
    const filteredRows = useMemo(() => {
      if (!searchText.trim()) {
        return arRows
      }

      const lowerCaseSearch = searchText.toLowerCase()
      return arRows.filter(
        row => row.rule.toLowerCase().includes(lowerCaseSearch) || row.slotDescription.toLowerCase().includes(lowerCaseSearch)
      )
    }, [searchText, arRows])

    const onSearch = useCallback((text: string) => {
      setSearchText(text)
    }, [])

    return (
      columns &&
      groupByColumnMapping && (
        <>
          <DataGridSearchBar onSearch={onSearch} />
          <AccessRulesDataGrid
            columns={columns}
            rows={filteredRows}
            groupBy={range === AccessRuleRange.DAY ? 'shiftCategory' : 'overviewCategory'}
            groupByColumnMappings={groupByColumnMapping}
            accessRulesMap={accessRulesMap}
            sortModel={sortModel}
            setSortModel={setSortModel}
          />
        </>
      )
    )
  }
)

export { AccessRulesList }
