import { useMemo } from 'react'
import type { POSITableMappingData } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'

export type TableMappingWizardFormData = ZodSchema<typeof useTableMappingWizardForm>
export type TableMappingWizardFormField = Field<TableMappingWizardFormData>

export const TableMappingFieldSchema = z.object({
  id: z.string().min(1),
  itemCode: z.string().min(1),
  posItemCode: z.string().min(1),
})

export const SeatingAreaFieldSchema = z.object({
  name: z.string().min(1),
  tableMappings: z.array(TableMappingFieldSchema),
})

export const UnmappedTablesReasonSchema = z.object({
  id: z.string(),
  isSelected: z.boolean(),
})

export function useTableMappingWizardForm() {
  return useMemo(
    () =>
      z.object({
        seatingAreas: z.array(SeatingAreaFieldSchema),
        unmappedTablesReasons: z
          .array(UnmappedTablesReasonSchema)
          // Verify that at least one checkbox is selected
          .refine(data => data.some(item => item.isSelected), { message: 'At least one option must be selected' }),
      }),
    []
  )
}

export const getTableMappingWizardFormDefaultValues = (tableMappingData: POSITableMappingData): TableMappingWizardFormData => {
  const { unmappedTablesReasons, unmappedTablesRecord } = tableMappingData.unmappedTablesData
  const selectedReasonIds = new Set(unmappedTablesRecord ? unmappedTablesRecord.unmappedTablesReasonIds : [])
  return {
    seatingAreas: tableMappingData.seatingAreas.map(seatingArea => ({
      name: seatingArea.name,
      tableMappings: seatingArea.tableItems.map(tableItem => ({
        id: tableItem.id,
        itemCode: tableItem.itemCode,
        posItemCode: tableItem.posItemCode ?? tableMappingData.suggestedMappings[tableItem.itemCode] ?? '',
      })),
    })),
    unmappedTablesReasons: unmappedTablesReasons.map(reason => ({
      id: reason.id,
      isSelected: selectedReasonIds.has(reason.id),
    })),
  }
}
