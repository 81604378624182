import SearchIcon from '@mui/icons-material/Search'
import { useEffect, useState, useCallback, memo } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { useDebounce } from '@sevenrooms/mgr-core/hooks/useDebounce'
import { Box } from '@sevenrooms/react-components/components/Box'
import { InputAdornment } from '@sevenrooms/react-components/components/InputAdornment'
import { OutlinedInput } from '@sevenrooms/react-components/components/OutlinedInput'
import { accessRulesListMessages } from '../../locales'

interface DataGridSearchBarProps {
  onSearch: (searchValue: string) => void
}

const DataGridSearchBar = memo(({ onSearch }: DataGridSearchBarProps) => {
  const { formatMessage } = useLocales()
  const [searchText, setSearchText] = useState('')
  const debouncedSearch = useDebounce(searchText, 300)

  useEffect(() => {
    onSearch(debouncedSearch)
  }, [debouncedSearch, onSearch])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
  }, [])

  // This component is rendered as an absolute positioned element in the AccessRulesDataGrid component so that we can
  // filter the data grid rows without having the re-render of the data grid clear our search box input.
  // This also allows us to make it look like it exists within the data grid. This hacky solution is necessary because
  // of how we have to render and pass the grouped data to the data grid
  return (
    <Box
      sx={{
        width: 'clamp(150px, 50%, 360px)',
        pr: 4,
        py: 2,
        flexShrink: 1,
        position: 'absolute',
        top: 2,
        right: 4,
        zIndex: 1,
        backgroundColor: 'common.white',
      }}
    >
      <OutlinedInput
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon fontSize="small" sx={{ color: 'text.secondary' }} />
          </InputAdornment>
        }
        placeholder={formatMessage(accessRulesListMessages.searchPlaceholder)}
        sx={{
          '& input[type=text].MuiInputBase-input': {
            width: 'inherit',
            pl: 0,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            m: 0,
          },
        }}
        fullWidth
        aria-label="Search by Name or Slot Description"
        id="access-rules-list-search"
        value={searchText}
        onChange={handleChange}
      />
    </Box>
  )
})

export { DataGridSearchBar }
