import { useLocales } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesListMessages } from '../../../locales'
import type { AccessListRowAudience } from '../../../views/AccessRulesList/rowInterfaces'

interface AudienceFieldProps {
  tiers: AccessListRowAudience
  rowId: string
}

function AudienceField({ tiers, rowId }: AudienceFieldProps) {
  const { formatMessage } = useLocales()
  const MAX_SINGLE_TIER_AUDIENCES = 6
  const MAX_MULTI_TIER_AUDIENCES = 3
  const tierKeys = Object.keys(tiers)

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {tierKeys.map(tierKey => {
        const audienceList = tiers[tierKey]
        if (!audienceList) {
          return null
        }
        const visibleAudiences =
          tierKeys.length === 1 ? audienceList?.slice(0, MAX_SINGLE_TIER_AUDIENCES) : audienceList?.slice(0, MAX_MULTI_TIER_AUDIENCES)
        const remainingCount = audienceList.length - visibleAudiences.length

        return (
          <Box key={`${rowId}-${tierKey}`} display="flex" flexDirection="column" gap={0.5}>
            <Typography variant="body2" fontWeight="bold">
              {tierKey}
            </Typography>
            <Typography variant="body2">
              {visibleAudiences.join(', ')}
              {remainingCount > 0 && formatMessage(accessRulesListMessages.andXMore, { count: remainingCount })}
            </Typography>
          </Box>
        )
      })}
    </Box>
  )
}

export { AudienceField }
