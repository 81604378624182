import { useMemo, useRef } from 'react'
import { v4 as uuid } from 'uuid'
import type { POSITableMappingData, SelectOption } from '@sevenrooms/core/domain'
import { type Path, useFieldArray, useFormContext } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import {
  Box,
  DividerLine,
  HStack,
  Modal,
  ModalActions,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  VStack,
} from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text } from '@sevenrooms/core/ui-kit/typography'
import { IGNORE_POS_ITEM_CODE } from '../TableMappingWizard.contants'
import { messages } from './TableMappingStep.locales'
import { TableMappingStepInfoCard } from './TableMappingStepInfoCard'
import { TableMappingStepRow } from './TableMappingStepRow'
import type { TableMappingWizardFormData, TableMappingWizardFormField } from '../TableMappingWizardForm.zod'

export interface TableMappingStepProps {
  field: TableMappingWizardFormField
  tableMappingData: POSITableMappingData
  onClose: () => void
  onSubmit: () => void
  isSaving: boolean
}

export function TableMappingStep({ field, tableMappingData, onClose, onSubmit, isSaving }: TableMappingStepProps) {
  const { formatMessage } = useLocales()
  const { fields: seatingAreasFieldArray } = useFieldArray(field, 'seatingAreas')

  const { getValues, formState, setValue, trigger, setFocus } = useFormContext<TableMappingWizardFormData>()
  const { errors } = formState

  const posItemCodeOptions: SelectOption[] = [
    ...tableMappingData.posItemCodes.map(posItemCode => ({ id: posItemCode, label: posItemCode })),
    { id: IGNORE_POS_ITEM_CODE, label: formatMessage(messages.ignoreLabel) },
  ]

  // Map for tracking selected posItemCodes and their paths
  const formValues = getValues()
  const initialSelectedPOSItemCodesMap = useMemo(() => {
    const initialMap = new Map<string, Path<TableMappingWizardFormData>>()
    formValues.seatingAreas.forEach((seatingArea, seatingAreaIndex) => {
      seatingArea.tableMappings.forEach((tableMapping, tableMappingIndex) => {
        const { posItemCode } = tableMapping
        if (posItemCode) {
          initialMap.set(posItemCode, `seatingAreas.${seatingAreaIndex}.tableMappings.${tableMappingIndex}.posItemCode`)
        }
      })
    })
    return initialMap
  }, [formValues.seatingAreas])
  const selectedPOSItemCodesMap = useRef<Map<string, Path<TableMappingWizardFormData>>>(initialSelectedPOSItemCodesMap)

  const handleSelectPOSItemCode = (posItemCode: string, currentPath: Path<TableMappingWizardFormData>) => {
    trigger(currentPath)
    if (posItemCode !== IGNORE_POS_ITEM_CODE) {
      // See if the table number has already been selected at another path
      const oldPath = selectedPOSItemCodesMap.current.get(posItemCode)

      // If so, clear the value at the existing path and revalidate to show/clear the error state
      if (oldPath && oldPath !== currentPath) {
        setValue(oldPath, '')
        trigger(oldPath)
      }

      // Update the map with the new path and update the ref with the updated map
      const updatedMap = new Map(selectedPOSItemCodesMap.current)
      updatedMap.set(posItemCode, currentPath)
      selectedPOSItemCodesMap.current = updatedMap
    }
  }

  const handleUnlinkPOSItemCode = (posItemCode: string, currentPath: Path<TableMappingWizardFormData>) => {
    setValue(currentPath, '', { shouldDirty: true })
    const updatedMap = new Map(selectedPOSItemCodesMap.current)
    updatedMap.delete(posItemCode)
    selectedPOSItemCodesMap.current = updatedMap
    trigger(currentPath)
  }

  const handleSubmit = () => {
    if (errors.seatingAreas?.length) {
      // If there are fields with errors in this step, set focus to the first one.
      for (let i = 0; i < errors.seatingAreas.length; i += 1) {
        const seatingAreaErrors = errors.seatingAreas[i]
        if (seatingAreaErrors?.tableMappings?.length) {
          for (let j = 0; j < seatingAreaErrors.tableMappings.length; j += 1) {
            const tableMappingError = seatingAreaErrors.tableMappings[j]?.posItemCode
            if (tableMappingError) {
              setFocus(`seatingAreas.${i}.tableMappings.${j}.posItemCode`)
              return
            }
          }
        }
      }
    } else {
      onSubmit()
    }
  }

  return (
    <Modal ariaLabel="Modal">
      <ModalHeader onClose={onClose}>
        <VStack spacing="s">
          <ModalTitle title={formatMessage(messages.title)} />
        </VStack>
      </ModalHeader>
      <ModalBody>
        <HStack spacing="l">
          <TableMappingStepInfoCard />
          {seatingAreasFieldArray.map(({ id: seatingAreaId }, seatingAreaIndex) => (
            <Box width="284px" key={`room-${seatingAreaId}`}>
              <VStack width="100%" spacing="s">
                <VStack width="100%">
                  <HStack width="100%" pl="s" spacing="s">
                    <Box width="16px" />
                    <SecondaryText width="110px">{formatMessage(messages.sevenroomsColumnHeader)}</SecondaryText>
                    <Box width="16px" />
                    <SecondaryText width="110px">{formatMessage(messages.posColumnHeader)}</SecondaryText>
                  </HStack>
                  <Box width="100%">
                    <DividerLine />
                  </Box>
                  <HStack width="100%" p="s" spacing="s" alignItems="center">
                    <Box width="16px" />
                    <Text width="100%" fontWeight="bold" fontSize="l">
                      {getValues(`seatingAreas.${seatingAreaIndex}.name`)}
                    </Text>
                  </HStack>
                </VStack>
                {seatingAreasFieldArray[seatingAreaIndex]?.tableMappings.map((_, tableMappingIndex) => {
                  const isRowError = !!errors.seatingAreas?.[seatingAreaIndex]?.tableMappings?.[tableMappingIndex]?.posItemCode
                  const itemCode = formValues.seatingAreas?.[seatingAreaIndex]?.tableMappings?.[tableMappingIndex]?.itemCode ?? ''
                  return (
                    <TableMappingStepRow
                      isRowError={isRowError}
                      itemCode={itemCode}
                      posItemCodeOptions={posItemCodeOptions}
                      field={field.prop(`seatingAreas.${seatingAreaIndex}.tableMappings.${tableMappingIndex}.posItemCode`)}
                      fieldPath={`seatingAreas.${seatingAreaIndex}.tableMappings.${tableMappingIndex}.posItemCode`}
                      handleSelectPOSItemCode={handleSelectPOSItemCode}
                      handleUnlinkPOSItemCode={handleUnlinkPOSItemCode}
                      key={`tableMapping-${uuid()}`}
                    />
                  )
                })}
              </VStack>
            </Box>
          ))}
        </HStack>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button onClick={onClose} variant="secondary" data-test="cancel-button" disabled={isSaving}>
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button variant="primary" onClick={handleSubmit} data-test="save-button" disabled={isSaving}>
            {formatMessage(commonMessages.save)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
