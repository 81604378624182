import { useLocales } from '@sevenrooms/core/locales'
import type { TimeInterval, TimeOnly } from '@sevenrooms/core/timepiece'
import { blueGrey } from '@sevenrooms/react-components'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesListMessages } from '../../../../locales'

interface TimeSlotChipProps {
  timeSlot: TimeOnly
}

interface TimeRangeChipProps {
  timeInterval: TimeInterval
}

function TimeSlotChip({ timeSlot }: TimeSlotChipProps) {
  const slotDisplay = timeSlot.formatSTime()

  return (
    <Box
      sx={{
        height: 22,
        width: 52,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: 1,
        py: 1,
        borderRadius: '4px',
        backgroundColor: blueGrey[200],
      }}
    >
      <Typography variant="body2" color="text.primary">
        {slotDisplay}
      </Typography>
    </Box>
  )
}

function TimeRangeChip({ timeInterval }: TimeRangeChipProps) {
  const rangeDisplay = timeInterval.formatSTime()
  return (
    <Box
      sx={{
        height: 22,
        width: 112,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: 1,
        py: 1,
        borderRadius: '4px',
        backgroundColor: blueGrey[200],
      }}
    >
      <Typography variant="body2" color="text.primary">
        {rangeDisplay}
      </Typography>
    </Box>
  )
}

function DefaultTimeChip() {
  const { formatMessage } = useLocales()
  return (
    <Box
      sx={{
        height: 22,
        width: 52,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        px: 1,
        py: 1,
        borderRadius: '4px',
        backgroundColor: blueGrey[50],
      }}
    >
      <Typography variant="body2" color="text.primary">
        {formatMessage(accessRulesListMessages.default)}
      </Typography>
    </Box>
  )
}

export { TimeRangeChip, TimeSlotChip, DefaultTimeChip }
