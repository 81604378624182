import { useLocales } from '@sevenrooms/core/locales'
import { blue } from '@sevenrooms/react-components'
import { Box } from '@sevenrooms/react-components/components/Box'
import { SvgIcon } from '@sevenrooms/react-components/components/SvgIcon'
import { Tooltip } from '@sevenrooms/react-components/components/Tooltip'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesListMessages } from '../../../locales'
import { repeatingDiagonalGradient } from '../../../utils/styleUtils'

interface RuleFieldProps {
  rule: string
  color: string
  spansMultipleShifts?: boolean
  isInvalid?: boolean
}

function RuleField({ rule, color, spansMultipleShifts, isInvalid = false }: RuleFieldProps) {
  const { formatMessage } = useLocales()
  return (
    <Box display="flex" position="relative" height="100%">
      <Box
        sx={{
          width: 4,
          height: '100%',
          position: 'absolute',
          left: 0,
          top: 0,
          borderRadius: 4,
          ...(isInvalid ? repeatingDiagonalGradient(color) : { backgroundColor: color }),
        }}
      />
      <Box display="flex" flexDirection="column" alignItems="flex-start" ml={2}>
        <Typography variant="body2" color="textPrimary">
          {rule}
        </Typography>
        {spansMultipleShifts && (
          <Tooltip title={formatMessage(accessRulesListMessages.spansMultipleShiftCategories)}>
            <Box
              display="felx"
              alignItems="center"
              p="1px"
              mt={1}
              height={18}
              width={18}
              sx={{
                backgroundColor: blue[50],
                borderRadius: '4px',
              }}
            >
              <SvgIcon sx={{ fontSize: '16px', color: 'text.primary' }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
                  <path d="M192-444v-72h273.24L645-696H528v-72h240v240h-72v-117L495-444H192Zm336 252v-72h117L531-378l51-51 114 114v-117h72v240H528Z" />
                </svg>
              </SvgIcon>
            </Box>
          </Tooltip>
        )}
      </Box>
    </Box>
  )
}

export { RuleField }
