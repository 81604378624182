import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  title: {
    id: 'posiSettings.TableMappingWizard.UnsavedChangesModal.title',
    defaultMessage: 'Are you sure you want to leave without saving?',
  },
  exitWithoutSaving: {
    id: 'posiSettings.TableMappingWizard.UnsavedChangesModal.exitWithoutSaving',
    defaultMessage: 'Exit Without Saving',
  },
} as const)
