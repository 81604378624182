import { toZonedTime } from 'date-fns-tz'
import { useCallback, useMemo } from 'react'
import { cleanNullish } from '@sevenrooms/core/api'
import { useLocation, useNavigation } from '@sevenrooms/core/navigation'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { routes } from '@sevenrooms/routes'
import { AccessRuleRange, AccessRuleView } from '../enums/enums'

export interface AccessRulesUrlParams {
  date: DateOnly
  view: AccessRuleView
  range: AccessRuleRange
  selectedAccessRuleId?: string
}

export function useAccessRulesUrlParams() {
  const { search } = useLocation()
  const { updateQuery } = useNavigation()

  const date = useDateParam()
  const params = useMemo(() => getParams(date, search), [date, search])

  const setParams = useCallback(
    (newParams: Partial<AccessRulesUrlParams>) => {
      const oldParams = getParams(date, search)
      const urlParams = { ...oldParams, ...newParams }
      updateQuery(
        routes.manager2.settings.availability.accessRulesSettings,
        cleanNullish({
          ...urlParams,
          date: urlParams.date.toIso(),
        }),
        'replace'
      )
    },
    [date, search, updateQuery]
  )
  return [params, setParams] as const
}

export function useDateParam() {
  const { search } = useLocation()
  const today = useVenueTodayDate()

  return useMemo(() => {
    const searchParams = new URLSearchParams(search)

    const dateParam = DateOnly.fromSafe(searchParams.get('date'))
    return dateParam != null ? dateParam : today
  }, [search, today])
}

export function useVenueTodayDate() {
  const { venueTimezone } = useAppContext()

  return useMemo(() => {
    const venueToday = toZonedTime(new Date(Date.now()), venueTimezone)
    return DateOnly.fromDate(venueToday)
  }, [venueTimezone])
}

function getParams(date: DateOnly, search?: string): AccessRulesUrlParams {
  const searchParams = new URLSearchParams(search)
  const view = searchParams.get('view') === AccessRuleView.LIST ? AccessRuleView.LIST : AccessRuleView.CALENDAR
  let range = AccessRuleRange.WEEK
  if (searchParams.get('range') === AccessRuleRange.DAY) {
    range = AccessRuleRange.DAY
  } else if (searchParams.get('range') === AccessRuleRange.OVERVIEW) {
    range = AccessRuleRange.OVERVIEW
  }

  const selectedAccessRuleId = searchParams.get('selected_access_rule_id')

  return cleanNullish({
    date,
    view,
    range,
    selectedAccessRuleId,
  })
}
