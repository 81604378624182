import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useCallback, useMemo, useState } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { DateOnly, startOfWeek, endOfWeek } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Button } from '@sevenrooms/react-components/components/Button'
import { DatePicker } from '@sevenrooms/react-components/components/DatePicker'
import { IconButton } from '@sevenrooms/react-components/components/IconButton'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { AccessRuleRange, AccessRuleView } from '../../enums/enums'
import { useAccessRulesUrlParams } from '../../hooks/useAccessRulesUrlParams'
import { accessRulesSettingsMessages } from '../../locales'

function AccessDatePicker() {
  const [{ date, view, range }, setParams] = useAccessRulesUrlParams()
  const [initialDate] = useState(date.toJsDate())
  const [datePickerOpen, setDatePickerOpen] = useState<boolean>(false)
  const { formatMessage } = useLocales()

  const isDisabled = view === AccessRuleView.LIST && range === AccessRuleRange.OVERVIEW

  const onChange = useCallback(
    async (d: Date | null) => {
      if (d == null) {
        return
      }
      const newDate = DateOnly.fromDate(d)
      setParams({ date: newDate })
    },
    [setParams]
  )

  const goToPrevious = () => {
    if (isDisabled) {
      return
    }
    const currentJsDate = date.toJsDate()
    currentJsDate.setDate(currentJsDate.getDate() - (range === AccessRuleRange.WEEK ? 7 : 1))
    const newDate = DateOnly.fromDate(currentJsDate)
    setParams({ date: newDate })
  }

  const goToNext = () => {
    if (isDisabled) {
      return
    }
    const currentJsDate = date.toJsDate()
    currentJsDate.setDate(currentJsDate.getDate() + (range === AccessRuleRange.WEEK ? 7 : 1))
    const newDate = DateOnly.fromDate(currentJsDate)
    setParams({ date: newDate })
  }

  const handleClickToday = () => {
    if (isDisabled) {
      return
    }
    const today = DateOnly.fromDate(new Date())
    setParams({ date: today })
  }

  const customFieldSlot = useMemo(
    () =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      function (params) {
        const { InputProps, value, ...otherProps } = params

        let displayDate = DateOnly.fromDate(value).formatNYearSMonthNDay()
        if (range === AccessRuleRange.WEEK) {
          const weekStart = startOfWeek(value, { weekStartsOn: 0 })
          const weekEnd = endOfWeek(value, { weekStartsOn: 0 })

          if (weekStart.getFullYear() !== weekEnd.getFullYear()) {
            displayDate = `${DateOnly.fromDate(weekStart).formatNYearSMonthNDay()} - ${DateOnly.fromDate(weekEnd).formatNYearSMonthNDay()}`
          } else {
            displayDate = `${DateOnly.fromDate(weekStart).formatSMonthNDay()} - ${DateOnly.fromDate(weekEnd).formatNYearSMonthNDay()}`
          }
        }
        return (
          <Box
            ref={InputProps?.ref}
            onClick={() => setDatePickerOpen(prev => !prev)}
            {...otherProps}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              padding: '8px 0',
              borderRadius: '0px',
              outline: 'none',
              height: 36,
              py: 0,
              opacity: 1,
            }}
          >
            <Typography variant="h3" fontWeight="bold" sx={{ whiteSpace: 'nowrap' }}>
              {displayDate}
            </Typography>
            <KeyboardArrowDownIcon fontSize="small" sx={{ height: 16, width: 16, ml: 1 }} />
          </Box>
        )
      },
    [range]
  )

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <Box width="70px">
        <Button
          size="small"
          data-test="today-btn"
          aria-label="Go to today"
          variant="outlined"
          fullWidth
          onClick={handleClickToday}
          disabled={isDisabled}
        >
          {formatMessage(accessRulesSettingsMessages.today)}
        </Button>
      </Box>
      <IconButton size="small" data-test="prev-day-arrow" onClick={goToPrevious} aria-label="Previous day/week" disabled={isDisabled}>
        <ArrowBackIosNewIcon fontSize="small" />
      </IconButton>
      <IconButton size="small" data-test="next-day-arrow" onClick={goToNext} aria-label="Next day/week" disabled={isDisabled}>
        <ArrowForwardIosIcon fontSize="small" />
      </IconButton>
      {range !== AccessRuleRange.OVERVIEW ? (
        <DatePicker
          open={datePickerOpen}
          onOpen={() => setDatePickerOpen(true)}
          onClose={() => setDatePickerOpen(false)}
          defaultValue={initialDate}
          value={date.toJsDate()}
          onChange={onChange}
          aria-label="Access Rules date picker"
          data-test="access-rules-date-picker"
          slots={{
            field: customFieldSlot,
          }}
        />
      ) : (
        <Typography variant="h3" fontWeight="bold" px={2}>
          {formatMessage(accessRulesSettingsMessages.availabilityOverview)}
        </Typography>
      )}
    </Stack>
  )
}

export { AccessDatePicker }
