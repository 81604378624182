import Slider from 'rc-slider'
import Handle from 'rc-slider/lib/Handle'
import React, { useMemo, useState } from 'react'
import 'rc-slider/assets/index.css'
import { useController, type Field } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { ErrorMessage, Label, CheckboxGroupSection, type CheckboxChoice } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { useVenueSettingsContext } from '@sevenrooms/mgr-core'
import { ShriftReportingPeriodSettingsMessages } from './ShiftReportingPeriods.locales'
import type { FormValues } from './ShiftReportingPeriods.zod'

interface HandleProps {
  className: string
  prefixCls?: string
  vertical?: boolean
  offset: number
  value: number
  dragging?: boolean
  disabled?: boolean
  min?: number
  max?: number
  reverse?: boolean
  index: number
  tabIndex?: number
  ariaLabel: string
  ariaLabelledBy: string
  ariaValueTextFormatter?: (value: number) => string
  style?: React.CSSProperties
}
enum MealPeriodEnum {
  OpenTime = 'OpenTime',
  Breakfast = 'breakfast',
  Brunch = 'brunch',
  Lunch = 'lunch',
  Dinner = 'dinner',
  NightTime = 'nightTime',
  CloseTime = 'CloseTime',
}
type MealPeriod = 'breakfast' | 'brunch' | 'lunch' | 'dinner' | 'nightTime'

type Ranges = {
  [key in MealPeriodEnum]?: number | null
}

const MealPeriodColors = {
  breakfast: '#C2DFC9',
  brunch: '#FFF2D4',
  lunch: '#FFC78E',
  dinner: '#F8BBD0',
  nightTime: '#C8E3F4',
}
interface MealRangeSelectorProps {
  field: Field<FormValues['shiftReportingPeriods'][number]>
}
export function MealRangeSelector({ field }: MealRangeSelectorProps) {
  const controller = useController(field)
  const [showReportingPeriodsError, setShowReportingPeriodsError] = useState(false)
  const { venueSettings } = useVenueSettingsContext()
  const formatTime = (totalMinutes: number, skipNonPairHours = true): React.ReactNode => {
    const [startHours, startMinutes, _unused] = (venueSettings?.start_of_day_time ?? '00:00:00').split(':').map(Number)
    const startTime = (startHours || 0) * 60 + (startMinutes || 0)

    const adjustedMinutes = (totalMinutes + startTime) % 1440
    const date = new Date()
    date.setHours(0, 0, 0, 0) // Reset time to midnight
    date.setMinutes(adjustedMinutes) // Add the adjusted minutes
    date.getMinutes()
    const formattedDateTime = new Intl.DateTimeFormat('en-US', {
      hour: 'numeric',
      minute: '2-digit',
      hour12: true,
    }).format(date)
    if (!skipNonPairHours) {
      return <span style={{ whiteSpace: 'nowrap' }}>{formattedDateTime}</span>
    }
    return (
      <VStack cursor="auto" pt="s" alignItems="center">
        <div
          style={{
            height: date.getMinutes() === 0 ? '18px' : '12px',
            borderRadius: '1px',
            width: '2px',
            backgroundColor: date.getMinutes() === 0 ? '#ADADAD' : '#CCCCCC',
          }}
        />
        {date.getHours() % 2 === (startHours || 0) % 2 && date.getMinutes() === 0 && (
          <span style={{ whiteSpace: 'nowrap' }}>{formattedDateTime}</span>
        )}
      </VStack>
    )
  }
  const generateMarks = (min: number, max: number, interval: number): Record<number, React.ReactNode> => {
    const marks: Record<number, React.ReactNode> = {}
    for (let i = min; i <= max; i += interval) {
      marks[i] = formatTime(i)
    }
    return marks
  }
  const ranges = useMemo<Ranges>(
    () => ({
      [MealPeriodEnum.OpenTime]: controller.field.value.breakfast.exists ? controller.field.value.breakfast.startTime : 0,
      [MealPeriodEnum.Breakfast]: controller.field.value.breakfast.exists ? controller.field.value.breakfast.endTime : undefined,
      [MealPeriodEnum.Brunch]: controller.field.value.brunch.exists ? controller.field.value.brunch.endTime : undefined,
      [MealPeriodEnum.Lunch]: controller.field.value.lunch.exists ? controller.field.value.lunch.endTime : undefined,
      [MealPeriodEnum.Dinner]: controller.field.value.dinner.exists ? controller.field.value.dinner.endTime : undefined,
      [MealPeriodEnum.NightTime]: controller.field.value.nightTime.exists ? controller.field.value.nightTime.endTime : undefined,
    }),
    [
      controller.field.value.breakfast.endTime,
      controller.field.value.breakfast.exists,
      controller.field.value.breakfast.startTime,
      controller.field.value.brunch.endTime,
      controller.field.value.brunch.exists,
      controller.field.value.dinner.endTime,
      controller.field.value.dinner.exists,
      controller.field.value.lunch.endTime,
      controller.field.value.lunch.exists,
      controller.field.value.nightTime.endTime,
      controller.field.value.nightTime.exists,
    ]
  )
  const { formatMessage } = useLocales()
  const makeReportingChoiceLabel = (period: MealPeriod): React.ReactNode => {
    if (!controller.field.value[period]?.exists) {
      return formatMessage(ShriftReportingPeriodSettingsMessages[period])
    }
    return (
      <span>
        {formatMessage(ShriftReportingPeriodSettingsMessages[period])} ({formatTime(controller.field.value[period]?.startTime || 0, false)}-
        {formatTime(controller.field.value[period]?.endTime || 1440, false)})
      </span>
    )
  }
  const reportingPeriodChoices = [
    {
      value: 'breakfast',
      label: makeReportingChoiceLabel('breakfast'),
    },
    {
      value: 'brunch',
      label: makeReportingChoiceLabel('brunch'),
    },
    {
      value: 'lunch',
      label: makeReportingChoiceLabel('lunch'),
    },
    {
      value: 'dinner',
      label: makeReportingChoiceLabel('dinner'),
    },
    {
      value: 'nightTime',
      label: makeReportingChoiceLabel('nightTime'),
    },
  ]
  const marks = generateMarks(0, 1440, 15)
  const handleRangeChange = (newValues: number[]): void => {
    // eslint-disable-next-line no-param-reassign
    newValues[0] = 0

    // eslint-disable-next-line no-param-reassign
    newValues[newValues.length - 1] = 1440
    for (let i = 1; i < newValues.length - 1; i += 1) {
      if ((newValues[i] || 0) - (newValues[i - 1] || 0) < 15) {
        return
      }
    }
    let cursor = 0
    const reportingTimes = controller.field.value
    reportingPeriodChoices.forEach(period => {
      const periodValue = period.value as MealPeriod
      if (reportingTimes[periodValue]?.exists) {
        reportingTimes[periodValue].startTime = newValues[cursor] || 0
        reportingTimes[periodValue].endTime = newValues[cursor + 1] || 1440
        cursor += 1
      }
      controller.field.onChange(reportingTimes)
    })
  }

  const existingReportingPeriods = useMemo(() => {
    const existingReportingPeriods = []
    if (controller.field.value.breakfast.exists) {
      existingReportingPeriods.push('breakfast')
    }
    if (controller.field.value.brunch.exists) {
      existingReportingPeriods.push('brunch')
    }
    if (controller.field.value.lunch.exists) {
      existingReportingPeriods.push('lunch')
    }
    if (controller.field.value.dinner.exists) {
      existingReportingPeriods.push('dinner')
    }
    if (controller.field.value.nightTime.exists) {
      existingReportingPeriods.push('nightTime')
    }
    return existingReportingPeriods
  }, [
    controller.field.value.breakfast.exists,
    controller.field.value.brunch.exists,
    controller.field.value.dinner.exists,
    controller.field.value.lunch.exists,
    controller.field.value.nightTime.exists,
  ])
  const getNextPeriod = (period: MealPeriod): MealPeriod | undefined => {
    const periods: MealPeriod[] = ['breakfast', 'brunch', 'lunch', 'dinner', 'nightTime']
    const index = periods.indexOf(period)

    for (let i = index + 1; i < periods.length; i += 1) {
      const nextPeriod = periods[i]
      if (nextPeriod !== undefined && controller.field.value[nextPeriod]?.exists) {
        return nextPeriod
      }
    }
    return undefined
  }
  const getPrevPeriod = (period: MealPeriod): MealPeriod | undefined => {
    const periods: MealPeriod[] = ['breakfast', 'brunch', 'lunch', 'dinner', 'nightTime']
    const index = periods.indexOf(period)

    for (let i = index - 1; i >= 0; i -= 1) {
      const prevPeriod = periods[i]
      if (prevPeriod !== undefined && controller.field.value[prevPeriod]?.exists) {
        return prevPeriod
      }
    }
    return undefined
  }
  const addReportingPeriod = (period: MealPeriod) => {
    const nextReportingPeriod = getNextPeriod(period)
    const prevReportingPeriod = getPrevPeriod(period)
    if (nextReportingPeriod && prevReportingPeriod) {
      const startTime = Math.floor(
        ((controller.field.value[prevReportingPeriod]?.endTime || 1440) - (controller.field.value[prevReportingPeriod]?.startTime || 0)) /
          2 +
          (controller.field.value[prevReportingPeriod]?.startTime || 0)
      )
      const endTime = Math.floor(
        (controller.field.value[nextReportingPeriod]?.startTime || 0) +
          ((controller.field.value[nextReportingPeriod]?.endTime || 1440) - (controller.field.value[nextReportingPeriod]?.startTime || 0)) /
            2
      )
      controller.field.onChange({
        ...controller.field.value,
        [prevReportingPeriod]: { ...controller.field.value[prevReportingPeriod], endTime: startTime },
        [period]: { exists: true, startTime, endTime },
        [nextReportingPeriod]: { ...controller.field.value[nextReportingPeriod], startTime: endTime },
      })
    }
    if (nextReportingPeriod && !prevReportingPeriod) {
      const endTime = Math.floor(
        (controller.field.value[nextReportingPeriod]?.startTime || 0) +
          ((controller.field.value[nextReportingPeriod]?.endTime || 0) - (controller.field.value[nextReportingPeriod]?.startTime || 0)) / 2
      )
      const startTime = controller.field.value[nextReportingPeriod]?.startTime
      controller.field.onChange({
        ...controller.field.value,
        [period]: { exists: true, startTime, endTime },
        [nextReportingPeriod]: { ...controller.field.value[nextReportingPeriod], startTime: endTime },
      })
    }
    if (!nextReportingPeriod && prevReportingPeriod) {
      const startTime = Math.floor(
        (controller.field.value[prevReportingPeriod]?.startTime || 0) +
          ((controller.field.value[prevReportingPeriod]?.endTime || 0) - (controller.field.value[prevReportingPeriod]?.startTime || 0)) / 2
      )
      const endTime = controller.field.value[prevReportingPeriod]?.endTime
      controller.field.onChange({
        ...controller.field.value,
        [prevReportingPeriod]: { ...controller.field.value[prevReportingPeriod], endTime: startTime },
        [period]: { exists: true, startTime, endTime },
      })
    }
  }

  const removeReportingPeriod = (period: MealPeriod) => {
    const nextReportingPeriod = getNextPeriod(period)
    const prevReportingPeriod = getPrevPeriod(period)
    const oldReportingPeriods = {
      ...controller.field.value,
      [period]: { exists: false, startTime: null, endTime: null },
    }
    if (nextReportingPeriod) {
      oldReportingPeriods[nextReportingPeriod] = {
        ...oldReportingPeriods[nextReportingPeriod],
        startTime: controller.field.value[period].startTime,
      }
    } else if (prevReportingPeriod) {
      oldReportingPeriods[prevReportingPeriod] = {
        ...oldReportingPeriods[prevReportingPeriod],
        endTime: 1440,
      }
    }
    controller.field.onChange(oldReportingPeriods)
  }
  const handleCheckboxChecked = (choice: CheckboxChoice<string>, checked: boolean): void => {
    if (existingReportingPeriods.length < 3 && !checked) {
      setShowReportingPeriodsError(true)
      return
    }
    setShowReportingPeriodsError(false)
    if (checked) {
      addReportingPeriod(choice.value as MealPeriod)
    } else {
      removeReportingPeriod(choice.value as MealPeriod)
    }
  }
  const handleNode = (props: HandleProps) => {
    const { dragging, value, offset } = props
    return (
      <div>
        {dragging && (
          <div
            style={{
              position: 'absolute',
              background: '#09223A',
              color: '#fff',
              padding: '16px',
              left: `${offset}%`,
              transform: 'translateX(-50%)',
              top: '-85px',
              borderRadius: '4px',
              zIndex: '9',
            }}
          >
            {formatTime(value, false)}
            <div
              style={{
                content: "''",
                position: 'absolute',
                bottom: '-10px',
                left: '50%',
                transform: 'translateX(-50%)',
                width: 0,
                height: 0,
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderTop: '10px solid #0E1A2B',
              }}
            />
          </div>
        )}
        <Handle {...props} />
      </div>
    )
  }
  return (
    <VStack spacing="l">
      <Box>
        <HStack spacing="m" mt="l">
          {Object.entries(MealPeriodColors).map(
            ([period, color]) =>
              controller.field.value[period as MealPeriod].exists && (
                <Box key={period} display="inline-block">
                  <div
                    style={{
                      display: 'inline-block',
                      width: '10px',
                      height: '10px',
                      backgroundColor: color,
                      borderRadius: '50%',
                    }}
                  />
                  <Box display="inline-block" ml="xxs">
                    {formatMessage(ShriftReportingPeriodSettingsMessages[period as MealPeriod])}
                  </Box>
                </Box>
              )
          )}
        </HStack>
        <Box mt="m" mb="xxl">
          <Slider.Range
            min={0}
            max={1440}
            step={15}
            marks={marks}
            dotStyle={{ display: 'none' }}
            railStyle={{ display: 'none' }}
            handle={handleNode}
            handleStyle={[
              { display: 'None' },
              ...Array.from(
                {
                  length: Math.max(
                    Object.keys(MealPeriodColors).filter(period => controller.field.value[period as MealPeriod].exists).length - 1,
                    0
                  ),
                },
                () => ({
                  backgroundColor: '#347BAF',
                  borderColor: '#347BAF',
                  height: '20px',
                  width: '20px',
                })
              ),
              { display: 'None' },
            ]}
            value={Object.values(ranges).filter(value => value !== undefined) as number[]}
            onChange={handleRangeChange}
            trackStyle={Object.entries(MealPeriodColors)
              .filter(([period]) => controller.field.value[period as MealPeriod]?.exists)
              .map(([, color]) => ({ backgroundColor: color, height: '12px' }))}
            allowCross={false}
          />
        </Box>
      </Box>
      <Box>
        <Label primary={formatMessage(ShriftReportingPeriodSettingsMessages.shiftReportingPeriods)}>
          <CheckboxGroupSection
            name="periods"
            data-test="existing-periods"
            choices={reportingPeriodChoices}
            selected={existingReportingPeriods}
            onChange={handleCheckboxChecked}
            canNestingCheckboxBeSelected
          />
          {showReportingPeriodsError && (
            <ErrorMessage>{formatMessage(ShriftReportingPeriodSettingsMessages.shiftReportingPeriodsLessThanTwo)}</ErrorMessage>
          )}
        </Label>
      </Box>
    </VStack>
  )
}
