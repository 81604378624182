import { useCallback, useEffect } from 'react'
import * as React from 'react'
import { useConnectWhatsAppMutation, useGetWhatsAppConnectionQuery } from '@sevenrooms/core/api'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { FacebookButton } from '@sevenrooms/core/ui-kit/core'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Box, Flex, HStack, Image, Loader } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { messagingIntegrationMessages } from '../MessagingIntegrations/MessagingIntegrations.locales'
import WhatsAppLogo from './assets/whatsapp_inline_green.svg'

export function WhatsAppIntegration() {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const [connectWhatsAppMutation, { isLoading: isSubmitting }] = useConnectWhatsAppMutation()
  const { isFetching, data: wabaConnection, refetch } = useGetWhatsAppConnectionQuery({ venueId: venue.id })
  const onLoginFB = useCallback(async () => {
    window.FB?.login(
      (_response: facebook.StatusResponse) => {
        // Since you are using Twilio's APIs, you do not need to do anything with the response here.
      },
      {
        config_id: `${window.WHATSAPP_CONFIG_ID}`,
        auth_type: 'rerequest', // avoids "user is already logged" in errors if users click the button again before refreshing the page
        response_type: 'code',
        override_default_response_type: true,
        extras: {
          sessionInfoVersion: 2, // required to get WABA ID
          featureType: 'only_waba_sharing',
          // leave below commented for now, you will need to replace this once your app is approved by Meta
          setup: {
            solutionID: `${window.WHATSAPP_SOLUTION_ID}`,
          },
        },
      }
    )
  }, [])

  const embeddedSignupInfoListener = useCallback(
    (event: { origin: string; data: string }) => {
      // listen for embedded signup events
      if (event.origin !== 'https://www.facebook.com') {
        return
      }
      try {
        // eslint-disable-next-line camelcase
        const data: { type: string; event: string; data: { phone_number_id: string; waba_id: string; current_step: string } } = JSON.parse(
          event.data
        )
        if (data.type === 'WA_EMBEDDED_SIGNUP') {
          // if user finishes the Embedded Signup flow and the popup window closes
          if (data.event === 'FINISH' || data.event === 'FINISH_ONLY_WABA') {
            // eslint-disable-next-line camelcase
            const { waba_id } = data.data
            connectWhatsAppMutation({ venueId: venue.id, whatsappBusinessAccountId: waba_id }).then(() => {
              refetch()
            })
          } else {
            // otherwise the user canceled the Embedded Signup flow by closing the popup window before the last step
          }
        }
      } catch (e) {
        if (!event.data || !event.data.includes('=')) {
          // eslint-disable-next-line no-console
          console.error('Something very strange happened here', e, event)
        }
      }
    },
    [venue, connectWhatsAppMutation, refetch]
  )
  useEffect(() => {
    window.addEventListener('message', embeddedSignupInfoListener)
    return () => {
      window.removeEventListener('message', embeddedSignupInfoListener)
    }
  }, [embeddedSignupInfoListener])

  let boxContent

  if (isSubmitting || isFetching) {
    boxContent = <Loader />
  } else if (!wabaConnection?.whatsappBusinessAccountId) {
    boxContent = (
      <FacebookButton data-test="whatsapp-integration-facebook-button" onClick={onLoginFB} height="40px" disabled={isSubmitting} />
    )
  } else {
    boxContent = (
      <Button
        disabled={isSubmitting}
        variant="secondary"
        data-test="whatsapp-integration-deactivate-btn"
        onClick={() => {
          connectWhatsAppMutation({ venueId: venue.id, whatsappBusinessAccountId: null }).then(() => {
            // eslint-disable-next-line no-restricted-globals
            refetch()
          })
        }}
      >
        {formatMessage(commonMessages.deactivate)}
      </Button>
    )
  }

  return (
    <Box p="m" width="100%">
      <HStack spacing="m" alignItems="center">
        <Image src={WhatsAppLogo} alt={formatMessage(messagingIntegrationMessages.whatsappLogoAlt)} width="225px" />
        <Flex flex={1} justifyContent="end" alignItems="center">
          {boxContent}
        </Flex>
      </HStack>
    </Box>
  )
}
