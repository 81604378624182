import { useEffect, useRef, useState } from 'react'

/**
 * Hook to get the pixel width of the parent container.
 * @returns { [React.RefObject<HTMLDivElement>, number] } - A ref to attach and the parent's width in pixels.
 */
export function useParentWidth(): [React.RefObject<HTMLDivElement>, number] {
  const parentRef = useRef<HTMLDivElement>(null)
  const [parentWidth, setParentWidth] = useState<number>(0)

  useEffect(() => {
    const updateParentWidth = () => {
      if (parentRef.current) {
        const width = parentRef.current.parentElement?.offsetWidth || window.innerWidth
        setParentWidth(width)
      }
    }

    updateParentWidth()

    window.addEventListener('resize', updateParentWidth)

    return () => window.removeEventListener('resize', updateParentWidth)
  }, [])

  return [parentRef, parentWidth]
}
