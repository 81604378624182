import MenuBookIcon from '@mui/icons-material/MenuBook'
import PeopleIcon from '@mui/icons-material/People'
import React from 'react'
import type { AccessRuleInventoryType } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesListMessages } from '../../../locales'

interface CoverLimitFieldProps {
  type: AccessRuleInventoryType
  limit?: number | undefined
  isFieldShift: boolean
}

function CoverLimitField({ type, limit, isFieldShift }: CoverLimitFieldProps) {
  const { formatMessage } = useLocales()
  const renderIconAndValue = (icon: React.ReactNode, value: number) => (
    <Box display="flex" alignItems="center">
      <Box display="inline-flex" sx={{ mt: type === 'RESERVATIONS' ? '-2px' : 0 }}>
        {icon}
      </Box>
      <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'} pl={1}>
        {value}
      </Typography>
    </Box>
  )

  if (type === 'UNLIMITED' || limit === 0) {
    return (
      <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
        {formatMessage(accessRulesListMessages.noLimit)}
      </Typography>
    )
  }

  return type === 'COVERS'
    ? renderIconAndValue(<PeopleIcon sx={{ fontSize: 16, color: isFieldShift ? 'text.secondary' : 'text.primary' }} />, limit ?? 0)
    : renderIconAndValue(<MenuBookIcon sx={{ fontSize: 16, color: isFieldShift ? 'text.secondary' : 'text.primary' }} />, limit ?? 0)
}

export { CoverLimitField }
