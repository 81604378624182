import TodayIcon from '@mui/icons-material/Today'
import { useLocales } from '@sevenrooms/core/locales'
import type { DateOnly } from '@sevenrooms/core/timepiece'
import { blueGrey } from '@sevenrooms/react-components'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Tooltip } from '@sevenrooms/react-components/components/Tooltip'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesListMessages } from '../../../locales'

interface ScheduleCellProps {
  daysOfWeek: boolean[]
  startDate: DateOnly
  endDate: DateOnly | undefined
  isOverride: boolean
  rowId: string
}

function ScheduleField({ daysOfWeek, startDate, endDate, isOverride, rowId }: ScheduleCellProps) {
  const { formatMessage } = useLocales()
  const startDateDisplay = startDate
  const endDateDisplay = endDate
  const allDays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']

  // Reorder daysOfWeek array to match Sunday-to-Saturday order
  const reorderedDays = [daysOfWeek[6], ...daysOfWeek.slice(0, 6)]

  const dateFormatted =
    isOverride || startDateDisplay.toIso() === endDateDisplay?.toIso()
      ? startDateDisplay.formatNYearNMonthNDay()
      : `${startDateDisplay.formatNYearNMonthNDay()} - ${
          endDateDisplay ? endDateDisplay.formatNYearNMonthNDay() : formatMessage(accessRulesListMessages.indefinite)
        } `

  if (isOverride) {
    reorderedDays[startDate.toJsDate().getDay()] = true
  }

  return (
    <Box display="flex" flexDirection="column" alignItems="start">
      <Box display="flex" justifyContent="center" gap="4px" mb={1}>
        {allDays.map((day, index) => (
          <Box
            key={`${rowId}-${allDays[index]}`}
            sx={{
              width: 26,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '4px',
              p: 1,
              backgroundColor: reorderedDays[index] ? blueGrey[100] : blueGrey[50],
            }}
          >
            <Typography variant="body2" color={reorderedDays[index] ? 'text.primary' : 'common.white'}>
              {day}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box display="flex" gap={0.5}>
        {isOverride && (
          <Tooltip title={formatMessage(accessRulesListMessages.override)}>
            <TodayIcon sx={{ fontSize: 16, color: 'text.primary' }} />
          </Tooltip>
        )}
        <Typography variant="body2" color="text.primary" align="center">
          {dateFormatted}
        </Typography>
      </Box>
    </Box>
  )
}

export { ScheduleField }
