import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  title: {
    id: 'posiSettings.TableMappingWizard.TableMappingStep.title',
    defaultMessage: 'Match Tables',
  },
  sevenroomsColumnHeader: {
    id: 'posiSettings.TableMappingWizard.TableMappingStep.sevenroomsColumnHeader',
    defaultMessage: 'SEVENROOMS',
  },
  posColumnHeader: {
    id: 'posiSettings.TableMappingWizard.TableMappingStep.posColumnHeader',
    defaultMessage: 'POS',
  },
  seatingArea: {
    id: 'posiSettings.TableMappingWizard.TableMappingStep.seatingArea',
    defaultMessage: 'SEATING AREA',
  },
  room: {
    id: 'posiSettings.TableMappingWizard.TableMappingStep.room',
    defaultMessage: 'ROOM',
  },
  floorplanLayout: {
    id: 'posiSettings.TableMappingWizard.TableMappingStep.floorplanLayout',
    defaultMessage: 'FLOORPLAN LAYOUT',
  },
  partySize: {
    id: 'posiSettings.TableMappingWizard.TableMappingStep.partySize',
    defaultMessage: 'PARTY SIZE',
  },
  ignoreLabel: {
    id: 'posiSettings.TableMappingWizard.TableMappingStep.ignoreLabel',
    defaultMessage: 'Ignore',
  },
} as const)
