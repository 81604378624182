import React, { createContext, type PropsWithChildren, useContext, useMemo, useState } from 'react'
import type { AccessRule, ShiftCategory } from '@sevenrooms/core/domain'
import { AccessRulesSlideout } from '@sevenrooms/mgr-access-rules-slideout/AccessRulesSlideout'
import { useAccessRulesSlideoutData } from '@sevenrooms/mgr-access-rules-slideout/useAccessRulesSlideoutData'
import { useVenueContext } from '@sevenrooms/mgr-core/hooks/useVenueContext'
import { AccessRuleRange, AccessRuleView } from '../enums/enums'
import { useAccessRulesUrlParams, useVenueTodayDate } from '../hooks/useAccessRulesUrlParams'

interface ShowAccessRuleSlideoutProps {
  mode?: 'new-item'
  startDate?: Date
  accessRule?: AccessRule
  shiftCategories?: ShiftCategory[]
}

interface SlideoutContextType {
  showAccessRuleSlideout: (props?: ShowAccessRuleSlideoutProps) => void
  hideAccessRuleSlideout: () => void
}

const SlideoutContext = createContext<SlideoutContextType | null>(null)

export function AccessRuleSlideoutProvider({ children }: PropsWithChildren<unknown>) {
  const [slideoutProps, setSlideoutProps] = useState<ShowAccessRuleSlideoutProps | null>(null)
  const { data: accessRulesSlideoutData } = useAccessRulesSlideoutData()
  const [{ date, view, range }] = useAccessRulesUrlParams()
  const { venue } = useVenueContext()
  const today = useVenueTodayDate()

  const selectedDate = useMemo(() => {
    if (slideoutProps?.startDate) {
      return slideoutProps.startDate.toISOString()
    }
    return range === AccessRuleRange.OVERVIEW ? today.toIso() : date.toIso()
  }, [date, range, slideoutProps?.startDate, today])

  const startDate = useMemo(() => {
    if (slideoutProps?.startDate) {
      return slideoutProps.startDate
    }
    if (range === AccessRuleRange.OVERVIEW) {
      return today.toJsDate()
    }
    return date.toJsDate() < today.toJsDate() ? today.toJsDate() : date.toJsDate()
  }, [date, range, slideoutProps?.startDate, today])

  const showAccessRuleSlideout = useMemo(
    () => (props?: ShowAccessRuleSlideoutProps) => {
      setSlideoutProps({
        mode: 'new-item',
        shiftCategories: [],
        ...props,
      })
    },
    []
  )

  if (!venue) {
    return <>{children}</>
  }

  const hideAccessRuleSlideout = () => {
    setSlideoutProps(null)
  }

  const onRuleUpdate = () => {
    hideAccessRuleSlideout()
  }

  return (
    <SlideoutContext.Provider value={{ showAccessRuleSlideout, hideAccessRuleSlideout }}>
      {children}
      {slideoutProps && accessRulesSlideoutData && (
        <AccessRulesSlideout
          mode={slideoutProps.mode}
          accessRule={slideoutProps.accessRule}
          selectedDay={selectedDate}
          onClose={hideAccessRuleSlideout}
          onRuleSave={onRuleUpdate}
          onRuleDelete={onRuleUpdate}
          startDate={startDate}
          shiftCategories={slideoutProps.shiftCategories || []}
          accessRulesSlideoutData={accessRulesSlideoutData}
          handleFocusableElements={view === AccessRuleView.LIST}
        />
      )}
    </SlideoutContext.Provider>
  )
}

export const useAccessRuleSlideout = () => {
  const context = useContext(SlideoutContext)
  if (!context) {
    throw new Error('useAccessRuleSlideout must be used within an AccessRuleSlideoutProvider')
  }
  return context.showAccessRuleSlideout
}
