import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import type { AccessListRowBookStartTime } from '../../../views/AccessRulesList/rowInterfaces'

interface BookStartTimeFieldProps {
  startTimeTiers: AccessListRowBookStartTime
  rowId: string
}

function BookStartTimeField({ startTimeTiers, rowId }: BookStartTimeFieldProps) {
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {Object.entries(startTimeTiers).map(
        ([tierKey, startTime]) =>
          startTime && (
            <Box key={`${rowId}-${tierKey}`} display="flex" flexDirection="column" gap={0.5}>
              <Typography variant="body2" fontWeight="bold">
                {tierKey}
              </Typography>
              <Typography variant="body2">{startTime}</Typography>
            </Box>
          )
      )}
    </Box>
  )
}

export { BookStartTimeField }
