import PeopleIcon from '@mui/icons-material/People'
import React from 'react'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import type { AccessListRowPax } from '../../../views/AccessRulesList/rowInterfaces'

interface PartySizeFieldProps {
  pax: AccessListRowPax
  isFieldShift: boolean
}

function PartySizeField({ pax, isFieldShift }: PartySizeFieldProps) {
  return (
    <Box display="flex" alignItems="center" gap="4px">
      <PeopleIcon sx={{ fontSize: 16, color: isFieldShift ? 'text.secondary' : 'text.primary' }} />
      <Typography variant="body2" color={isFieldShift ? 'textSecondary' : 'textPrimary'}>
        {`${pax.min} - ${pax.max}`}
      </Typography>
    </Box>
  )
}

export { PartySizeField }
