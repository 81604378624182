import _ from 'lodash'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { SideNavigation } from '@sevenrooms/core/ui-kit/layout'
import { AppRoot, useUserContext, useVenueContext } from '@sevenrooms/mgr-core'
// eslint-disable-next-line import/no-relative-packages
import * as GlobalActions from '../../../../application/site/static/app/manager/lib/actions/GlobalActions'
import { useNavigationItems } from './hooks'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let window: any

export function LegacySideNavigation() {
  return (
    <AppRoot>
      <NavComponent />
    </AppRoot>
  )
}

function NavComponent() {
  const dispatch = useDispatch()
  const { venue } = useVenueContext()
  const user = useUserContext()
  const navigationItems = useNavigationItems(true)

  useEffect(() => {
    if (window.globalInit) {
      dispatch(GlobalActions.initializeAction(window.globalInit))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (_.isEmpty(venue) || _.isEmpty(user)) {
    return null
  }

  return <SideNavigation venue={venue?.internalDisplayName || ''} user={user?.id || ''} items={navigationItems} />
}
