import { defineMessages } from '@sevenrooms/core/locales'

export const accessRulesSettingsMessages = defineMessages({
  title: {
    id: 'accessRulesSettings.title',
    defaultMessage: 'Access Rules',
  },
  subtitle: {
    id: 'accessRulesSettings.subtitle',
    defaultMessage:
      'Control your external availability on your Reservation Widget and Third Party Booking Channels and ensure that they are driving covers and revenue. <a>Learn More</a>',
  },
  createAccessRule: {
    id: 'accessRulesSettings.createAccessRule',
    defaultMessage: 'Create Access Rule',
  },
  viewReservationWidget: {
    id: 'accessRulesSettings.viewReservationWidget',
    defaultMessage: 'View Reservation Widget',
  },
  viewAccessRuleActivityLog: {
    id: 'accessRulesSettings.viewAccessRuleActivityLog',
    defaultMessage: 'View Access Rule Activity Log',
  },
  needHelp: {
    id: 'accessRulesSettings.needHelp',
    defaultMessage: 'Need Help?',
  },
  bulkCopy: {
    id: 'accessRulesSettings.bulkCopy',
    defaultMessage: 'Bulk Copy',
  },
  bulkCopyProgressing: {
    id: 'accessRulesSettings.bulkCopyProgressing',
    defaultMessage: 'Bulk Copy Progressing...',
  },
  bulkCopyLoading: {
    id: 'accessRulesSettings.bulkCopyLoading',
    defaultMessage: 'Bulk Copy Status Loading...',
  },
  today: {
    id: 'accessRulesSettings.today',
    defaultMessage: 'Today',
  },
  week: {
    id: 'accessRulesSettings.week',
    defaultMessage: 'Week',
  },
  overview: {
    id: 'accessRulesSettings.overview',
    defaultMessage: 'Overview',
  },
  day: {
    id: 'accessRulesSettings.day',
    defaultMessage: 'Day',
  },
  availabilityOverview: {
    id: 'accessRulesSettings.availabilityOverview',
    defaultMessage: 'Availability Overview',
  },
  calendar: {
    id: 'accessRulesSettings.calendar',
    defaultMessage: 'Calendar',
  },
  list: {
    id: 'accessRulesSettings.list',
    defaultMessage: 'List',
  },
  compact: {
    id: 'accessRulesSettings.compact',
    defaultMessage: 'Compact',
  },
  expanded: {
    id: 'accessRulesSettings.expanded',
    defaultMessage: 'Expanded',
  },
} as const)

export const accessRulesListMessages = defineMessages({
  andXMore: {
    id: 'accessRulesList.andXMore',
    defaultMessage: 'and {count} more...',
  },
  xMore: {
    id: 'accessRulesList.xMore',
    defaultMessage: '{count} more...',
  },
  xMoreTimes: {
    id: 'accessRulesList.xMoreTimes',
    defaultMessage: '{count} more times...',
  },
  noLimit: {
    id: 'accessRulesList.noLimit',
    defaultMessage: 'No Limit',
  },
  allSeatingAreas: {
    id: 'accessRulesList.allSeatingAreas',
    defaultMessage: 'All Seating Areas',
  },
  indefinite: {
    id: 'accessRulesList.indefinite',
    defaultMessage: 'Indefinite',
  },
  upToReservationTime: {
    id: 'accessRulesList.upToReservationTime',
    defaultMessage: 'Up to reservation time',
  },
  hours: {
    id: 'accessRulesList.duration.hours',
    defaultMessage: `{hours, plural,
    one {{hours} hr}
    other {{hours} hrs}
  }`,
  },
  minutes: {
    id: 'accessRulesList.duration.minutes',
    defaultMessage: `{minutes, plural,
    one {{minutes} min}
    other {{minutes} mins}
  }`,
  },
  hoursAndMinutes: {
    id: 'accessRulesList.duration.hoursAndMinutes',
    defaultMessage: `{hoursPlural, select,
      true {{hours} hrs}
      other {{hours} hr}
    } {minutesPlural, select,
      true {and {minutes} mins}
      other {and {minutes} min}
    }`,
  },
  followShift: {
    id: 'accessRulesList.followShift',
    defaultMessage: 'Follows Shift',
  },
  default: {
    id: 'accessRulesList.default',
    defaultMessage: 'Default',
  },
  noAccessRules: {
    id: 'accessRulesList.noAccessRules',
    defaultMessage: 'No Access Rules',
  },
  searchPlaceholder: {
    id: 'accessRulesList.searchPlaceholder',
    defaultMessage: 'Search by Name or Slot Description',
  },
  restrictedByShift: {
    id: 'accessRulesList.restrictedByShift',
    defaultMessage: 'Restricted by Shift',
  },
  guestDurationPicker: {
    id: 'accessRulesList.guestDurationPicker',
    defaultMessage: 'Guest Duration Picker',
  },
  guestDurationMin: {
    id: 'accessRulesList.guestDurationMin',
    defaultMessage: 'Min',
  },
  guestDurationMax: {
    id: 'accessRulesList.guestDurationMax',
    defaultMessage: 'Max',
  },
  spansMultipleShiftCategories: {
    id: 'accessRulesList.spansMultipleShiftCategories',
    defaultMessage: 'Spans multiple Shift Categories',
  },
  override: {
    id: 'accessRulesList.override',
    defaultMessage: 'Override',
  },
})

export const accessRulesListCategoriesMessages = defineMessages({
  breakfastCategory: {
    id: 'accessRulesListCategory.breakfastCategory',
    defaultMessage: 'Breakfast',
  },
  brunchCategory: {
    id: 'accessRulesListCategory.brunchCategory',
    defaultMessage: 'Brunch',
  },
  lunchCategory: {
    id: 'accessRulesListCategory.lunchCategory',
    defaultMessage: 'Lunch',
  },
  dayCategory: {
    id: 'accessRulesListCategory.dayCategory',
    defaultMessage: 'Day',
  },
  dinnerCategory: {
    id: 'accessRulesListCategory.dinnerCategory',
    defaultMessage: 'Dinner',
  },
  legacyCategory: {
    id: 'accessRulesListCategory.nightCategory',
    defaultMessage: 'Night',
  },
  noneCategory: {
    id: 'accessRulesListCategory.noneCategory',
    defaultMessage: 'None',
  },
  unassignedCategory: {
    id: 'accessRulesListCategory.unassignedCategory',
    defaultMessage: 'Unassigned',
  },
  activeCategory: {
    id: 'accessRulesListCategory.activeCategory',
    defaultMessage: 'Active',
  },
  upcomingCategory: {
    id: 'accessRulesListCategory.upcomingCategory',
    defaultMessage: 'Upcoming',
  },
})

export const PaymentPolicyMessages = defineMessages({
  paymentChoiceNone: {
    id: 'ar.paymentPolicy.paymentChoiceNone',
    defaultMessage: 'No payment required',
  },
  paymentChoiceLater: {
    id: 'ar.paymentPolicy.paymentChoiceLater',
    defaultMessage: 'Require credit card',
  },
  paymentChoiceImmediately: {
    id: 'ar.paymentPolicy.paymentChoiceImmediately',
    defaultMessage: 'Charge credit card',
  },
  refundChoiceNone: {
    id: 'ar.paymentPolicy.refundChoiceNone',
    defaultMessage: 'No refund',
  },
  refundChoiceFull: {
    id: 'ar.paymentPolicy.refundChoiceFull',
    defaultMessage: 'Full refund',
  },
  refundChoicePartial: {
    id: 'ar.paymentPolicy.refundChoicePartial',
    defaultMessage: 'Partial refund',
  },
  penaltyChoiceNone: {
    id: 'ar.paymentPolicy.penaltyChoiceNone',
    defaultMessage: 'No refund',
  },
  penaltyChoiceCharge: {
    id: 'ar.paymentPolicy.penaltyChoiceCharge',
    defaultMessage: 'Automatic cancellation/no-show fee',
  },
  cancelChoiceAnyTime: {
    id: 'ar.paymentPolicy.cancelChoiceAnyTime',
    defaultMessage: 'Modify/cancel at any time',
  },
  cancelChoiceNever: {
    id: 'ar.paymentPolicy.cancelChoiceNever',
    defaultMessage: 'Cannot modify/cancel',
  },
  upUntilGivenCutoff: {
    id: 'ar.paymentPolicy.upUntilGivenCutoff',
    defaultMessage: `{time, select,
      0 { {unit, select,
          MINUTES { {num, plural,
              one {Modify/cancel up until one minute before reservation}
              other {Modify/cancel up until {num} minutes before reservation}
            } }
          DAYS { {num, plural,
              one {Modify/cancel up until one day before reservation}
              other {Modify/cancel up until {num} days before reservation}
            } }
          other { {num, plural,
              one {Modify/cancel up until one hour before reservation}
              other {Modify/cancel up until {num} hours before reservation}
            } }
        } }
      other { {unit, select,
          MINUTES { {num, plural,
              one {Modify/cancel up until one minute before {time}}
              other {Modify/cancel up until {num} minutes before {time}}
            } }
          DAYS { {num, plural,
              one {Modify/cancel up until one day before {time}}
              other {Modify/cancel up until {num} days before {time}}
            } }
          other { {num, plural,
              one {Modify/cancel up until one hour before {time}}
              other {Modify/cancel up until {num} hours before {time}}
            } }
        } }
    }`,
  },
})

export const accessListHeaderMessages = defineMessages({
  ruleHeader: {
    id: 'accessListHeader.ruleHeader',
    defaultMessage: 'Rule',
  },
  scheduleHeader: {
    id: 'accessListHeader.scheduleHeader',
    defaultMessage: 'Schedule',
  },
  timeHeader: {
    id: 'accessListHeader.timeHeader',
    defaultMessage: 'Time',
  },
  paxHeader: {
    id: 'accessListHeader.paxHeader',
    defaultMessage: 'Party Size',
  },
  seatingAreasHeader: {
    id: 'accessListHeader.seatingAreasHeader',
    defaultMessage: 'Seating Areas',
  },
  slotDescriptionHeader: {
    id: 'accessListHeader.slotDescriptionHeader',
    defaultMessage: 'Slot Description',
  },
  paymentPolicyHeader: {
    id: 'accessListHeader.paymentPolicyHeader',
    defaultMessage: 'Payment',
  },
  audienceHeader: {
    id: 'accessListHeader.audienceHeader',
    defaultMessage: 'Audience',
  },
  coverLimitHeader: {
    id: 'accessListHeader.coverLimitHeader',
    defaultMessage: 'Limit',
  },
  durationHeader: {
    id: 'accessListHeader.durationHeader',
    defaultMessage: 'Duration',
  },
  bookStartTimeHeader: {
    id: 'accessListHeader.bookStartTimeHeader',
    defaultMessage: 'Book Start Time',
  },
  bookEndTimeHeader: {
    id: 'accessListHeader.bookEndTimeHeader',
    defaultMessage: 'Book Cutoff Time',
  },
  offerHeader: {
    id: 'accessListHeader.offerHeader',
    defaultMessage: 'Offer',
  },
  bundledUpgradesHeader: {
    id: 'accessListHeader.bundledUpgradesHeader',
    defaultMessage: 'Bundled Upgrades',
  },
  selectableUpgradesHeader: {
    id: 'accessListHeader.selectableUpgradesHeader',
    defaultMessage: 'Selectable Upgrades',
  },
  pacingHeader: {
    id: 'accessListHeader.pacingHeader',
    defaultMessage: 'Pacing',
  },
  shiftCategoryHeader: {
    id: 'accessListHeader.shiftCategoryHeader',
    defaultMessage: 'Shift Category',
  },
  overviewCategoryHeader: {
    id: 'accessListHeader.overviewCategoryHeader',
    defaultMessage: 'Category',
  },
} as const)
