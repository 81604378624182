import React from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesListMessages } from '../../../locales'

interface BookEndTimeFieldProps {
  bookEndTime: string
}

function BookEndTimeField({ bookEndTime }: BookEndTimeFieldProps) {
  const { formatMessage } = useLocales()
  const displayText = bookEndTime === '' ? formatMessage(accessRulesListMessages.upToReservationTime) : bookEndTime

  return (
    <Box>
      <Typography variant="body2">{displayText}</Typography>
    </Box>
  )
}

export { BookEndTimeField }
