import { defineMessages } from '@sevenrooms/core/locales'

export const backwriterSettingsMessages = defineMessages({
  header: {
    id: 'settings.general.backwriter.header',
    defaultMessage: '{icon} AI Responses',
  },
  enabledTitle: {
    id: 'settings.general.backwriter.enabledTitle',
    defaultMessage: 'Enabled',
  },
  enabledDescription: {
    id: 'settings.general.backwriter.enabledDescription',
    defaultMessage:
      'Save time and draft consistent replies to guest emails, SMS, Private Line messages, reviews, and feedback. Learn more about <a>AI Responses in the Help Center</a>.',
  },
  emailResponseHeader: {
    id: 'settings.general.backwriter.email.header',
    defaultMessage: 'Email Response Settings',
  },
  emailSignature: {
    id: 'settings.general.backwriter.email.signature',
    defaultMessage: 'Sign emails as:',
  },
  emailGeneralTuning: {
    id: 'settings.general.backwriter.email.generalTuning',
    defaultMessage: 'The general tone of voice for responses should be:',
  },
  smsHeader: {
    id: 'settings.general.backwriter.sms.header',
    defaultMessage: 'Text Response Settings',
  },
  smsGeneralTuning: {
    id: 'settings.general.backwriter.sms.generalTuning',
    defaultMessage: 'The general tone of voice for responses should be:',
  },
  reviewsHeader: {
    id: 'settings.general.backwriter.reviews.header',
    defaultMessage: 'Review Response Settings',
  },
  reviewsGeneralTuning: {
    id: 'settings.general.backwriter.reviews.generalTuning',
    defaultMessage: 'The general tone of voice for review and SevenRooms feedback responses should be:',
  },
  clientNotesHeader: {
    id: 'settings.general.backwriter.clientNotes.header',
    defaultMessage: '{icon} AI Note Polish',
  },
  clientNotesDescription: {
    id: 'settings.general.backwriter.clientNotesDescription',
    defaultMessage:
      'Reformat and polish Client Notes on the SevenRooms mobile app. Learn more about <a>AI Note Polish in the Help Center</a>.',
  },
  clientNotesGeneralTuning: {
    id: 'settings.general.backwriter.clientNotes.generalTuning',
    defaultMessage: 'The structure of polished Client Notes should be:',
  },
  emailGeneralTuningPlaceholder: {
    id: 'settings.general.backwriter.email.generalTuning.placeholder',
    defaultMessage: 'Professional, warm, and polished. Keep it courteous, well-structured, and personalized when possible.',
  },
  emailSignaturePlaceholder: {
    id: 'settings.general.backwriter.email.signature.placeholder',
    defaultMessage: '[Your Name], | [Restaurant Name] Team',
  },
  smsGeneralTuningPlaceholder: {
    id: 'settings.general.backwriter.sms.generalTuning.placeholder',
    defaultMessage: 'Friendly, concise, and clear. Keep it warm and efficient without being overly casual.',
  },
  reviewsGeneralTuningPlaceholder: {
    id: 'settings.general.backwriter.reviews.generalTuning.placeholder',
    defaultMessage: 'Gracious and professional. Thank guests, acknowledge feedback, and reinforce hospitality.',
  },
})
