// magic numbers from stripesgenerator.com
export function repeatingDiagonalGradient(color: string) {
  const c = `hsl(from ${color} h calc(s - 30) calc(l - 20))`
  const background = `linear-gradient(
  45deg,
  ${c} 10%, transparent 10%, transparent 50%, ${c} 50%, ${c} 60%, transparent 60%, transparent 100%
)`

  const backgroundSize = '7.07px 7.07px'

  return { background, backgroundSize }
}
