import { useLocales } from '@sevenrooms/core/locales'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesListMessages } from '../../../locales'

interface BundledUpgradesFieldProps {
  bundledUpgrades: string[]
}

function BundledUpgradesField({ bundledUpgrades }: BundledUpgradesFieldProps) {
  const { formatMessage } = useLocales()
  const MAX_DISPLAY = 7
  const displayedUpgrades = bundledUpgrades.slice(0, MAX_DISPLAY)
  const remainingCount = bundledUpgrades.length - MAX_DISPLAY

  return (
    <Typography variant="body2">
      {displayedUpgrades.join(', ')}
      {remainingCount > 0 && formatMessage(accessRulesListMessages.andXMore, { count: remainingCount })}
    </Typography>
  )
}

export { BundledUpgradesField }
