import { useCallback, useEffect, useMemo, useRef } from 'react'
import type { ReservationWidgetSettingsV2 } from '@sevenrooms/core/domain'
import { type Field, useWatchMany, useWatch } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { generatePath, useDestination } from '@sevenrooms/core/navigation'
import { TimeOnly } from '@sevenrooms/core/timepiece'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import {
  Checkbox,
  FormMultiSelect,
  FormNumberInput,
  FormSelect,
  FormSelectRangePicker,
  Label,
  Radio,
  RadioGroup,
  RadioGroupToggle,
} from '@sevenrooms/core/ui-kit/form'
import { BaseSection, Box, HStack, SettingsSection, VStack } from '@sevenrooms/core/ui-kit/layout'
import { SecondaryText, Text, Link } from '@sevenrooms/core/ui-kit/typography'
import { useDurationOptions, useVenueContext } from '@sevenrooms/mgr-core'
import { routes } from '@sevenrooms/routes'
import { CopyLinkButton } from '../components'
import { generalMessages } from '../General/General.locales'
import { searchAvailability } from './SeachAvailability.locales'
import type { SearchAvailabilityForm } from '../ReservationSettings.zod'

export interface SearchAvailabilityProps {
  field: Field<SearchAvailabilityForm>
  data: ReservationWidgetSettingsV2
}

const DURATION_INTERVAL_OPTIONS = [15, 30, 60]
const DURATION_PICKER_HELP_PAGE_LINK = 'https://help.sevenrooms.com/hc/en-us/articles/8718626669851-Access-Rule-Guest-Duration-Picker'

export function SearchAvailability({ field, data }: SearchAvailabilityProps) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const { query } = useDestination(routes.manager2.settings.widgetSettings.reservationWidgetSettingsV2)
  const { activeEl } = query
  const durationPickerRef = useRef<HTMLDivElement>(null)
  const [availabilityTimeRange, enableRequests, reservationHoldEnabled, durationPickerDefaultEnabled] = useWatchMany(field, [
    'availabilityTimeRange',
    'enableRequests',
    'reservationHoldEnabled',
    'durationPickerDefaultEnabled',
  ])
  const venueSpecificMarketingOnForRequest = useWatch(field.prop('venueSpecificMarketingOnForRequest'))
  const venueGroupMarketingOnForRequest = useWatch(field.prop('venueGroupMarketingOnForRequest'))
  const tailoredCommunicationOnForRequest = useWatch(field.prop('tailoredCommunicationOnForRequest'))
  const venueSmsMarketingOnForRequest = useWatch(field.prop('venueSmsMarketingOnForRequest'))
  const durationOptions = useDurationOptions()
  const formattedDurationOptions = useMemo(() => {
    const options = durationOptions.map(option => {
      const minutes = option.id
      const timeOnly = TimeOnly.from({ minutes })
      return {
        id: option.id,
        label: timeOnly.formatNTimeDuration(),
      }
    })
    options.unshift({
      id: 0,
      label: formatMessage(searchAvailability.durationPickerDurationDefaultValue),
    })
    return options
  }, [durationOptions, formatMessage])
  const formattedDurationIntervalOptions = useMemo(
    () =>
      DURATION_INTERVAL_OPTIONS.map(minutes => {
        const timeOnly = TimeOnly.from({ minutes })
        return {
          id: minutes,
          label: timeOnly.formatNTimeDuration(),
        }
      }),
    []
  )
  const newResWidgetPathWithoutDurationPicker = generatePath(
    routes.explore.reservations.create.search.path,
    { venueKey: venue.urlKey },
    { duration_picker: false }
  )
  const newResWidgetUrlWithoutDurationPicker = `https://${window.location.host}${newResWidgetPathWithoutDurationPicker}`
  const minutesInDay = 24 * 60
  const timesChoices = useCallback(
    (interval: number) => {
      const timeRange = []
      for (let i = 0; i < minutesInDay; i += interval) {
        const to = new TimeOnly({ hours: 0, minutes: i, seconds: 0, milliseconds: 0, timezoneOffset: 0 })
        timeRange.push({
          label: TimeOnly.fromJsDate(to.toJsDate()).formatSTime(),
          id: to.toHoursMinutesIso(),
        })
      }
      return timeRange
    },
    [minutesInDay]
  )

  const timesChoicesForRange = useMemo(() => timesChoices(30), [timesChoices])

  const timesChoicesForSpecial = useMemo(() => timesChoices(15), [timesChoices])

  const minGuestOptions = useMemo(
    (): SelectOption<number | string>[] =>
      Array.from({ length: 20 }, (_, index) => ({
        id: index + 1,
        label: String(index + 1),
      })),
    []
  )
  const maxGuestOptions = Array.from({ length: 100 }, (_, index) => ({
    id: index + 1,
    label: String(index + 1),
  }))

  useEffect(() => {
    if (activeEl === 'durationPicker') {
      // there is a bug with our animation and scrollIntoView method, so used scroll on window
      const top = durationPickerRef.current?.offsetTop
      window.scrollTo({ top, behavior: 'smooth' })
    }
  }, [activeEl])

  return (
    <VStack spacing="lm" mt="lm">
      <BaseSection title={formatMessage(searchAvailability.searchCriteria)} padding="xl">
        <VStack spacing="l" width="100%" mt="l" pl="xl" mb="xxl" maxWidth="50%">
          <Box>
            <Box pb="s">
              <VStack spacing="xs">
                <Text fontSize="m" fontWeight="bold">
                  {formatMessage(searchAvailability.guestPickerRange)}
                </Text>
                <SecondaryText>{formatMessage(searchAvailability.guestPickerRangeDescr)}</SecondaryText>
              </VStack>
            </Box>
            <FormSelectRangePicker
              field={field.prop('guests')}
              minSelectProps={{ options: minGuestOptions, label: formatMessage(searchAvailability.minGuest) }}
              maxSelectProps={{ options: maxGuestOptions, label: formatMessage(searchAvailability.maxGuest) }}
            />
          </Box>
          <Box>
            <VStack spacing="xs">
              <Text fontSize="m" fontWeight="bold">
                {formatMessage(searchAvailability.timePickerIncrements)}
              </Text>
              <SecondaryText>{formatMessage(searchAvailability.timePickerIncrementsDescr)}</SecondaryText>
            </VStack>
            <Box pt="s">
              <VStack>
                <Box>
                  <Radio value="all" field={field.prop('availabilityTimeRange')} checked={availabilityTimeRange === 'all'}>
                    {formatMessage(searchAvailability.all)}
                  </Radio>
                </Box>
                <Box pt="s">
                  <Radio
                    value="customTimeRange"
                    field={field.prop('availabilityTimeRange')}
                    checked={availabilityTimeRange === 'customTimeRange'}
                  >
                    {formatMessage(searchAvailability.customTimeRange)}
                  </Radio>
                </Box>
                {availabilityTimeRange === 'customTimeRange' && (
                  <Box pl="xs">
                    <Box pl="lm" pt="xs">
                      <FormSelectRangePicker
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        field={field.prop('seating')}
                        minSelectProps={{ options: timesChoicesForRange, label: formatMessage(searchAvailability.firstSeating) }}
                        maxSelectProps={{ options: timesChoicesForRange, label: formatMessage(searchAvailability.lastSeating) }}
                      />
                    </Box>
                  </Box>
                )}
                <Box pt="s">
                  <Radio
                    value="specificTimeSlots"
                    field={field.prop('availabilityTimeRange')}
                    checked={availabilityTimeRange === 'specificTimeSlots'}
                  >
                    {formatMessage(searchAvailability.specificTimeSlots)}
                  </Radio>
                </Box>
              </VStack>
            </Box>

            {availabilityTimeRange === 'specificTimeSlots' && (
              <Box pl="l" pt="s">
                <FormMultiSelect
                  field={field.prop('specificTimeSlotsArray')}
                  options={timesChoicesForSpecial}
                  useCheckboxItems
                  searchable
                  placeholder={formatMessage(searchAvailability.selectTimeSlot)}
                />
              </Box>
            )}
          </Box>
          <Box>
            <VStack spacing="xs">
              <Text fontSize="m" fontWeight="bold">
                {formatMessage(searchAvailability.datePickerCalendarLength)}
              </Text>
              <SecondaryText>{formatMessage(searchAvailability.datePickerCalendarLengthDescr)}</SecondaryText>
            </VStack>
            <Box width="46%" pt="s">
              <FormNumberInput
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                field={field.prop('maxDaysOut')}
                greaterThanZero
                required
              />
            </Box>
          </Box>
        </VStack>
      </BaseSection>

      <BaseSection title={formatMessage(searchAvailability.reservationRequests)} padding="xl">
        <VStack spacing="l" mt="l" pl="xl" mb="xxl">
          <HStack>
            <Checkbox field={field.prop('enableRequests')} disabled={data.searchAvailability.priorityAlertsEnabled}>
              <VStack>
                <Box pt="xs" pb="xs">
                  <Text fontWeight="bold">{formatMessage(searchAvailability.priorityAlertRequests)}</Text>
                </Box>
                <SecondaryText>
                  {formatMessage(
                    data.searchAvailability.priorityAlertsEnabled
                      ? searchAvailability.priorityAlertRequestsDescr
                      : searchAvailability.reservationRequestsDescr
                  )}
                </SecondaryText>
                {enableRequests && (
                  <Box pt="s">
                    <RadioGroup
                      field={field.prop('enableRequestsType')}
                      data-test="enableRequestsType"
                      choices={[
                        { label: formatMessage(searchAvailability.shiftLabel), value: 'searched_time' },
                        {
                          label: formatMessage(searchAvailability.accessRulesLabel),
                          info: <>{formatMessage(searchAvailability.accessRulesTooltip)}</>,
                          value: 'active_booking_window',
                        },
                      ]}
                    />
                  </Box>
                )}
              </VStack>
            </Checkbox>
          </HStack>
          {data.checkoutConfirmation.isSmsMarketingEnabled && (
            <HStack>
              <Box>
                <Checkbox field={field.prop('venueSmsMarketingOnForRequest')} data-test="venueSmsMarketingOnForRequest">
                  <Text fontWeight="bold">{formatMessage(searchAvailability.venueSmsMarketingOn)}</Text>
                </Checkbox>
                {venueSmsMarketingOnForRequest && (
                  <Box pt="s" pl="lm">
                    <RadioGroupToggle
                      field={field.prop('isVenueSmsMarketingPolicyDefaultOnForRequest')}
                      data-test="isVenueSmsMarketingPolicyDefaultOnForRequest"
                      choices={[
                        { label: formatMessage(searchAvailability.checkedByDefault), value: true },
                        { label: formatMessage(searchAvailability.uncheckedByDefault), value: false },
                      ]}
                    />
                  </Box>
                )}
              </Box>
            </HStack>
          )}
          <HStack>
            <Box>
              <Checkbox field={field.prop('venueSpecificMarketingOnForRequest')} data-test="venueSpecificMarketingOnForRequest">
                <Text fontWeight="bold">{formatMessage(searchAvailability.displayVenueOptIn)}</Text>
              </Checkbox>
              {venueSpecificMarketingOnForRequest && (
                <Box pt="s" pl="lm">
                  <RadioGroupToggle
                    field={field.prop('isVenueSpecificMarketingPolicyDefaultOnForRequest')}
                    data-test="isVenueSpecificMarketingPolicyDefaultOnForRequest"
                    choices={[
                      { label: formatMessage(searchAvailability.checkedByDefault), value: true },
                      { label: formatMessage(searchAvailability.uncheckedByDefault), value: false },
                    ]}
                  />
                </Box>
              )}
            </Box>
          </HStack>
          <HStack>
            <Box>
              <Checkbox field={field.prop('venueGroupMarketingOnForRequest')} data-test="venueGroupMarketingOnForRequest">
                <Text fontWeight="bold">{formatMessage(searchAvailability.displayGroupOptIn)}</Text>
              </Checkbox>
              {venueGroupMarketingOnForRequest && (
                <Box pt="s" pl="lm">
                  <RadioGroupToggle
                    field={field.prop('isVenueGroupMarketingPolicyDefaultOnForRequest')}
                    data-test="isVenueGroupMarketingPolicyDefaultOnForRequest"
                    choices={[
                      { label: formatMessage(searchAvailability.checkedByDefault), value: true },
                      { label: formatMessage(searchAvailability.uncheckedByDefault), value: false },
                    ]}
                  />
                </Box>
              )}
            </Box>
          </HStack>
          <HStack>
            <Box>
              <Checkbox field={field.prop('tailoredCommunicationOnForRequest')} data-test="tailoredCommunicationOnForRequest">
                <Text fontWeight="bold">{formatMessage(searchAvailability.displayTailoredCommunication)}</Text>
              </Checkbox>
              {tailoredCommunicationOnForRequest && (
                <Box pt="s" pl="lm">
                  <RadioGroupToggle
                    field={field.prop('isTailoredCommunicationPolicyDefaultOnForRequest')}
                    data-test="isTailoredCommunicationPolicyDefaultOnForRequest"
                    choices={[
                      { label: formatMessage(searchAvailability.checkedByDefault), value: true },
                      { label: formatMessage(searchAvailability.uncheckedByDefault), value: false },
                    ]}
                  />
                </Box>
              )}
            </Box>
          </HStack>
          {reservationHoldEnabled && (
            <Label
              primary={<Text textStyle="body1Bold">{formatMessage(generalMessages.brandSettingsReservationHoldTimeMinsLabel)}</Text>}
              secondary={formatMessage(generalMessages.brandSettingReservationHoldTimeMinsDescription, { br: <br /> })}
            >
              <Box width="22%">
                <FormNumberInput field={field.prop('reservationHoldTimeMins')} greaterThanZero fullWidth data-test="sr-hold-time-mins" />
              </Box>
            </Label>
          )}
          <VStack>
            <Box pt="xs" pb="xs">
              <Text fontWeight="bold">{formatMessage(searchAvailability.formatOfRequestingTIme)}</Text>
            </Box>
            <Box pt="s">
              <RadioGroup
                field={field.prop('formatOfRequestingTime')}
                choices={[
                  {
                    label: (
                      <VStack spacing="xs">
                        <Text>{formatMessage(searchAvailability.singleTimeFrame)}</Text>
                        <SecondaryText>{formatMessage(searchAvailability.singleTimeFrameEx)}</SecondaryText>
                      </VStack>
                    ),
                    value: 'single',
                  },
                  {
                    label: (
                      <VStack spacing="xs">
                        <Text>{formatMessage(searchAvailability.timeRange)}</Text>
                        <SecondaryText>{formatMessage(searchAvailability.timeRangeEx)}</SecondaryText>
                      </VStack>
                    ),
                    value: 'time_range',
                  },
                ]}
              />
            </Box>
          </VStack>
        </VStack>
      </BaseSection>
      <BaseSection title={formatMessage(searchAvailability.availabilityConfigurations)} padding="xl">
        <VStack spacing="l" mt="l" pl="xl" mb="xxl">
          <HStack>
            <Checkbox field={field.prop('isFeesInPriceDisplayed')}>
              <VStack>
                <Box pt="xs" pb="xs">
                  <Text fontWeight="bold">{formatMessage(searchAvailability.displayAdditionalFeesInPrice)}</Text>
                </Box>
                <SecondaryText>{formatMessage(searchAvailability.displayAdditionalFeesInPriceDescr)}</SecondaryText>
              </VStack>
            </Checkbox>
          </HStack>
        </VStack>
      </BaseSection>
      <BaseSection title={formatMessage(searchAvailability.durationPickerConfigurations)} padding="xl">
        <VStack pl="xl" pr="xl" spacing="l" mt="l" mb="xxl" width="100%" ref={durationPickerRef}>
          <SecondaryText>
            {formatMessage(searchAvailability.durationPickerConfigurationsDescription, {
              link: (
                <Link data-test="res-widget-duration-picker-help-page-link" isExternal to={DURATION_PICKER_HELP_PAGE_LINK}>
                  {formatMessage(searchAvailability.durationPickerConfigurationsDescriptionHelpPageLink)}
                </Link>
              ),
            })}
          </SecondaryText>
          <VStack width="100%" maxWidth="50%" spacing="lm">
            <FormSelectRangePicker
              field={field.prop('duration')}
              data-test="sr-duration-range"
              minSelectProps={{ options: formattedDurationOptions, label: formatMessage(searchAvailability.durationPickerDurationMin) }}
              maxSelectProps={{ options: formattedDurationOptions, label: formatMessage(searchAvailability.durationPickerDurationMax) }}
            />
            <Label primary={formatMessage(searchAvailability.durationPickerDurationDefault)}>
              <FormSelect
                field={field.prop('defaultSearchDuration')}
                options={formattedDurationOptions}
                searchable={false}
                data-test="sr-duration-default"
              />
            </Label>
            <Label primary={formatMessage(searchAvailability.durationPickerDurationInterval)}>
              <FormSelect
                field={field.prop('searchDurationInterval')}
                options={formattedDurationIntervalOptions}
                searchable={false}
                data-test="sr-duration-interval"
                hideErrorMessage
              />
            </Label>
          </VStack>
          <VStack spacing="lm">
            <Checkbox field={field.prop('durationPickerDefaultEnabled')}>
              <Box pt="xs" pb="xs">
                <Text fontWeight="bold">{formatMessage(searchAvailability.durationPickerDefaultEnabledLabel)}</Text>
              </Box>
            </Checkbox>
            {durationPickerDefaultEnabled && (
              <SettingsSection
                collapsible
                defaultCollapsed
                size="s"
                data-test="sr-duration-picker-advances-settings"
                title={formatMessage(searchAvailability.durationPickerAdvancedSettings)}
              >
                <Box pt="lm">
                  <Label
                    primary={<Text textStyle="body1Bold">{formatMessage(searchAvailability.durationPickerSwitchedOffPrimaryLabel)}</Text>}
                    secondary={formatMessage(searchAvailability.durationPickerSwitchedOffSecondaryLabel)}
                  >
                    <Box pt="xs">
                      <CopyLinkButton
                        text={formatMessage(searchAvailability.durationPickerAdvancedSettingsCopyWidgetLinkLabel)}
                        link={newResWidgetUrlWithoutDurationPicker}
                        noPadding
                        data-test="sr-copy-widget-link-duration-picker-off"
                      />
                    </Box>
                  </Label>
                </Box>
              </SettingsSection>
            )}
          </VStack>
        </VStack>
      </BaseSection>
    </VStack>
  )
}
