import { useRef } from 'react'
import { type POSIUnmappedTablesReason, POSIUnmappedTablesReasonCodeEnum } from '@sevenrooms/core/domain'
import { useFormContext } from '@sevenrooms/core/form'
import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { Button, Checkbox } from '@sevenrooms/core/ui-kit/form'
import { Box, Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { UnmappedTableItemCodesDisplay } from './UnmappedTableItemCodesDisplay'
import { messages } from './UnmappedTablesStep.locales'
import type { TableMappingWizardFormData, TableMappingWizardFormField } from '../TableMappingWizardForm.zod'

const UNMAPPED_TABLES_REASON_SORT_ORDERS = {
  [POSIUnmappedTablesReasonCodeEnum.MISSING_POS_TABLES]: 0,
  [POSIUnmappedTablesReasonCodeEnum.MISSING_SEVENROOMS_TABLES]: 1,
  [POSIUnmappedTablesReasonCodeEnum.POS_TABLES_FOR_PRIVATE_EVENTS]: 2,
  [POSIUnmappedTablesReasonCodeEnum.INACCURATE_UNUSED_TABLES]: 3,
  [POSIUnmappedTablesReasonCodeEnum.OTHER]: 4,
}

export interface UnmappedTablesStepProps {
  field: TableMappingWizardFormField
  onClose: () => void
  onCancel: () => void
  onSubmit: () => void
  unmapped7RTableItemCodes: string[]
  unmappedPOSTableItemCodes: string[]
  unmappedTablesReasons: POSIUnmappedTablesReason[]
  isSaving: boolean
}

export function UnmappedTablesStep({
  field,
  onClose,
  onCancel,
  onSubmit,
  unmapped7RTableItemCodes,
  unmappedPOSTableItemCodes,
  unmappedTablesReasons,
  isSaving,
}: UnmappedTablesStepProps) {
  const { formatMessage } = useLocales()
  const { getValues, formState, trigger } = useFormContext<TableMappingWizardFormData>()
  const { errors } = formState

  const modalBodyRef = useRef<HTMLDivElement | null>(null)

  const handleSubmit = () => {
    trigger('unmappedTablesReasons')
    const formValues = getValues()
    if (formValues.unmappedTablesReasons.some(reason => reason.isSelected)) {
      onSubmit()
    } else if (modalBodyRef.current) {
      // If there is an error, scroll to the bottom of the modal
      modalBodyRef.current.scrollTop = modalBodyRef.current.scrollHeight
    }
  }

  return (
    <Modal ariaLabel="Modal">
      <ModalHeader onClose={onClose} />
      <ModalBody ref={modalBodyRef}>
        <VStack spacing="m">
          <Text textAlign="center" fontSize="xl" fontWeight="bold">
            {formatMessage(messages.title)}
          </Text>
          <Text textAlign="center">{formatMessage(messages.body)}</Text>
          <VStack borderColor="dividerLines" borderRadius="s" spacing="m" p="m">
            <UnmappedTableItemCodesDisplay title={formatMessage(messages.sevenrooms)} unmappedTableItemCodes={unmapped7RTableItemCodes} />
            <UnmappedTableItemCodesDisplay title={formatMessage(messages.pos)} unmappedTableItemCodes={unmappedPOSTableItemCodes} />
            <VStack spacing="s">
              <Text fontWeight="bold">{formatMessage(messages.reasonTitle)}</Text>
              {unmappedTablesReasons
                .filter(({ code }) => !!messages[code])
                .sort((a, b) => UNMAPPED_TABLES_REASON_SORT_ORDERS[a.code] - UNMAPPED_TABLES_REASON_SORT_ORDERS[b.code])
                .map(({ id, code }, unmappedTableReasonIndex) => (
                  <Checkbox key={`reason-${id}`} field={field.prop(`unmappedTablesReasons.${unmappedTableReasonIndex}.isSelected`)}>
                    {formatMessage(messages[code])}
                  </Checkbox>
                ))}
            </VStack>
            {errors.unmappedTablesReasons ? (
              <Text fontStyle="italic" color="error">
                {formatMessage(messages.pleaseSelectAtLeastOneOption)}
              </Text>
            ) : (
              /**
               * This box is here so that the height of the modalBody is constant regardless
               * of whether the error is showing or not for scrolling purposes
               */
              <Box height="18px" />
            )}
          </VStack>
        </VStack>
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button onClick={onCancel} variant="secondary" data-test="cancel-button" disabled={isSaving}>
            {formatMessage(messages.back)}
          </Button>
          <Button variant="primary" onClick={handleSubmit} data-test="save-button" type="submit" disabled={isSaving}>
            {formatMessage(commonMessages.save)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
