export enum AccessRuleView {
  LIST = 'list',
  CALENDAR = 'calendar',
}

export enum AccessRuleRange {
  DAY = 'day',
  OVERVIEW = 'overview',
  WEEK = 'week',
}

export enum Density {
  COMPACT = 'compact',
  EXPANDED = 'expanded',
}
