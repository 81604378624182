import { useLocales } from '@sevenrooms/core/locales'
import { VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import { messages } from './TableMappingStepInfoCard.locales'

export function TableMappingStepInfoCard() {
  const { formatMessage } = useLocales()
  return (
    <VStack width="199px" spacing="m">
      <Text fontSize="xl" fontWeight="bold">
        {formatMessage(messages.title)}
      </Text>
      <Text>{formatMessage(messages.body1)}</Text>
      <Text>{formatMessage(messages.body2)}</Text>
    </VStack>
  )
}
