import type { Shift } from '@sevenrooms/core/domain'
import { commonMessages, Locale, useLocales } from '@sevenrooms/core/locales'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { VStack, Modal, ModalActions, ModalBody, ModalFooter, ModalHeader, ModalTitle } from '@sevenrooms/core/ui-kit/layout'
import { List, Status, Text } from '@sevenrooms/core/ui-kit/typography'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { ShriftReportingPeriodSettingsMessages } from './ShiftReportingPeriods.locales'

export interface UnlinkAccessRuleModalProps {
  onDiscard: () => void
  onSubmit: () => void
  shiftReportingPeriodName: string
  associatedShifts: Shift[]
}
export function DeleteShiftPeriod({ onDiscard, onSubmit, shiftReportingPeriodName, associatedShifts }: UnlinkAccessRuleModalProps) {
  const { formatMessage } = useLocales()
  const { venueLocale } = useAppContext()

  return (
    <Modal ariaLabel={formatMessage(commonMessages.warning)}>
      <ModalHeader onClose={() => onDiscard()}>
        <Status kind="error">{formatMessage(commonMessages.warning)}</Status>
      </ModalHeader>
      <ModalBody>
        <ModalTitle
          title={formatMessage(ShriftReportingPeriodSettingsMessages.deleteTitle, { name: shiftReportingPeriodName })}
          subTitle={
            associatedShifts.length > 0 && (
              <VStack spacing="xs">
                <Text>{formatMessage(ShriftReportingPeriodSettingsMessages.deleteDescription)}</Text>
                <List variant="unordered" spacing="xs">
                  {associatedShifts
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(shift => (
                      <Text type="li" textStyle="body1" key={shift.id}>
                        {shift.name} - {shift.startDate?.formatNYearSMonthNDay(Locale.getLocale(venueLocale))}
                      </Text>
                    ))}
                </List>
              </VStack>
            )
          }
        />
      </ModalBody>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={onDiscard} data-test="sr-experience-unlink-access-rule-cancel">
            {formatMessage(commonMessages.cancel)}
          </Button>
          <Button variant="primary-warning" onClick={onSubmit} data-test="sr-experience-unlink-access-rule">
            {formatMessage(commonMessages.delete)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
