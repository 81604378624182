import React from 'react'
import { DateOnly } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import type { SxProps } from '@sevenrooms/react-components/hooks/useTheme'

interface GridCellProps {
  gridRow?: string
  gridColumn?: string
  isPast?: boolean
  showBorderRight?: boolean
  showBorderBottom?: boolean
  sx?: SxProps
}

function GridCell({
  gridRow,
  gridColumn,
  isPast = false,
  showBorderRight = true,
  showBorderBottom = true,
  sx = {},
  children,
}: React.PropsWithChildren<GridCellProps>) {
  return (
    <Box
      sx={{
        gridRow,
        gridColumn,
        zIndex: 1,
        borderWidth: '1px',
        borderRightStyle: showBorderRight ? 'solid' : 'none',
        borderBottomStyle: showBorderBottom ? 'solid' : 'none',
        borderColor: theme => theme.palette.grey[200],
        backgroundColor: theme => (isPast ? theme.palette.grey.A100 : theme.palette.common.white),
        position: 'relative',
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}

function DayHeaderCell({
  date,
  isLast,
  onClick,
  isSingleDay,
}: {
  date: DateOnly
  isLast: boolean
  onClick: Function
  isSingleDay: boolean
}) {
  const weekdayString = date.toJsDate().toLocaleDateString([], { weekday: 'short' }).toUpperCase()

  const today = DateOnly.fromDate(new Date())
  const isToday = date.isEqualTo(today)
  const isPast = date.isLessThan(today)

  return (
    <GridCell showBorderRight={!isLast} sx={{ position: 'sticky', top: 0, zIndex: 10001 }}>
      <Typography
        variant="h2"
        fontSize={11}
        fontWeight={500}
        textAlign="center"
        lineHeight="100%"
        sx={{ color: theme => (isPast ? theme.palette.grey.A400 : theme.palette.text.secondary) }}
      >
        <Stack
          onClick={() => onClick(date)}
          spacing={0}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
        >
          <Box minWidth="21px" marginBottom="4px">
            {weekdayString}
          </Box>
          <Typography
            fontSize={14}
            lineHeight="21px"
            sx={{
              borderRadius: '50%',
              minWidth: '21px',
              minHeight: '21px',
              ...(!isSingleDay ? { cursor: 'pointer' } : {}),
              ...(isToday
                ? {
                    backgroundColor: theme => theme.palette.primary.main,
                    color: theme => theme.palette.common.white,
                    ...(!isSingleDay
                      ? {
                          '&:hover': {
                            backgroundColor: '#24567A',
                          },
                        }
                      : {}),
                  }
                : {
                    ...(!isSingleDay
                      ? {
                          '&:hover': {
                            backgroundColor: 'rgba(52, 123, 175, 0.12)',
                          },
                        }
                      : {}),
                  }),
            }}
          >
            {date.getInfo().day}
          </Typography>
        </Stack>
      </Typography>
    </GridCell>
  )
}

function HourHeaderCell({ gridRow, hour, isLast }: { gridRow: string; hour: number; isLast: boolean }) {
  const hourString = new Date(new Date().setHours(hour, 0, 0)).toLocaleTimeString([], {
    hour: 'numeric',
    minute: 'numeric',
  })

  return (
    <GridCell showBorderBottom={!isLast} gridRow={gridRow} gridColumn="hour-header-column" sx={{ backgroundColor: undefined }}>
      {!isLast && (
        <Box
          sx={{
            position: 'absolute',
            bottom: '-0.75rem',
            lineHeight: '1.5rem',
            right: '4px',
            width: '100%',
            textAlign: 'right',
            backgroundColor: theme => theme.palette.common.white,
            color: theme => theme.palette.text.secondary,
            paddingRight: 1,
          }}
        >
          <Box fontSize={14}>{hourString}</Box>
        </Box>
      )}
    </GridCell>
  )
}

interface MultiDayScheduleGridProps {
  startHour: number
  hourSpan: number
  dates: DateOnly[]
  onClickDayHeader: (date: DateOnly) => void
  rowMinHeight: number
  width?: string
  minWidth?: number
}

export function MultiDayScheduleGrid({
  startHour,
  hourSpan,
  dates,
  onClickDayHeader,
  rowMinHeight,
  width,
  minWidth,
  children,
}: React.PropsWithChildren<MultiDayScheduleGridProps>) {
  const numRows = hourSpan + 2

  const today = DateOnly.fromDate(new Date())

  return (
    <Box data-test="multi-day-schedule-grid-container" height="100%" width="100%" sx={{ overflowY: 'auto', overflowX: 'auto' }}>
      <Box
        data-test="multi-day-schedule-grid"
        sx={{
          display: 'grid',
          gridTemplateRows: `[header-row] 45px repeat(${numRows * 4}, [row-start] minmax(${rowMinHeight / 4}px, auto) [row-end])`,
          gridTemplateColumns: `[hour-header-column] 75px repeat(${dates.length}, [col-start] auto [col-end])`,
          isolation: 'isolate',
        }}
        height="100%"
        width={width}
        minWidth={minWidth}
      >
        <GridCell sx={{ position: 'sticky', top: 0, zIndex: 10001 }} />
        {dates.map((date, i, { length }) => (
          <DayHeaderCell
            key={`day-header-${date.toIso()}`}
            onClick={onClickDayHeader}
            date={date}
            isLast={i === length - 1}
            isSingleDay={dates.length === 1}
          />
        ))}
        {Array.from({ length: numRows }, (_, row: number) => {
          const gridRow = `${row * 4 + 1} row-start / span 4`

          return (
            <React.Fragment key={`row-fragment-${row}`}>
              <HourHeaderCell key={`hour-header-${row}`} gridRow={gridRow} hour={(startHour + row) % 24} isLast={row === numRows - 1} />
              {dates.map((date, i, { length }) => (
                <GridCell
                  key={`grid-cell-${date.toIso()}`}
                  isPast={date.isLessThan(today)}
                  showBorderRight={i < length - 1}
                  gridRow={gridRow}
                  gridColumn={`${i + 1} col-start`}
                />
              ))}
            </React.Fragment>
          )
        })}
        {children}
      </Box>
    </Box>
  )
}
