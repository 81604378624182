import { defineMessages } from '@sevenrooms/core/locales'

export const messages = defineMessages({
  title: {
    id: 'posiSettings.TableMappingWizard.TableMappingStep.TableMappingStepInfoCard.title',
    defaultMessage: 'SevenRooms & POS Table Mapping',
  },
  body1: {
    id: 'posiSettings.TableMappingWizard.TableMappingStep.TableMappingStepInfoCard.body1',
    defaultMessage: 'The SevenRooms Matching Algorithm auto-mapped your Tables - now, simply confirm them.',
  },
  body2: {
    id: 'posiSettings.TableMappingWizard.TableMappingStep.TableMappingStepInfoCard.body2',
    defaultMessage: 'For any unmapped Tables, select a Table or choose to Ignore the match before continuing.',
  },
} as const)
