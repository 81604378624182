import { useEffect, useMemo, useState } from 'react'
import { useUpdatePOSITableMappingDataMutation } from '@sevenrooms/core/api'
import type { POSITableMappingData } from '@sevenrooms/core/domain'
import { useForm } from '@sevenrooms/core/form'
import { useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Form } from '@sevenrooms/core/ui-kit/form'
import { notify, type ModalHeaderProps } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { TableMappingStep } from './TableMappingStep'
import { IGNORE_POS_ITEM_CODE } from './TableMappingWizard.contants'
import { messages } from './TableMappingWizard.locales'
import { getTableMappingWizardFormDefaultValues, useTableMappingWizardForm } from './TableMappingWizardForm.zod'
import { UnmappedTablesStep } from './UnmappedTablesStep'
import { UnsavedChangesModal } from './UnsavedChangesModal'

export interface TableMappingWizardProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  tableMappingData: POSITableMappingData
  refetchTableMappingData: () => void
}

export function TableMappingWizard({ closeHref, tableMappingData, refetchTableMappingData }: TableMappingWizardProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()
  const { venueId } = useVenueContext()

  // State variables and setters
  const [unmappedTableItemCodes, setUnmappedTableItemCodes] = useState<{
    unmapped7RTableItemCodes: string[]
    unmappedPOSTableItemCodes: string[]
  }>({
    unmapped7RTableItemCodes: [],
    unmappedPOSTableItemCodes: [],
  })
  const [posTableItemCodesByTableItemId, setPOSTableItemCodesByTableItemId] = useState<{ [key: string]: string | null }>({})
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState<boolean>(false)
  const [showUnmappedTablesForm, setShowUnmappedTablesForm] = useState<boolean>(false)

  // Update table mapping mutation
  const [updatePOSITableMappingData, { isLoading: isSaving }] = useUpdatePOSITableMappingDataMutation()

  // Initialize the form
  const defaultValues = useMemo(() => getTableMappingWizardFormDefaultValues(tableMappingData), [tableMappingData])
  const form = useForm(useTableMappingWizardForm(), {
    defaultValues,
  })
  const { field, formState, getValues, trigger } = form
  const { isDirty } = formState

  // Trigger form validation
  useEffect(() => {
    trigger()
  }, [trigger])

  const handleSave = async ({
    posTableItemCodesByTableItemId,
    unmappedPOSTableItemCodes,
    unmappedTablesReasonIds,
  }: {
    posTableItemCodesByTableItemId: { [key: string]: string | null }
    unmappedPOSTableItemCodes: string[]
    unmappedTablesReasonIds: string[]
  }) => {
    try {
      await updatePOSITableMappingData({
        venueId,
        posTableItemCodesByTableItemId,
        unmappedPOSTableItemCodes,
        unmappedTablesReasonIds,
      })
      refetchTableMappingData()
      notify({
        content: formatMessage(messages.saveSuccess),
        type: 'success',
      })
      nav.closeSurface(closeHref)
    } catch (error) {
      notify({
        content: formatMessage(messages.saveError),
        type: 'error',
      })
    }
  }

  const handleTableMappingStepSubmit = async () => {
    const formValues = getValues()
    const unmappedPOSTableItemCodesSet = new Set<string>(tableMappingData.posItemCodes)
    const unmapped7RTableItemCodesSet = new Set<string>()
    const _posTableItemCodesByTableItemId: { [key: string]: string | null } = {}
    formValues.seatingAreas.forEach(seatingArea => {
      seatingArea.tableMappings.forEach(tableMapping => {
        if (tableMapping.posItemCode === IGNORE_POS_ITEM_CODE) {
          _posTableItemCodesByTableItemId[tableMapping.id] = null
          unmapped7RTableItemCodesSet.add(tableMapping.itemCode)
        } else {
          _posTableItemCodesByTableItemId[tableMapping.id] = tableMapping.posItemCode
          unmappedPOSTableItemCodesSet.delete(tableMapping.posItemCode)
        }
      })
    })
    setPOSTableItemCodesByTableItemId(_posTableItemCodesByTableItemId)

    if (unmapped7RTableItemCodesSet.size > 0 || unmappedPOSTableItemCodesSet.size > 0) {
      setUnmappedTableItemCodes({
        unmapped7RTableItemCodes: [...unmappedPOSTableItemCodesSet],
        unmappedPOSTableItemCodes: [...unmapped7RTableItemCodesSet],
      })
      setShowUnmappedTablesForm(true)
    } else {
      await handleSave({ posTableItemCodesByTableItemId, unmappedPOSTableItemCodes: [], unmappedTablesReasonIds: [] })
    }
  }

  const handleUnmappedTablesStepSubmit = async () => {
    const formValues = getValues()
    const unmappedTablesReasonIds = formValues.unmappedTablesReasons.filter(reason => reason.isSelected).map(reason => reason.id)
    await handleSave({
      posTableItemCodesByTableItemId,
      unmappedPOSTableItemCodes: unmappedTableItemCodes.unmappedPOSTableItemCodes,
      unmappedTablesReasonIds,
    })
  }

  const renderContent = () => {
    if (showUnsavedChangesModal) {
      return <UnsavedChangesModal closeHref={closeHref} onClose={() => setShowUnsavedChangesModal(false)} />
    } else if (showUnmappedTablesForm) {
      return (
        <UnmappedTablesStep
          field={field}
          onClose={() => setShowUnsavedChangesModal(true)}
          onCancel={() => setShowUnmappedTablesForm(false)}
          onSubmit={handleUnmappedTablesStepSubmit}
          unmapped7RTableItemCodes={unmappedTableItemCodes.unmapped7RTableItemCodes}
          unmappedPOSTableItemCodes={unmappedTableItemCodes.unmappedPOSTableItemCodes}
          unmappedTablesReasons={tableMappingData.unmappedTablesData.unmappedTablesReasons}
          isSaving={isSaving}
        />
      )
    }
    return (
      <TableMappingStep
        field={field}
        tableMappingData={tableMappingData}
        onClose={() => {
          if (isDirty) {
            setShowUnsavedChangesModal(true)
          } else {
            nav.closeSurface(closeHref)
          }
        }}
        onSubmit={handleTableMappingStepSubmit}
        isSaving={isSaving}
      />
    )
  }

  return (
    /**
     * This form's onSubmit is empty because the submission criteria are variable
     * depending on the state of the form and what step in the wizard we are in.
     * Instead, we handle saving/submission logic with functions defined above.
     */
    <Form {...form} onSubmit={() => {}} onInvalid={() => {}}>
      {renderContent()}
    </Form>
  )
}
