import React, { type Dispatch, type SetStateAction } from 'react'
import { POSITypeEnum, type POSIType, type POSILandingData } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import { Box, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Anchor } from '@sevenrooms/core/ui-kit/typography'
import { SettingsPage, SettingsPageContent, SettingsPageMeta, useVenueContext } from '@sevenrooms/mgr-core'
import { SettingsBanner } from '../../components'
import { AuthenticationStatusCard } from '../../Edit'
import { posiDocumentationLink, posiMetaDataMap } from '../../ReferenceData'
import { IntegrationStatusCard } from '../IntegrationStatusCard'
import { messages } from './IntegrationStatusPage.locales'

export interface IntegrationStatusPageData {
  lastUpdatedDateString: string
  setIsDisconnecting: Dispatch<SetStateAction<boolean>>
  locationsError?: string
  landingData: POSILandingData[]
}

export function IntegrationStatusPage({
  lastUpdatedDateString,
  setIsDisconnecting,
  locationsError,
  landingData,
}: IntegrationStatusPageData) {
  const { formatMessage } = useLocales()
  const { venue } = useVenueContext()
  const posName = venue.posIntegrationType as POSIType

  const externalSetupLink = landingData.find(ld => ld.posName === posName)?.externalSetupLink

  return (
    <SettingsPage>
      <SettingsPageMeta title={formatMessage(messages.tabTitle)} />
      <SettingsPageContent
        title={formatMessage(messages.title)}
        description={formatMessage(messages.description, {
          a: (chunks: string[]) => <Anchor href={posiDocumentationLink}>{chunks}</Anchor>,
        })}
        secondHeaderMaxWidth="1000px"
      >
        <Box width="1024px" pl="lm">
          <VStack spacing="s" flexWrap="wrap">
            {locationsError && (
              <Box width="100%">
                <SettingsBanner
                  title={formatMessage(messages.locationsErrorBannerTitle)}
                  description={formatMessage(messages.locationsErrorBannerDescription, { error: locationsError as string })}
                  type="error"
                />
              </Box>
            )}
            {posName === POSITypeEnum.SQUARE ||
            posName === POSITypeEnum.LIGHTSPEED_K ||
            posName === POSITypeEnum.SIMPHONY ||
            posName === POSITypeEnum.OMNIVORE ? (
              <IntegrationStatusCard
                isAuthenticated={!locationsError}
                lastUpdatedDateString={lastUpdatedDateString}
                setIsDisconnecting={setIsDisconnecting}
                externalSetupLink={externalSetupLink}
              />
            ) : (
              <AuthenticationStatusCard
                isAuthenticated
                logo={posiMetaDataMap[posName].logo}
                title={posiMetaDataMap[posName].title}
                docLink={posiMetaDataMap[posName].docLink}
              />
            )}
          </VStack>
        </Box>
      </SettingsPageContent>
    </SettingsPage>
  )
}
