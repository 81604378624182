import type { Shift } from '@sevenrooms/core/domain'
import { useLocales } from '@sevenrooms/core/locales'
import type { DateOnly } from '@sevenrooms/core/timepiece'
import { Box } from '@sevenrooms/react-components/components/Box'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { Tooltip } from '@sevenrooms/react-components/components/Tooltip'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { useAccessRuleSlideout } from '../../contexts/AccessRuleSlideoutProvider'
import { getShiftCategoryName } from '../../utils/accessRulesListUtils'
import { ScheduleBlock } from '../MultiDayScheduleGrid/ScheduleBlock'

const BACKGROUND_COLOR = '#D2DAE44D'

export interface ShiftBlockProps {
  shift: Shift
  date: DateOnly
  column: number
  startSortOrder: number
  endSortOrder: number
  sortOrderOffset: number
  isLeft?: boolean
  isRight?: boolean
}

export function ShiftBlock({
  shift,
  date,
  column,
  startSortOrder,
  endSortOrder,
  sortOrderOffset,
  isLeft = true,
  isRight = true,
}: ShiftBlockProps) {
  const showAccessRuleSlideout = useAccessRuleSlideout()
  const { formatMessage } = useLocales()

  const borderStyles = {
    borderTopLeftRadius: isLeft ? '3px' : 0,
    borderBottomLeftRadius: isLeft ? '3px' : 0,
    borderTopRightRadius: isRight ? '3px' : 0,
    borderBottomRightRadius: isRight ? '3px' : 0,
  }

  return (
    <ScheduleBlock
      columnStart={column}
      columnEnd={column}
      rowStart={startSortOrder - sortOrderOffset}
      rowEnd={endSortOrder - sortOrderOffset}
      zIndex={2}
    >
      <Tooltip
        title={
          <Stack direction="column">
            <Typography fontSize={14}>
              {shift.name} ({getShiftCategoryName(shift.shiftCategory, formatMessage)})
            </Typography>
            <Typography fontSize={12}>
              {shift.startTime?.formatSTime()} - {shift.endTime?.formatSTime()}
            </Typography>
          </Stack>
        }
      >
        <Box
          onClick={() =>
            showAccessRuleSlideout({ shiftCategories: shift.shiftCategory ? [shift.shiftCategory] : [], startDate: date.toJsDate() })
          }
          sx={{
            ...borderStyles,
            height: `100%`,
            width: `100%`,
            borderTopLeftRadius: 0,
            position: 'relative',
            backgroundColor: BACKGROUND_COLOR,
            cursor: 'pointer',
          }}
        />
      </Tooltip>
    </ScheduleBlock>
  )
}
