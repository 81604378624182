import { v4 as uuid } from 'uuid'
import { HStack, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Text } from '@sevenrooms/core/ui-kit/typography'

export interface UnmappedTableItemCodesDisplayProps {
  title: string
  unmappedTableItemCodes: string[]
}

export function UnmappedTableItemCodesDisplay({ title, unmappedTableItemCodes }: UnmappedTableItemCodesDisplayProps) {
  return (
    <VStack>
      <Text color="secondaryFont">{title}</Text>
      <HStack width="360px" flexWrap="wrap" columnGap="m" rowGap="m">
        {unmappedTableItemCodes.map(unmappedTableItemName => (
          <HStack
            key={uuid()}
            width="78px"
            height="42px"
            backgroundColor="infoBackground"
            borderRadius="s"
            alignItems="center"
            justifyContent="center"
          >
            <Text>{unmappedTableItemName}</Text>
          </HStack>
        ))}
      </HStack>
    </VStack>
  )
}
