import { useState, useEffect } from 'react'

/**
 * Custom debounce hook
 * @param value - The value to debounce
 * @param delay - Delay in milliseconds
 * @returns Debounced value after delay
 */
export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
