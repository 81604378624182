import type { TimeOnly, TimeInterval } from '@sevenrooms/core/timepiece'
import type { GroupRow } from './AccessRulesDataGrid'
import type { AccessListRowTime } from '../../views/AccessRulesList/rowInterfaces'
import type { GridSortModel } from '@mui/x-data-grid'

export const sortChildRows = (sm: GridSortModel, sortedRows: Record<string, GroupRow[]>) => {
  if (sm.length === 0 || !sm[0]?.field || !sm[0]?.sort) {
    return sortedRows
  }

  const { field, sort } = sm[0]
  const fieldKey = field as keyof GroupRow

  let newSortedChildRows = {}

  switch (fieldKey) {
    case 'rule':
      newSortedChildRows = sortRuleColumn(sortedRows, sort)
      break
    case 'schedule':
      newSortedChildRows = sortScheduleColumn(sortedRows, sort)
      break
    case 'time':
      newSortedChildRows = sortTimeColumn(sortedRows, sort)
      break
    case 'pax':
      newSortedChildRows = sortPaxColumn(sortedRows, sort)
      break
    case 'slotDescription':
      newSortedChildRows = sortSlotDescriptionColumn(sortedRows, sort)
      break
    default:
      throw new Error('Invalid sort field')
  }

  return newSortedChildRows
}

function sortRuleColumn(rows: { [p: string]: GroupRow[] }, sort: 'asc' | 'desc') {
  const newSortedChildRows = { ...rows }

  Object.keys(newSortedChildRows).forEach(groupId => {
    const groupRows = newSortedChildRows[groupId]
    if (groupRows) {
      newSortedChildRows[groupId] = [...groupRows].sort((a, b) => {
        const aValue = a.rule ?? ''
        const bValue = b.rule ?? ''

        if (aValue < bValue) {
          return sort === 'asc' ? -1 : 1
        }
        if (aValue > bValue) {
          return sort === 'asc' ? 1 : -1
        }
        return 0
      })
    }
  })
  return newSortedChildRows
}

function sortScheduleColumn(rows: { [p: string]: GroupRow[] }, sort: 'asc' | 'desc') {
  const newSortedChildRows = { ...rows }

  Object.keys(newSortedChildRows).forEach(groupId => {
    const groupRows = newSortedChildRows[groupId]
    if (groupRows) {
      newSortedChildRows[groupId] = [...groupRows].sort((a, b) => {
        const aValue = a.schedule
        const bValue = b.schedule

        // Step 1: Handle undefined schedules (shouldn't happen)
        if (!aValue || !bValue) {
          return sort === 'asc' ? -1 : 1
        }

        // Step 2: Reorder daysOfWeek (Sunday to Saturday)
        const reorderDays = (days: boolean[]) => [days[6], ...days.slice(0, 6)]

        const aDays = reorderDays(aValue.dayOfWeek)
        const bDays = reorderDays(bValue.dayOfWeek)

        // Step 3: Compare dayOfWeek arrays
        for (let i = 0; i < 7; i += 1) {
          if (aDays[i] !== bDays[i]) {
            if (sort === 'asc') {
              return aDays[i] ? -1 : 1
            }
            return aDays[i] ? 1 : -1
          }
        }

        // Step 4: Compare startDate
        const aStart = aValue.startDate?.toJsDate()
        const bStart = bValue.startDate?.toJsDate()

        if (aStart && bStart && aStart.getTime() !== bStart.getTime()) {
          return sort === 'asc' ? aStart.getTime() - bStart.getTime() : bStart.getTime() - aStart.getTime()
        }

        // Step 5: Compare endDate
        const aEnd = aValue.endDate?.toJsDate()
        const bEnd = bValue.endDate?.toJsDate()

        if (aEnd && bEnd) {
          return sort === 'asc' ? aEnd.getTime() - bEnd.getTime() : bEnd.getTime() - aEnd.getTime()
        } else if (aEnd === undefined && bEnd !== undefined) {
          return sort === 'asc' ? 1 : -1
        } else if (aEnd !== undefined && bEnd === undefined) {
          return sort === 'asc' ? -1 : 1
        }

        // If everything is equal
        return 0
      })
    }
  })

  return newSortedChildRows
}

function sortTimeColumn(rows: { [p: string]: GroupRow[] }, sort: 'asc' | 'desc') {
  const newSortedChildRows = { ...rows }

  Object.keys(newSortedChildRows).forEach(groupId => {
    const groupRows = newSortedChildRows[groupId]
    if (groupRows) {
      newSortedChildRows[groupId] = [...groupRows].sort((a, b) => {
        const aValue = a.time
        const bValue = b.time

        // Step 1: Handle undefined times (shouldn't happen, but safe to check)
        if (!aValue || !bValue) {
          return sort === 'asc' ? -1 : 1
        }

        // Step 2: Extract the sorted list of start times for each row
        const extractSortedStartTimesWithEnd = (timeObj: AccessListRowTime): { start: number; end?: number; isTimeSlot: boolean }[] => {
          if (timeObj.timeSlots.length > 0) {
            // Extract start times from timeSlots
            return timeObj.timeSlots
              .map((slot: TimeOnly) => {
                const { hours, minutes, seconds, milliseconds } = slot.getInfo()
                const startTime = ((hours * 60 + minutes) * 60 + seconds) * 1000 + milliseconds
                return { start: startTime, isTimeSlot: true }
              })
              .sort((a, b) => a.start - b.start)
          } else if (timeObj.timeRanges.length > 0) {
            // Extract start and end times from timeRanges
            return timeObj.timeRanges
              .map((range: TimeInterval) => {
                const startInfo = range.getStart().getInfo()
                const endInfo = range.getEnd()?.getInfo()

                const startTime = ((startInfo.hours * 60 + startInfo.minutes) * 60 + startInfo.seconds) * 1000 + startInfo.milliseconds
                const endTime = endInfo
                  ? ((endInfo.hours * 60 + endInfo.minutes) * 60 + endInfo.seconds) * 1000 + endInfo.milliseconds
                  : Number.MAX_SAFE_INTEGER

                return { start: startTime, end: endTime, isTimeSlot: false }
              })
              .sort((a, b) => a.start - b.start)
          }
          return []
        }

        const aTimes = extractSortedStartTimesWithEnd(aValue)
        const bTimes = extractSortedStartTimesWithEnd(bValue)

        // Step 3: Compare start times and prioritize timeSlots if start times are equal
        const maxLength = Math.max(aTimes.length, bTimes.length)

        for (let i = 0; i < maxLength; i += 1) {
          const aStart = aTimes[i]?.start ?? Number.MAX_SAFE_INTEGER
          const bStart = bTimes[i]?.start ?? Number.MAX_SAFE_INTEGER

          // If start times are equal, prioritize timeSlots
          if (aStart === bStart) {
            const aIsTimeSlot = aTimes[i]?.isTimeSlot ?? false
            const bIsTimeSlot = bTimes[i]?.isTimeSlot ?? false

            if (aIsTimeSlot !== bIsTimeSlot) {
              if (sort === 'asc') {
                return aIsTimeSlot ? -1 : 1 // TimeSlot comes first in ascending order
              }
              return aIsTimeSlot ? 1 : -1 // TimeRange comes first in descending order
            }

            // If both are of the same type, compare end times if they exist
            const aEnd = aTimes[i]?.end ?? Number.MAX_SAFE_INTEGER
            const bEnd = bTimes[i]?.end ?? Number.MAX_SAFE_INTEGER

            if (aEnd !== bEnd) {
              return sort === 'asc' ? aEnd - bEnd : bEnd - aEnd
            }
          } else {
            // If start times are different, compare them normally
            return sort === 'asc' ? aStart - bStart : bStart - aStart
          }
        }

        // Step 4: If all times are the same, rows are equal
        return 0
      })
    }
  })

  return newSortedChildRows
}

function sortPaxColumn(rows: { [p: string]: GroupRow[] }, sort: 'asc' | 'desc') {
  const newSortedChildRows = { ...rows }

  Object.keys(newSortedChildRows).forEach(groupId => {
    const groupRows = newSortedChildRows[groupId]
    if (groupRows) {
      newSortedChildRows[groupId] = [...groupRows].sort((a, b) => {
        const aValue = a.pax
        const bValue = b.pax

        // Step 1: Extract min and max values, default to 0 if undefined
        const aMin = aValue?.min ?? 0
        const aMax = aValue?.max ?? 0
        const bMin = bValue?.min ?? 0
        const bMax = bValue?.max ?? 0

        // Step 2: Compare min values first
        if (aMin !== bMin) {
          return sort === 'asc' ? aMin - bMin : bMin - aMin
        }

        // Step 3: If min values are equal, compare max values
        if (aMax !== bMax) {
          return sort === 'asc' ? aMax - bMax : bMax - aMax
        }

        // Step 4: If both min and max values are equal, the rows are the same
        return 0
      })
    }
  })

  return newSortedChildRows
}

function sortSlotDescriptionColumn(rows: { [p: string]: GroupRow[] }, sort: 'asc' | 'desc') {
  const newSortedChildRows = { ...rows }

  Object.keys(newSortedChildRows).forEach(groupId => {
    const groupRows = newSortedChildRows[groupId]
    if (groupRows) {
      newSortedChildRows[groupId] = [...groupRows].sort((a, b) => {
        const aValue = a.slotDescription ?? ''
        const bValue = b.slotDescription ?? ''

        if (aValue < bValue) {
          return sort === 'asc' ? -1 : 1
        }
        if (aValue > bValue) {
          return sort === 'asc' ? 1 : -1
        }
        return 0
      })
    }
  })
  return newSortedChildRows
}
