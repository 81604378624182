import moment from 'moment-timezone'
import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useLocales } from '@sevenrooms/core/locales'
import { Icon } from '@sevenrooms/core/ui-kit/icons'
import {
  Box,
  Flex,
  Loader,
  Pagination,
  FooterCell,
  LeftBorder,
  PaginationSpace,
  PaginationWrapper,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableHeader,
} from '@sevenrooms/core/ui-kit/layout'
import { StatusLabel } from '@sevenrooms/core/ui-kit/typography'
// eslint-disable-next-line import/no-relative-packages
import { showErrorMessage } from '../../../../../application/site/static/app/manager/lib/actions/GlobalActions'
import { fetchJobs } from '../../JobServices'
import { jobsTableHeaders, jobsTableContent, jobStatus } from '../../locales'
import type { Job } from '../../types/Jobs.types'

export interface JobsTableProps {
  venueId: string
  completed: boolean
  currentPage: number
  rowsPerPage: number
  setCurrentPage: (currentPage: number) => void
  setRowsPerPage: (rowsPerPage: number) => void
}

const jobStatusToStatusLabelMap = {
  IN_PROGRESS: 'success',
  PENDING: 'success',
  FAILED: 'warning',
  COMPLETED: 'inactive',
} as const

export function JobsTable({ venueId, completed, currentPage, setCurrentPage, rowsPerPage, setRowsPerPage }: JobsTableProps) {
  const { formatMessage } = useLocales()
  const [jobs, setJobs] = useState([] as Job[])
  const [jobCount, setJobCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()

  const tryFetchJobs = async () => {
    setIsLoading(true)
    await fetchJobs(venueId, {
      completed,
      current_page: currentPage,
      rows_per_page: rowsPerPage,
    })
      .then(data => {
        if (data.longRunningStateJobs.length === 0 && currentPage !== 1) {
          setCurrentPage(1)
        } else {
          setJobs(data.longRunningStateJobs)
          setJobCount(data.longRunningStateJobsCount)
          setIsLoading(false)
        }
      })
      .catch(_error => {
        dispatch(showErrorMessage('Unable to fetch jobs, please try again later or contact customer support.'))
        setIsLoading(false)
      })
  }

  useEffect(() => {
    tryFetchJobs()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, rowsPerPage, completed])

  const getTimeInProcessing = (job: Job) => {
    let differenceInMinutes
    let differenceInHours
    if (job.startedAt === null && job.finishedAt === null) {
      return formatMessage(jobsTableContent.notAvailable)
    } else if (job.finishedAt) {
      differenceInHours = moment(job.finishedAt).diff(moment(job.created), 'hours')
      differenceInMinutes = moment(job.finishedAt).diff(moment(job.created), 'minutes')
    } else {
      differenceInHours = moment().diff(moment(job.created), 'hours')
      differenceInMinutes = moment().diff(moment(job.created), 'minutes')
    }
    return formatMessage(jobsTableContent.hours, { value: `${differenceInHours}${differenceInMinutes >= 30 ? '.5' : ''}` })
  }

  const getFormattedStatus = (status: string) => {
    if (status === 'IN_PROGRESS') {
      return formatMessage(jobStatus.inProgress)
    }
    if (status === 'PENDING') {
      return formatMessage(jobStatus.pending)
    }
    if (status === 'COMPLETED') {
      return formatMessage(jobStatus.completed)
    }
    return formatMessage(jobStatus.failed)
  }

  const renderJobsTable = () => (
    <Box height="480px">
      <Box width="100%" height="100%" overflow="auto">
        <Flex width="100%" height="100%">
          <Table>
            <TableHeader>
              <TableRow>
                <TableCell key={jobsTableHeaders.jobName.id} isHeader isInFirstRow>
                  {formatMessage(jobsTableHeaders.jobName)}
                </TableCell>
                <TableCell key={jobsTableHeaders.JobType.id} isHeader isInFirstRow>
                  {formatMessage(jobsTableHeaders.JobType)}
                </TableCell>
                <TableCell key={jobsTableHeaders.jobStatus.id} isHeader isInFirstRow>
                  {formatMessage(jobsTableHeaders.jobStatus)}
                </TableCell>
                <TableCell key={jobsTableHeaders.timeInProcessing.id} isHeader isInFirstRow>
                  {formatMessage(jobsTableHeaders.timeInProcessing)}
                </TableCell>
                <TableCell key={jobsTableHeaders.failReason.id} isHeader isInFirstRow>
                  {formatMessage(jobsTableHeaders.failReason)}
                </TableCell>
                <TableCell key="attachment" isHeader isInFirstRow />
              </TableRow>
            </TableHeader>
            <TableBody>
              {jobs.map((job, rowIdx, rowArr) => {
                const timeInProcessing = getTimeInProcessing(job)
                const failReason = job.failReason === null ? formatMessage(jobsTableContent.notAvailable) : job.failReason
                const isLastRow = rowIdx === rowArr.length - 1

                return (
                  <TableRow key={job.id}>
                    <TableCell noBorderBottom={isLastRow}>
                      {' '}
                      {`${job.jobSubtype.toLowerCase()} ${job.jobType.toLowerCase()} [${job.created.substring(0, 10)}]`}{' '}
                    </TableCell>
                    <TableCell noBorderBottom={isLastRow}> {job.jobType} </TableCell>
                    <TableCell noBorderBottom={isLastRow}>
                      <StatusLabel variant={jobStatusToStatusLabelMap[job.status]}> {getFormattedStatus(job.status)} </StatusLabel>
                    </TableCell>
                    <TableCell noBorderBottom={isLastRow}> {timeInProcessing} </TableCell>
                    <TableCell noBorderBottom={isLastRow}> {failReason} </TableCell>
                    <TableCell noBorderBottom={isLastRow}>
                      {job.assetLink !== null && (
                        <a href={job.assetLink} rel="noreferrer" target="_blank">
                          <Icon name="VMSWeb-attach-file" size="lg" color="primaryIcons" />
                        </a>
                      )}
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
            <TableFooter>
              <TableRow>
                <FooterCell colSpan={6}>
                  <Flex justifyContent="flex-end">
                    <PaginationWrapper>
                      <LeftBorder />
                      <PaginationSpace>
                        <Pagination
                          rowsPerPage={rowsPerPage}
                          canNextPage={currentPage * rowsPerPage <= jobCount}
                          canPreviousPage={currentPage !== 1}
                          currentPage={currentPage}
                          totalItemsCount={jobCount}
                          onPerPageChange={perPage => {
                            setRowsPerPage(perPage)
                          }}
                          goToPage={page => {
                            switch (page) {
                              case 'next':
                                setCurrentPage(currentPage + 1)
                                break
                              case 'prev':
                                setCurrentPage(currentPage - 1)
                                break
                              case 'first':
                                setCurrentPage(1)
                                break
                              case 'last':
                                setCurrentPage(Math.ceil(jobCount / rowsPerPage))
                                break
                              default:
                                setCurrentPage(page)
                                break
                            }
                          }}
                        />
                      </PaginationSpace>
                    </PaginationWrapper>
                  </Flex>
                </FooterCell>
              </TableRow>
            </TableFooter>
          </Table>
        </Flex>
      </Box>
    </Box>
  )

  const renderLoader = () => <Loader size="m"> Loading Table Exports... </Loader>

  return <>{isLoading ? renderLoader() : renderJobsTable()}</>
}
