import { useMemo, useState } from 'react'
import type { SelectOption } from '@sevenrooms/core/domain'
import { type Field, useFormContext, type Path } from '@sevenrooms/core/form'
import { FormSelect } from '@sevenrooms/core/ui-kit/form'
import { Icon, Icons } from '@sevenrooms/core/ui-kit/icons'
import { Box, HStack } from '@sevenrooms/core/ui-kit/layout'
import type { ThemeColor } from '@sevenrooms/core/ui-kit/theme'
import { Text } from '@sevenrooms/core/ui-kit/typography'
import type { TableMappingWizardFormData } from '../TableMappingWizardForm.zod'

export interface TableMappingStepRowPros {
  isRowError: boolean
  itemCode: string
  posItemCodeOptions: SelectOption[]
  field: Field<string>
  fieldPath: Path<TableMappingWizardFormData>
  handleSelectPOSItemCode: (posItemCode: string, currentPath: Path<TableMappingWizardFormData>) => void
  handleUnlinkPOSItemCode: (posItemCode: string, currentPath: Path<TableMappingWizardFormData>) => void
}

export function TableMappingStepRow({
  isRowError,
  itemCode,
  posItemCodeOptions,
  field,
  fieldPath,
  handleSelectPOSItemCode,
  handleUnlinkPOSItemCode,
}: TableMappingStepRowPros) {
  const [isHovered, setIsHovered] = useState<boolean>(false)
  const { getValues } = useFormContext()

  const mappingColors: { background: ThemeColor; foreground: ThemeColor } = useMemo(() => {
    let background: ThemeColor
    let foreground: ThemeColor
    if (isRowError) {
      background = 'warningBackground'
      foreground = 'warning'
    } else if (isHovered) {
      background = 'infoBackground'
      foreground = 'info'
    } else {
      background = 'primaryBackground'
      foreground = 'infoBackground'
    }
    return { background, foreground }
  }, [isRowError, isHovered])

  return (
    <Box
      onMouseOver={() => {
        setIsHovered(true)
      }}
      onMouseOut={() => {
        setIsHovered(false)
      }}
    >
      <HStack width="100%" p="s" borderRadius="s" spacing="s" alignItems="center" backgroundColor={mappingColors.background}>
        {isRowError ? (
          <Icon name={Icons['VMSWeb-warning']} color="warning" />
        ) : (
          <Icon name={Icons['VMSWeb-checkbox-marked-circle']} color="success" />
        )}
        <HStack width="110px" height="42px" backgroundColor={mappingColors.foreground} borderRadius="s" alignItems="center" pl="m">
          <Text fontWeight={isRowError ? 'bold' : 'normal'}>{itemCode}</Text>
        </HStack>
        {isRowError || !isHovered ? (
          <Icon name="VMSWeb-arrow-up" rotate={90} />
        ) : (
          <Box
            width="16px"
            onClick={() => {
              if (!isRowError) {
                handleUnlinkPOSItemCode(getValues(fieldPath), fieldPath)
              }
            }}
          >
            <Icon name="VMSWeb-unlink" />
          </Box>
        )}

        <Box width="110px">
          <FormSelect
            options={posItemCodeOptions}
            field={field}
            searchable={false}
            disabled={false}
            onChange={e => {
              handleSelectPOSItemCode(e, fieldPath)
            }}
            hideErrorMessage
            placeholder=" "
          />
        </Box>
      </HStack>
    </Box>
  )
}
