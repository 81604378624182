import RoomServiceOutlined from '@mui/icons-material/RoomServiceOutlined'
import { useLocales } from '@sevenrooms/core/locales'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { Button } from '@sevenrooms/react-components/components/Button'
import { Stack } from '@sevenrooms/react-components/components/Stack'
import { accessRulesSettingsMessages } from '../../locales'
import { CreateAccessRuleButton } from './CreateAccessRuleButton'
import { OverflowMenu } from './OverflowMenu'

export function AccessRulesActionButtons() {
  const { formatMessage } = useLocales()
  const { venueUrlKey } = useAppContext()

  return (
    <Stack id="access-rules-header-action-buttons" direction="row" spacing={2}>
      <Button variant="text" data-test="btn-need-help" startIcon={<RoomServiceOutlined />}>
        {formatMessage(accessRulesSettingsMessages.needHelp)}
      </Button>
      <Button
        variant="outlined"
        href={`${window.location.origin}/reservations/${venueUrlKey}`}
        target="_blank"
        id="btn-view-reservation-widget"
        data-test="btn-view-reservation-widget"
      >
        {formatMessage(accessRulesSettingsMessages.viewReservationWidget)}
      </Button>
      <CreateAccessRuleButton />
      <OverflowMenu />
    </Stack>
  )
}
