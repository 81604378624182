import { commonMessages, useLocales } from '@sevenrooms/core/locales'
import { useNavigation } from '@sevenrooms/core/navigation'
import { Button } from '@sevenrooms/core/ui-kit/form'
import { Modal, ModalActions, ModalFooter, ModalHeader, type ModalHeaderProps, ModalTitle, VStack } from '@sevenrooms/core/ui-kit/layout'
import { Status } from '@sevenrooms/core/ui-kit/typography'
import { messages } from './UnsavedChangesModal.locales'

interface UnsavedChangesModalProps {
  closeHref: NonNullable<ModalHeaderProps['closeHref']>
  onClose: () => void
}

export function UnsavedChangesModal({ closeHref, onClose }: UnsavedChangesModalProps) {
  const { formatMessage } = useLocales()
  const nav = useNavigation()

  return (
    <Modal ariaLabel="Modal" minWidth={528}>
      <ModalHeader pb="none" onClose={onClose}>
        <VStack spacing="s">
          <Status kind="error">{formatMessage(commonMessages.unsavedChanges)}</Status>
          <ModalTitle title={formatMessage(messages.title)} />
        </VStack>
      </ModalHeader>
      <ModalFooter>
        <ModalActions>
          <Button variant="tertiary" onClick={onClose} data-test="posi-table-mapping-unsaved-changes-back-to-editing">
            {formatMessage(commonMessages.backToEditing)}
          </Button>
          <Button
            variant="primary-warning"
            onClick={() => {
              // This doesn't quite work...but we'll fix it later. yay surfaces.
              nav.closeSurface(closeHref)
            }}
            data-test="posi-table-mapping-unsaved-changes-exit-without-saving"
          >
            {formatMessage(messages.exitWithoutSaving)}
          </Button>
        </ModalActions>
      </ModalFooter>
    </Modal>
  )
}
