import MoreVertIcon from '@mui/icons-material/MoreVert'
import { useEffect, useState } from 'react'
import { useLocales } from '@sevenrooms/core/locales'
import { AccessRuleBulkCopy, BulkCopyStatus, pollBulkCopyStatus } from '@sevenrooms/mgr-access-rule-bulk-copy/AccessRuleBulkCopy'
import { useAppContext } from '@sevenrooms/mgr-core/hooks/useAppContext'
import { useNavigationPermissionsContext } from '@sevenrooms/mgr-core/hooks/useNavigationPermissionsContext'
import { IconButton } from '@sevenrooms/react-components/components/IconButton'
import { Menu } from '@sevenrooms/react-components/components/Menu'
import { MenuItem } from '@sevenrooms/react-components/components/MenuItem'
import { accessRulesSettingsMessages } from '../../locales'

export function OverflowMenu() {
  const { formatMessage } = useLocales()
  const { venueUrlKey, venueGroupId } = useAppContext()
  const permissions = useNavigationPermissionsContext()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const [startedBulkCopyPolling, setStartedBulkCopyPolling] = useState(false)
  const [isBulkCopyOpen, setIsBulkCopyOpen] = useState(false)
  const [bulkCopyStatus, setBulkCopyStatus] = useState(BulkCopyStatus.NOT_STARTED)

  const canAccessRuleBulkCopy = permissions?.canAccessRuleBulkCopy
  const canViewReportingFull = permissions?.canViewReportingFull

  useEffect(() => {
    if (canAccessRuleBulkCopy && !startedBulkCopyPolling) {
      pollBulkCopyStatus({ venueGroupId, onStatusChange: setBulkCopyStatus, previousStatus: BulkCopyStatus.COMPLETED })
      setStartedBulkCopyPolling(true)
    }
  }, [venueGroupId, startedBulkCopyPolling, canAccessRuleBulkCopy])

  const getBulkStatusLabel = (bulkCopyStatus: BulkCopyStatus) => {
    switch (bulkCopyStatus) {
      case BulkCopyStatus.LOADING:
        return formatMessage(accessRulesSettingsMessages.bulkCopyLoading)
      case BulkCopyStatus.IN_PROGRESS:
        return formatMessage(accessRulesSettingsMessages.bulkCopyProgressing)
      default:
        return formatMessage(accessRulesSettingsMessages.bulkCopy)
    }
  }

  return canAccessRuleBulkCopy || canViewReportingFull ? (
    <div>
      <IconButton
        aria-label="more"
        id="overflow-menu-button"
        aria-controls={open ? 'overflow-menu-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        data-test="btn-overflow-menu"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="overflow-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'overflow-menu-button',
        }}
      >
        {canAccessRuleBulkCopy && (
          <MenuItem
            onClick={() => {
              setIsBulkCopyOpen(true)
              handleClose()
            }}
            disabled={[BulkCopyStatus.IN_PROGRESS, BulkCopyStatus.LOADING].includes(bulkCopyStatus)}
          >
            {getBulkStatusLabel(bulkCopyStatus)}
          </MenuItem>
        )}
        {canViewReportingFull && (
          <MenuItem
            onClick={() => {
              window.open(`${window.location.origin}/manager/${venueUrlKey}/activitylog?selectedCategory=ACCESS`, '_blank')
              handleClose()
            }}
          >
            {formatMessage(accessRulesSettingsMessages.viewAccessRuleActivityLog)}
          </MenuItem>
        )}
      </Menu>

      {isBulkCopyOpen ? <AccessRuleBulkCopy onClose={() => setIsBulkCopyOpen(false)} onStatusChange={setBulkCopyStatus} /> : null}
    </div>
  ) : null
}
