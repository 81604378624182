import { useLocales } from '@sevenrooms/core/locales'
import { Typography } from '@sevenrooms/react-components/components/Typography'
import { accessRulesListMessages } from '../../../locales'

function FollowShift() {
  const { formatMessage } = useLocales()
  return (
    <Typography color="textSecondary" variant="body2">
      {formatMessage(accessRulesListMessages.followShift)}
    </Typography>
  )
}

export { FollowShift }
