import { useMemo } from 'react'
import type { OmnivoreConfig, POSILocation } from '@sevenrooms/core/domain'
import { type Field, z, type ZodSchema } from '@sevenrooms/core/form'
import {
  getLocationSelectionCardFormDefaultValues,
  convertLocationSelectionCardFormDataToPOSIConfig,
  useLocationSelectionCardForm,
} from '../../../LocationSelectionCard/LocationSelectionCardForm.zod'

export type OmnivoreEditPageFormData = ZodSchema<typeof useOmnivoreEditPageForm>
export type OmnivoreEditPageFormField = Field<OmnivoreEditPageFormData>

export function useOmnivoreEditPageForm() {
  const locationCard = useLocationSelectionCardForm()

  return useMemo(
    () =>
      z.object({
        locationCard,
      }),
    [locationCard]
  )
}

export const getOmnivoreEditPageFormDefaultValues = (config: OmnivoreConfig): OmnivoreEditPageFormData => ({
  locationCard: getLocationSelectionCardFormDefaultValues(config.location?.id),
})

export const convertOmnivoreEditPageFormDataToOmnivoreConfig = ({
  config,
  locations,
  data,
}: {
  config: OmnivoreConfig
  locations: POSILocation[]
  data: OmnivoreEditPageFormData
}): OmnivoreConfig => ({
  lastSaved: config.lastSaved,
  checkCreationEnabled: false,
  ...convertLocationSelectionCardFormDataToPOSIConfig({ locations, data: data.locationCard }),
  courseStatusMappings: [],
  courseStatusMappingEnabled: false,
  depositsEnabled: false,
})
