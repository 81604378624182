import { Loader } from '@sevenrooms/core/ui-kit/layout'
import { useVenueContext } from '@sevenrooms/mgr-core'
import { ShiftReportingPeriods } from './ShiftReportingPeriods'
import { useGetVenueShiftReportingPeriodsQuery } from './ShiftReportingPeriodsApi'

export function VenueShiftReportingPeriods() {
  const { venueId } = useVenueContext()
  const { isFetching } = useGetVenueShiftReportingPeriodsQuery({ venueId })
  return isFetching ? <Loader /> : <ShiftReportingPeriods />
}
