import { defineMessages } from '@sevenrooms/core/locales'

export const searchAvailability = defineMessages({
  searchCriteria: {
    id: 'searchCriteria',
    defaultMessage: 'Search Criteria',
  },
  guestPickerRange: {
    id: 'guestPickerRange',
    defaultMessage: 'Guest Picker: Searchable Range',
  },
  guestPickerRangeDescr: {
    id: 'guestPickerRangeDescr',
    defaultMessage: 'Party size range from which a guest can select.',
  },
  minGuest: {
    id: 'minGuest',
    defaultMessage: 'Min Guests',
  },
  maxGuest: {
    id: 'maxGuest',
    defaultMessage: 'Max Guests',
  },
  timePickerIncrements: {
    id: 'timePickerIncrements',
    defaultMessage: 'Time Picker: Searchable Increments',
  },
  timePickerIncrementsDescr: {
    id: 'timePickerIncrementsDescr',
    defaultMessage: 'Increments of time slots from which a guest can select.',
  },
  customTimeRange: {
    id: 'customTimeRange',
    defaultMessage: 'Range of Time Slots in :00 and :30 increments',
  },
  specificTimeSlots: {
    id: 'specificTimeSlots',
    defaultMessage: 'Specific Time Slot(s) in :00, :15, :30, :45 increments',
  },
  all: {
    id: 'all',
    defaultMessage: 'All Time Slots in :00 and :30 increments',
  },
  firstSeating: {
    id: 'firstSeating',
    defaultMessage: 'First Seating',
  },
  lastSeating: {
    id: 'lastSeating',
    defaultMessage: 'Last Seating',
  },
  datePickerCalendarLength: {
    id: 'datePickerCalendarLength',
    defaultMessage: 'Date Picker: Searchable Timeframe',
  },
  datePickerCalendarLengthDescr: {
    id: 'datePickerCalendarLengthDescr',
    defaultMessage: 'Number of days into the future a guest can select a date in the calendar.',
  },
  reservationRequests: {
    id: 'reservationRequests',
    defaultMessage: 'Reservation Requests',
  },
  reservationRequestsDescr: {
    id: 'reservationRequestsDescr',
    defaultMessage: 'Guests can Request a reservation when there is no availability',
  },
  priorityAlertRequests: {
    id: 'priorityAlertRequests',
    defaultMessage: 'Priority Alerts and Requests',
  },
  displayVenueOptIn: {
    id: 'displayVenueOptIn',
    defaultMessage: 'Opt-in for Email Marketing Checkbox for Venue',
  },
  displayGroupOptIn: {
    id: 'displayGroupOptIn',
    defaultMessage: 'Opt-in for Email Marketing Checkbox for Group',
  },
  displayTailoredCommunication: {
    id: 'displayTailoredCommunication',
    defaultMessage: 'Opt-in to receive tailored communication',
  },
  venueSmsMarketingOn: {
    id: 'mandatedAgeConsent',
    defaultMessage: 'Opt-in for Text Marketing Checkbox for Venue',
  },
  checkedByDefault: {
    id: 'checkedByDefault',
    defaultMessage: 'Checked by default',
  },
  uncheckedByDefault: {
    id: 'uncheckedByDefault',
    defaultMessage: 'Unchecked by default',
  },
  priorityAlertRequestsDescr: {
    id: 'priorityAlertRequestsDescr',
    defaultMessage: 'Guests can set a Priority Alert or Request when there is no availability',
  },
  availabilityConfigurations: {
    id: 'availabilityConfigurations',
    defaultMessage: 'Availability Configurations',
  },
  displayAdditionalFeesInPrice: {
    id: 'displayAdditionalFeesInPrice',
    defaultMessage: 'Display additional fees in price',
  },
  displayAdditionalFeesInPriceDescr: {
    id: 'displayAdditionalFeesInPriceDescr',
    defaultMessage:
      'Includes fees on Access Rules, Shifts, or Upgrades, specifically Service Charge and Forced Gratuity. Taxes are excluded.',
  },
  learnMore: {
    id: 'learnMore',
    defaultMessage: 'Learn more',
  },
  shiftLabel: {
    id: 'shiftLabel',
    defaultMessage: 'Shift must exist at searched time',
  },
  accessRulesLabel: {
    id: 'accessRulesLabel',
    defaultMessage: 'Access Rules must be in active booking window at searched time',
  },
  selectTimeSlot: {
    id: 'selectTimeSlot',
    defaultMessage: 'Select Time Slot(s)',
  },
  accessRulesTooltip: {
    id: 'accessRulesTooltip',
    defaultMessage: 'To ensure guests cannot make requests if availability for date/time has not yet been opened',
  },
  formatOfRequestingTIme: {
    id: 'formatOfRequestingTIme',
    defaultMessage: 'Format of requesting time',
  },
  singleTimeFrame: {
    id: 'singleTimeFrame',
    defaultMessage: 'Single time frame',
  },
  singleTimeFrameEx: {
    id: 'singleTimeFrameEx',
    defaultMessage: 'Ex: 7:00 PM',
  },
  timeRange: {
    id: 'timeRange',
    defaultMessage: 'Time Range',
  },
  timeRangeEx: {
    id: 'timeRangeEx',
    defaultMessage: 'Ex: 5:00 PM - 10:00 PM',
  },
  durationPickerConfigurations: {
    id: 'durationPickerConfigurations',
    defaultMessage: 'Duration Picker',
  },
  durationPickerConfigurationsDescription: {
    id: 'durationPickerConfigurationsDescription',
    defaultMessage:
      'When enabled, the Duration Picker on the Reservation Widget lets guests choose the length of their reservation. Keep in mind that activating this feature will only show availability from Access Rules that support custom Durations. {link}',
  },
  durationPickerConfigurationsDescriptionHelpPageLink: {
    id: 'durationPickerConfigurationsDescriptionHelpPageLink',
    defaultMessage: 'Learn more about the Duration Picker in this article.',
  },
  durationPickerDurationMin: {
    id: 'durationPickerDurationMin',
    defaultMessage: 'Minimum Duration',
  },
  durationPickerDurationMax: {
    id: 'durationPickerDurationMax',
    defaultMessage: 'Maximum Duration',
  },
  durationPickerDurationDefault: {
    id: 'durationPickerDurationDefault',
    defaultMessage: 'Default Duration',
  },
  durationPickerDurationDefaultValue: {
    id: 'durationPickerDurationDefaultValue',
    defaultMessage: 'Default',
  },
  durationPickerDurationInterval: {
    id: 'durationPickerDurationInterval',
    defaultMessage: 'Duration Interval',
  },
  durationPickerDefaultEnabledLabel: {
    id: 'durationPickerDefaultEnabledLabel',
    defaultMessage: 'Enable Duration Picker by default for Reservation Widget',
  },
  durationPickerSwitchedOffPrimaryLabel: {
    id: 'durationPickerSwitchedOffPrimaryLabel',
    defaultMessage: 'Additional Widget without Duration Picker',
  },
  durationPickerSwitchedOffSecondaryLabel: {
    id: 'durationPickerSwitchedOffSecondaryLabel',
    defaultMessage: 'If you need an additional Widget for Access Rules without durations enabled, please use the link below.',
  },
  durationPickerAdvancedSettings: {
    id: 'durationPickerAdvancedSettings',
    defaultMessage: 'Advanced Settings',
  },
  durationPickerAdvancedSettingsCopyWidgetLinkLabel: {
    id: 'durationPickerAdvancedSettingsCopyWidgetLinkLabel',
    defaultMessage: 'Copy Widget link with no Duration Picker enabled',
  },
  durationPickerMinDurationValidationMessage: {
    id: 'durationPickerMinDurationValidationMessage',
    defaultMessage: 'Minimum Duration should be lower than or equal to Maximum Duration',
  },
  durationPickerDefaultDurationValidationMessage: {
    id: 'durationPickerDefaultDurationValidationMessage',
    defaultMessage: 'Default Duration should be between Minimum and Maximum Duration',
  },
})
