import React from 'react'
import { Typography } from '@sevenrooms/react-components/components/Typography'

interface SlotDescriptionFieldProps {
  slotDescription?: string
}

function SlotDescriptionField({ slotDescription }: SlotDescriptionFieldProps) {
  return (
    <Typography variant="body2" color="textPrimary">
      {slotDescription}
    </Typography>
  )
}

export { SlotDescriptionField }
